package pl.krystiankaniowski.rank.core.repository

import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.withContext
import kotlinx.datetime.LocalDate
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import org.koin.core.annotation.Single
import pl.krystiankaniowski.rank.core.UserException
import pl.krystiankaniowski.rank.core.model.MatchWithPlayers
import pl.krystiankaniowski.rank.core.networking.RankApi
import pl.krystiankaniowski.rank.core.networking.Response
import pl.krystiankaniowski.rank.core.toUserError
import pl.krystiankaniowski.rank.model.Match
import pl.krystiankaniowski.rank.model.NewMatch

@Single
class MatchRepository(
    private val playerRepository: PlayerRepository,
    private val rankApi: RankApi,
) {

    private var matches: MutableStateFlow<List<MatchWithPlayers>> = MutableStateFlow(emptyList())

    fun getMatches(forceSync: Boolean = false): Flow<List<MatchWithPlayers>> = flow {
        if (forceSync || matches.value.isEmpty()) {
            when (val result = rankApi.matches()) {
                is Response.Error -> throw UserException(result.toUserError())
                is Response.Success -> updateMatchesFromRemote(result.body.data)
            }
        }
        matches.collect { emit(it) }
    }

    fun getMatchesGroupedByDate(
        forceSync: Boolean = false,
        filter: (MatchWithPlayers) -> Boolean = { true },
    ): Flow<Map<LocalDate, List<MatchWithPlayers>>> = flow {
        getMatches(forceSync)
            .collect { matches ->
                matches.sortedByDescending { it.datetime }
                    .filter(filter)
                    .groupBy { it.datetime.date }
                    .let { emit(it) }
            }
    }

    fun getMatch(id: Long): Flow<MatchWithPlayers?> = flow {
        emit(getMatches().first().firstOrNull { it.id == id })
    }

    suspend fun addMatch(newMatch: NewMatch) {
        check(rankApi.addMatch(newMatch).isSuccess) { "Failed to add match" }
        when (val result = rankApi.matches()) {
            is Response.Error -> throw UserException(result.toUserError())
            is Response.Success -> updateMatchesFromRemote(result.body.data)
        }
    }

    suspend fun getPlayerMatches(playerId: Long): List<MatchWithPlayers> {
        return withContext(Dispatchers.Default) {
            matches.first().filter { it.winner.id == playerId || it.loser.id == playerId }
        }
    }

    private suspend fun Match.toMatchWithPlayer(): MatchWithPlayers {
        val datetime = this.timestamp.toLocalDateTime(TimeZone.currentSystemDefault())
        return MatchWithPlayers(
            id = this.id,
            datetime = datetime,
            winner = playerRepository.getPlayer(this.winnerId).first()!!,
            loser = playerRepository.getPlayer(this.loserId).first()!!,
            winnerScore = this.winnerScore,
            loserScore = this.loserScore,
            winnerEloChange = this.winnerEloChange,
            loserEloChange = this.loserEloChange,
            history = this.history,
        )
    }

    private suspend fun updateMatchesFromRemote(data: List<Match>) {
        matches.value = data.sortedByDescending { it.timestamp }.map { it.toMatchWithPlayer() }
    }

    suspend fun getMatchesBetween(playerId1: Long, playerId2: Long): List<MatchWithPlayers> {
        return matches.value
            .filter { match ->
                (match.winner.id == playerId1 && match.loser.id == playerId2) ||
                    (match.winner.id == playerId2 && match.loser.id == playerId1)
            }
            .sortedByDescending { it.datetime }
    }
}