@file:Suppress("MatchingDeclarationName")

package pl.krystiankaniowski.rank.core.networking

import io.ktor.client.*
import io.ktor.client.engine.js.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.serialization.kotlinx.json.*

actual class HttpClientBuilder actual constructor() {

    actual fun build(): HttpClient = HttpClient(Js) {
        expectSuccess = true
        install(ContentNegotiation) {
            json()
        }
    }
}