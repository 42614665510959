package pl.krystiankaniowski.rank.core.networking

import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.serialization.*
import io.ktor.util.network.*
import io.ktor.utils.io.errors.*
import kotlinx.serialization.SerializationException
import pl.krystiankaniowski.rank.model.ErrorBody

sealed class Response<out T> {

    /** Represents successful network responses (2xx). */
    data class Success<T>(val body: T) : Response<T>()

    sealed class Error : Response<Nothing>() {

        /** Represents client (40x) and server (50x) errors. */
        data class HttpError(val code: Int, val error: ErrorBody?) : Error()

        /** Represent IOExceptions and connectivity issues. */
        data object NetworkError : Error()

        /** Represent SerializationExceptions. */
        data object SerializationError : Error()
    }

    val isSuccess: Boolean get() = this is Success
}

suspend inline fun <reified T> HttpClient.safeRequest(block: HttpRequestBuilder.() -> Unit): Response<T> =
    try {
        val response = request { block() }
        Response.Success(response.body())
    } catch (e: ClientRequestException) {
        Response.Error.HttpError(e.response.status.value, e.error())
    } catch (e: ServerResponseException) {
        Response.Error.HttpError(e.response.status.value, e.error())
    } catch (e: IOException) {
        Response.Error.NetworkError
    } catch (e: UnresolvedAddressException) {
        Response.Error.NetworkError
    } catch (e: SerializationException) {
        Response.Error.SerializationError
    }

suspend inline fun ResponseException.error(): ErrorBody? =
    try {
        response.body()
    } catch (e: SerializationException) {
        null
    } catch (e: JsonConvertException) {
        null
    } catch (e: NoTransformationFoundException) {
        null
    }
