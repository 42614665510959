package pl.krystiankaniowski.rank.feature.issue

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.items
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.luminance
import androidx.compose.ui.unit.dp
import pl.krystiankaniowski.rank.core.designsystem.RankLayout
import pl.krystiankaniowski.rank.core.designsystem.RankScreenState
import pl.krystiankaniowski.rank.core.designsystem.RankTopBar
import pl.krystiankaniowski.rank.model.Issue

@Composable
fun IssuesScreen(
    viewModel: IssuesViewModel,
    onBackPressed: () -> Unit,
) {

    RankLayout.Page(
        topBar = { RankTopBar.Solid(title = "Issues", onBackPressed = onBackPressed) },
    ) {
        when (val state = viewModel.state.collectAsState().value) {
            is IssuesViewModel.State.Loaded -> IssuesScreenLoaded(state)
            IssuesViewModel.State.Loading -> RankScreenState.Loading()
            IssuesViewModel.State.Empty -> RankScreenState.Empty(message = "No data to display")
            is IssuesViewModel.State.Error -> RankScreenState.Error(userError = state.userError)
        }
    }
}

@OptIn(ExperimentalLayoutApi::class)
@Composable
private fun IssuesScreenLoaded(
    state: IssuesViewModel.State.Loaded,
) {
    RankLayout.ContentLazy {
        items(state.issues) { issue ->
            RankLayout.ContentCard(modifier = Modifier.padding(16.dp)) {
                Text(issue.title)
                if (issue.tags.isNotEmpty()) {
                    Spacer(Modifier.height(8.dp))
                    FlowRow(
                        horizontalArrangement = Arrangement.spacedBy(4.dp),
                        verticalArrangement = Arrangement.spacedBy(4.dp),
                    ) {
                        issue.tags.forEach {
                            Tag(it)
                        }
                    }
                }
            }
        }
    }
}

@Composable
private fun Tag(tag: Issue.Tag) {
    val color = Color(tag.color)
    Text(
        modifier = Modifier
            .background(color, MaterialTheme.shapes.medium)
            .padding(horizontal = 8.dp, vertical = 4.dp),
        text = tag.name,
        color = calculateContrastColor(color),
        style = MaterialTheme.typography.labelSmall,
    )
}

@Composable
private fun calculateContrastColor(backgroundColor: Color): Color {
    return if (backgroundColor.luminance() > 0.5) Color.Black else Color.White
}