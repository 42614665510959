package pl.krystiankaniowski.rank.feature.arbiter

import androidx.compose.material3.Text
import androidx.compose.material3.TextField
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import pl.krystiankaniowski.rank.core.designsystem.RankLayout
import pl.krystiankaniowski.rank.core.designsystem.RankListItems
import pl.krystiankaniowski.rank.core.designsystem.RankTopBar

@Composable
fun ArbiterScreen(
    viewModel: ArbiterViewModel,
    onBackPressed: () -> Unit,
) {

    val state = viewModel.state.collectAsState().value

    RankLayout.Page(
        topBar = { RankTopBar.Solid(title = "Arbiter", onBackPressed = onBackPressed) },
    ) {
        RankLayout.ContentScrollable {
            RankLayout.ContentCard {
                RankListItems.Header(text = "Arbiter")
                RankListItems.Switch(
                    label = "Arbiter mode",
                    isSelected = state.isArbiterModeActive,
                    onClick = { viewModel.onAction(ArbiterViewModel.Action.SetArbiterMode(it)) },
                )
                androidx.compose.material3.ListItem(
                    headlineContent = {
                        TextField(
                            enabled = state.isArbiterTokenEnabled,
                            label = { Text("Arbiter token") },
                            value = state.arbiterToken ?: "",
                            onValueChange = { viewModel.onAction(ArbiterViewModel.Action.SetArbiterToken(it)) },
                        )
                    },
                )
            }
        }
    }
}
