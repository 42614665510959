package pl.krystiankaniowski.rank.feature.match.add.pending

import androidx.compose.foundation.layout.Column
import androidx.compose.material3.AlertDialog
import androidx.compose.material3.Text
import androidx.compose.runtime.*
import pl.krystiankaniowski.rank.core.designsystem.RankButton
import pl.krystiankaniowski.rank.core.designsystem.RankListItems
import pl.krystiankaniowski.rank.model.Player

@Composable
internal fun MatchAddSetWinnerDialog(
    player1: Player,
    player2: Player,
    onResult: (winner: Long) -> Unit,
    onDismiss: () -> Unit,
) {

    var selectedId: Long? by remember { mutableStateOf(null) }

    AlertDialog(
        onDismissRequest = onDismiss,
        title = { Text(text = "Add set winner") },
        text = {
            Column {
                PlayerRow(
                    player = player1,
                    selected = selectedId == player1.id,
                    onSelect = { selectedId = player1.id },
                )
                PlayerRow(
                    player = player2,
                    selected = selectedId == player2.id,
                    onSelect = { selectedId = player2.id },
                )
            }
        },
        confirmButton = {
            RankButton.TextButton(
                text = "Add",
                enabled = selectedId != null,
                onClick = {
                    onResult(selectedId!!)
                    onDismiss()
                },
            )
        },
        dismissButton = {
            RankButton.TextButton(
                text = "Cancel",
                onClick = onDismiss,
            )
        },
    )
}

@Composable
private fun PlayerRow(player: Player, selected: Boolean, onSelect: () -> Unit) {
    RankListItems.RadioButton(
        label = player.name,
        isSelected = selected,
        onClick = onSelect,
    )
}