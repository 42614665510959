package pl.krystiankaniowski.rank.feature.rank

import androidx.lifecycle.viewModelScope
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.launch
import org.koin.core.annotation.Factory
import pl.krystiankaniowski.rank.core.UserError
import pl.krystiankaniowski.rank.core.UserException
import pl.krystiankaniowski.rank.core.repository.RankRepository
import pl.krystiankaniowski.rank.core.transformIf
import pl.krystiankaniowski.rank.core.update
import pl.krystiankaniowski.rank.core.viewmodel.StateViewModel
import pl.krystiankaniowski.rank.model.Player
import kotlin.math.roundToInt

@Factory
class RankViewModel(
    private val rankRepository: RankRepository,
) : StateViewModel<RankViewModel.State, RankViewModel.Event, RankViewModel.Action>() {

    enum class SortBy {
        Elo,
        Wins,
        Matches,
        Rivals,
        LongestStrike,
    }

    enum class Display {
        All,
        Classified,
    }

    sealed interface State {
        data object Loading : State
        data class Error(val userError: UserError) : State
        data class Loaded(
            val sortBy: SortBy = SortBy.Wins,
            val display: Display = Display.All,
            val data: List<PlayerStats>,
            val displayData: List<PlayerStats>,
        ) : State {
            data class PlayerStats(
                val position: Int,
                val player: Player,
                val isClassified: Boolean,
                val elo: Int,
                val matchesCount: Int,
                val winMatches: Int,
                val loseMatches: Int,
                val winRatio: Float,
                val rivals: Int,
                val longestStrike: Int,
            ) {
                val winRatioString: String
                    get() = "${(winRatio * 100).roundToInt()}%"
            }
        }
    }

    sealed interface Event {
        data class OpenPlayerDetailsScreen(val id: Long) : Event
    }

    sealed interface Action {
        data class OnPlayerClick(val id: Long) : Action
        data class OnModeChange(val sortBy: SortBy) : Action
        data object OnDisplayClick : Action
    }

    init {
        reload()
    }

    override fun initState() = State.Loading

    override fun onAction(action: Action) {
        when (action) {
            is Action.OnModeChange -> onModeChange(sortBy = action.sortBy)
            is Action.OnPlayerClick -> onPlayerClick(id = action.id)
            Action.OnDisplayClick -> onDisplayClick()
        }
    }

    private fun reload() {
        viewModelScope.launch {
            try {
                val stats = rankRepository.getPlayersPositions().first()
                    .map {
                        State.Loaded.PlayerStats(
                            position = 0,
                            player = it.player,
                            isClassified = it.isClassified,
                            elo = it.elo,
                            matchesCount = it.matchesCount,
                            winMatches = it.wonMatches,
                            loseMatches = it.lostMatches,
                            winRatio = it.winRatio,
                            rivals = it.rivals,
                            longestStrike = it.longestStrike,
                        )
                    }
                    .sortedByDescending { it.matchesCount }
                    .sortedByDescending { it.winRatio }

                _state.update(State.Loaded(data = stats, displayData = stats))
                onModeChange(sortBy = SortBy.Elo)
            } catch (e: UserException) {
                _state.update(State.Error(e.userError))
            }
        }
    }

    @Suppress("CyclomaticComplexMethod")
    private fun onModeChange(sortBy: SortBy) {
        _state.transformIf<State.Loaded>(viewModelScope) {

            val data = when (sortBy) {
                SortBy.Elo -> data.sortedByDescending { it.matchesCount }.sortedByDescending { it.elo }
                SortBy.Wins -> data.sortedByDescending { it.elo }.sortedByDescending { it.winRatio }
                SortBy.Matches -> data.sortedByDescending { it.elo }.sortedByDescending { it.matchesCount }
                SortBy.Rivals -> data.sortedByDescending { it.elo }.sortedByDescending { it.rivals }
                SortBy.LongestStrike -> data.sortedByDescending { it.elo }.sortedByDescending { it.longestStrike }
            }
                .mapIndexed { index, playerStats -> playerStats.copy(position = index + 1) }
                .let {
                    val list = it.toMutableList()
                    for (i in it.indices) {
                        if (i > 0 && list[i - 1].elo == list[i].elo && list[i - 1].matchesCount == list[i].matchesCount) {
                            list[i] = list[i].copy(position = list[i - 1].position)
                        }
                    }
                    list
                }

            val displayData = when (display) {
                Display.All -> data
                Display.Classified -> data.filter { it.isClassified }
                    .mapIndexed { index, playerStats -> playerStats.copy(position = index + 1) }
                    .let {
                        val list = it.toMutableList()
                        for (i in it.indices) {
                            if (i > 0 && list[i - 1].elo == list[i].elo && list[i - 1].matchesCount == list[i].matchesCount) {
                                list[i] = list[i].copy(position = list[i - 1].position)
                            }
                        }
                        list
                    }
            }

            copy(
                sortBy = sortBy,
                data = data,
                displayData = displayData,
            )
        }
    }

    private fun onDisplayClick() {
        _state.transformIf<State.Loaded>(viewModelScope) {

            val display = Display.entries[(display.ordinal + 1) % Display.entries.size]
            val displayData = when (display) {
                Display.All -> data
                Display.Classified -> data.filter { it.isClassified }
                    .mapIndexed { index, playerStats -> playerStats.copy(position = index + 1) }
                    .let {
                        val list = it.toMutableList()
                        for (i in it.indices) {
                            if (i > 0 && list[i - 1].elo == list[i].elo && list[i - 1].matchesCount == list[i].matchesCount) {
                                list[i] = list[i].copy(position = list[i - 1].position)
                            }
                        }
                        list
                    }
            }

            copy(
                display = display,
                displayData = displayData,
            )
        }
    }

    private fun onPlayerClick(id: Long) {
        viewModelScope.launch {
            _events.emit(Event.OpenPlayerDetailsScreen(id))
        }
    }
}