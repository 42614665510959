package pl.krystiankaniowski.rank.feature.match.add.confirm

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.AlertDialog
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import pl.krystiankaniowski.rank.core.designsystem.RankButton
import pl.krystiankaniowski.rank.feature.match.ui.RankMatchHeader
import pl.krystiankaniowski.rank.model.Player

@Composable
fun MatchConfirmDialog(
    data: MatchConfirmDialogData,
    onConfirm: () -> Unit,
    onDismiss: () -> Unit,
) {
    AlertDialog(
        onDismissRequest = onDismiss,
        title = { Text(text = "Confirm match") },
        text = {
            Box(
                modifier = Modifier.padding(vertical = 16.dp),
            ) {
                RankMatchHeader(player1 = data.player1, player2 = data.player2, player1Score = data.player1Score, player2Score = data.player2Score)
            }
        },
        confirmButton = {
            RankButton.TextButton(
                text = "Confirm",
                onClick = {
                    onConfirm()
                    onDismiss()
                },
            )
        },
        dismissButton = {
            RankButton.TextButton(
                text = "Back",
                onClick = onDismiss,
            )
        },
    )
}

data class MatchConfirmDialogData(
    val player1: Player,
    val player2: Player,
    val player1Score: Int,
    val player2Score: Int,
)