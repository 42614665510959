package pl.krystiankaniowski.rank.feature.stat.usecase

import kotlinx.coroutines.flow.first
import org.koin.core.annotation.Factory
import pl.krystiankaniowski.rank.core.repository.MatchRepository

@Factory
class StatGetNumberOfSetsUseCase(
    private val matchRepository: MatchRepository,
) {

    suspend operator fun invoke(): Int = matchRepository.getMatches().first().sumOf { it.history?.size ?: 0 }
}