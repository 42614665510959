package pl.krystiankaniowski.rank.feature.player.list

import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.SharedFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import org.koin.core.annotation.Factory
import pl.krystiankaniowski.rank.core.UserError
import pl.krystiankaniowski.rank.core.catchUserException
import pl.krystiankaniowski.rank.core.repository.PlayerRepository
import pl.krystiankaniowski.rank.core.update
import pl.krystiankaniowski.rank.model.Player

@Factory
class PlayerListViewModel(
    private val playerRepository: PlayerRepository,
) : ViewModel() {

    private val _state: MutableStateFlow<State> = MutableStateFlow(State.Loading)
    val state: StateFlow<State> = _state

    private val _events: MutableSharedFlow<Event> = MutableSharedFlow()
    val events: SharedFlow<Event> = _events

    private var currentJob: Job? = null

    init {
        loadData(forceSync = false)
    }

    private fun loadData(forceSync: Boolean) {
        currentJob?.cancel()
        currentJob = viewModelScope.launch {
            _state.update(State.Loading)
            playerRepository.getPlayers(forceSync)
                .catchUserException { _state.value = State.Error(it.userError) }
                .collect { data ->
                    _state.value = when {
                        data.isEmpty() -> State.Empty
                        else -> State.Loaded(players = data)
                    }
                }
        }
    }

    fun onAction(action: Action) {
        when (action) {
            Action.Refresh -> loadData(forceSync = true)
            is Action.OnPlayerClick -> onPlayerClick(action.id)
        }
    }

    private fun onPlayerClick(id: Long) {
        viewModelScope.launch {
            _events.emit(Event.OpenPlayerDetailsScreen(id))
        }
    }

    sealed interface State {
        data object Loading : State
        data object Empty : State
        data class Error(val userError: UserError) : State
        data class Loaded(
            val players: List<Player>,
        ) : State
    }

    sealed interface Event {
        data class OpenPlayerDetailsScreen(val id: Long) : Event
    }

    sealed interface Action {
        data object Refresh : Action
        data class OnPlayerClick(val id: Long) : Action
    }
}