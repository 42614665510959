package pl.krystiankaniowski.rank.core.designsystem

import androidx.compose.foundation.layout.Column
import androidx.compose.material3.AlertDialog
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable

object RankDialog {

    @Composable
    fun <T> Select(
        title: String? = null,
        items: List<T>,
        selectedItem: T?,
        formatter: (T) -> String,
        onSelect: (T) -> Unit,
        onDismiss: () -> Unit,
    ) {
        AlertDialog(
            title = title?.let { { Text(it) } },
            text = {
                Column {
                    items.forEach { item ->
                        RankListItems.RadioButton(
                            label = formatter(item),
                            isSelected = item == selectedItem,
                            onClick = {
                                onSelect(item)
                                onDismiss()
                            },
                        )
                    }
                }
            },
            onDismissRequest = onDismiss,
            confirmButton = {
                RankButton.TextButton(
                    onClick = onDismiss,
                    text = "Cancel",
                )
            },
        )
    }
}