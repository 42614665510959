package pl.krystiankaniowski.rank.feature.issue

import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import org.koin.core.annotation.Factory
import pl.krystiankaniowski.rank.core.UserError
import pl.krystiankaniowski.rank.core.catchUserException
import pl.krystiankaniowski.rank.model.Issue

@Factory
class IssuesViewModel(
    private val issueRepository: IssueRepository,
) : ViewModel() {

    private val _state: MutableStateFlow<State> = MutableStateFlow(State.Loading)
    val state: StateFlow<State> = _state

    init {
        viewModelScope.launch {
            issueRepository.getIssues()
                .catchUserException {
                    _state.value = State.Error(it.userError)
                }
                .collect { data ->
                    _state.value = when {
                        data.isEmpty() -> State.Empty
                        else -> State.Loaded(issues = data)
                    }
                }
        }
    }

    sealed interface State {
        data object Loading : State
        data object Empty : State
        data class Error(val userError: UserError) : State
        data class Loaded(
            val issues: List<Issue>,
        ) : State
    }
}