package pl.krystiankaniowski.rank.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class MatchSet(
    @SerialName("matchId") val matchId: Long,
    @SerialName("number") val number: Long,
    @SerialName("winnerId") val winnerId: Long,
    @SerialName("loserId") val loserId: Long,
    @SerialName("winnerScore") val winnerScore: Long?,
    @SerialName("loserScore") val loserScore: Long?,
)
