package pl.krystiankaniowski.rank.core.repository

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.flow.firstOrNull
import kotlinx.coroutines.flow.flow
import org.koin.core.annotation.Single
import pl.krystiankaniowski.rank.core.model.MatchWithPlayers
import pl.krystiankaniowski.rank.feature.player.summary.PlayerStat
import pl.krystiankaniowski.rank.model.Player
import kotlin.math.roundToInt

@Single
class RankRepository(
    private val matchRepository: MatchRepository,
    private val playerRepository: PlayerRepository,
) {
    fun getPlayersPositions(): Flow<List<PlayerPosition>> = flow {
        val players = playerRepository.getPlayers().first()
        val matches = matchRepository.getMatches().first()
        val playersPositions = players.map { player ->
            val playerMatches = matches.filter { match -> match.winner.id == player.id || match.loser.id == player.id }
            val matchesCount = playerMatches.size
            val winMatches = playerMatches.count { match -> match.winner.id == player.id }
            val loseMatches = matchesCount - winMatches
            val winRatio = if (matchesCount > 0) winMatches / matchesCount.toFloat() else 0f
            val opponents = playerMatches.map { match -> if (match.winner.id == player.id) match.loser else match.winner }.toSet().size
            val longestStrike = playerMatches.findLongestStreak(player.id)
            PlayerPosition(
                player = player,
                isClassified = matchesCount >= 10,
                elo = player.elo.roundToInt(),
                wonMatches = winMatches,
                lostMatches = loseMatches,
                matchesCount = matchesCount,
                winRatio = winRatio,
                rivals = opponents,
                longestStrike = longestStrike,
            )
        }.filter { it.matchesCount > 0 }
        emit(playersPositions)
    }

    suspend fun getPlayerStats(id: Long): PlayerStat? {
        val data = getPlayersPositions().firstOrNull()?.find { it.player.id == id } ?: return null
        return PlayerStat(
            elo = data.elo,
            wins = data.wonMatches / data.matchesCount.toFloat(),
            matches = data.matchesCount,
            opponents = data.rivals,
        )
    }

    private fun List<MatchWithPlayers>.findLongestStreak(playerId: Long): Int {
        var currentStreak = 0
        var longestStreak = 0

        for (element in this) {
            if (element.winner.id == playerId) {
                currentStreak++
            } else {
                currentStreak = 0
            }
            if (currentStreak > longestStreak) {
                longestStreak = currentStreak
            }
        }

        return longestStreak
    }
}

data class PlayerPosition(
    val player: Player,
    val isClassified: Boolean,
    val elo: Int,
    val wonMatches: Int,
    val lostMatches: Int,
    val matchesCount: Int,
    val winRatio: Float,
    val rivals: Int,
    val longestStrike: Int,
)