package pl.krystiankaniowski.rank.feature.arbiter

import androidx.lifecycle.ViewModel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import org.koin.core.annotation.Factory
import pl.krystiankaniowski.rank.core.transform
import pl.krystiankaniowski.rank.feature.setting.Settings

@Factory
class ArbiterViewModel(
    private val settings: Settings,
) : ViewModel() {

    data class State(
        val isArbiterModeActive: Boolean = false,
        val arbiterToken: String? = null,
    ) {
        val isArbiterTokenEnabled: Boolean = isArbiterModeActive
    }

    sealed interface Action {
        data class SetArbiterMode(val enabled: Boolean) : Action
        data class SetArbiterToken(val token: String) : Action
    }

    private val _state: MutableStateFlow<State> = MutableStateFlow(State())
    val state: StateFlow<State> = _state

    init {
        _state.value = State(
            isArbiterModeActive = settings.arbiterMode,
            arbiterToken = settings.arbiterToken,
        )
    }

    fun onAction(action: Action) {
        when (action) {
            is Action.SetArbiterMode -> onSetArbiterMode(action.enabled)
            is Action.SetArbiterToken -> onSetArbiterToken(action.token)
        }
    }

    private fun onSetArbiterMode(enabled: Boolean) {
        settings.arbiterMode = enabled
        _state.transform {
            copy(isArbiterModeActive = settings.arbiterMode)
        }
    }

    private fun onSetArbiterToken(token: String) {
        settings.arbiterToken = token
        _state.transform {
            copy(arbiterToken = settings.arbiterToken)
        }
    }
}