package pl.krystiankaniowski.rank.core.designsystem

import androidx.compose.material3.*
import androidx.compose.runtime.Composable
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource
import rank.client.shared.generated.resources.Res
import rank.client.shared.generated.resources.icon_back

object RankTopBar {

    @OptIn(ExperimentalMaterial3Api::class)
    @Composable
    fun Solid(
        title: String,
        actions: List<Action> = emptyList(),
        onBackPressed: (() -> Unit)? = null,
    ) {
        TopAppBar(
            colors = TopAppBarDefaults.topAppBarColors(
                containerColor = MaterialTheme.colorScheme.surface,
            ),
            navigationIcon = {
                when {
                    onBackPressed != null -> IconButton(
                        content = { Icon(painter = painterResource(Res.drawable.icon_back), contentDescription = "Back") },
                        onClick = { onBackPressed() },
                    )
                    else -> Unit
                }
            },
            title = { Text(text = title) },
            actions = {
                actions.forEach { action ->
                    when (action) {
                        is Action.Icon -> {
                            IconButton(
                                content = { Icon(painter = painterResource(action.icon), contentDescription = action.contentDescription) },
                                onClick = action.onClick,
                            )
                        }

                        is Action.Text -> RankButton.TextButton(
                            text = action.label,
                            onClick = action.onClick,
                        )
                    }
                }
            },
        )
    }

    sealed interface Action {

        data class Icon(
            val icon: DrawableResource,
            val contentDescription: String? = null,
            val onClick: () -> Unit,
        ) : Action

        data class Text(
            val label: String,
            val contentDescription: String? = null,
            val onClick: () -> Unit,
        ) : Action
    }
}
