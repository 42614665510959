package pl.krystiankaniowski.rank.feature.player.rival

import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.resources.painterResource
import pl.krystiankaniowski.rank.core.designsystem.*
import pl.krystiankaniowski.rank.core.formatter.asPercentage
import pl.krystiankaniowski.rank.core.formatter.cutAfter
import pl.krystiankaniowski.rank.core.formatter.formatRelative
import pl.krystiankaniowski.rank.feature.player.ui.PlayerAvatar
import pl.krystiankaniowski.rank.model.Player
import rank.client.shared.generated.resources.Res
import rank.client.shared.generated.resources.icon_drop_down
import rank.client.shared.generated.resources.icon_sort
import kotlin.math.abs

@Composable
fun PlayerRivalsScreen(
    viewModel: PlayerRivalsViewModel,
    openPlayersCompareScreen: (Long, Long) -> Unit,
    onBackPressed: () -> Unit,
) {

    LaunchedEffect(Unit) {
        viewModel.events.collect { event ->
            when (event) {
                is PlayerRivalsViewModel.Event.OpenComparePlayersScreen -> openPlayersCompareScreen(event.playerId1, event.playerId2)
            }
        }
    }

    when (val state = viewModel.state.collectAsState().value) {
        PlayerRivalsViewModel.State.Loading -> {
            RankLayout.Page(
                topBar = { RankTopBar.Solid(title = "Player rivals", onBackPressed = onBackPressed) },
                content = { RankScreenState.Loading() },
            )
        }

        is PlayerRivalsViewModel.State.Data -> {

            var openSortDialog by remember { mutableStateOf(false) }

            if (openSortDialog) {
                RankDialog.Select(
                    title = "Sort by",
                    items = PlayerRivalsViewModel.SortBy.entries,
                    selectedItem = state.sortBy,
                    formatter = ::formatMode,
                    onSelect = { mode -> viewModel.onAction(PlayerRivalsViewModel.Action.OnSortByChange(mode)) },
                    onDismiss = { openSortDialog = false },
                )
            }

            RankLayout.Page(
                topBar = {
                    RankTopBar.Solid(
                        title = "${state.player.name}'s rivals",
                        actions = listOf(
                            RankTopBar.Action.Icon(
                                icon = Res.drawable.icon_sort,
                                onClick = { openSortDialog = true },
                            ),
                        ),
                        onBackPressed = onBackPressed,
                    )
                },
                content = { PlayerRivalsScreenLoaded(state, viewModel::onAction) },
            )
        }
    }
}

@Composable
private fun PlayerRivalsScreenLoaded(
    state: PlayerRivalsViewModel.State.Data,
    onAction: (PlayerRivalsViewModel.Action) -> Unit,
) {
    LazyColumn(
        contentPadding = PaddingValues(vertical = 16.dp),
        verticalArrangement = Arrangement.spacedBy(8.dp),
    ) {
        items(state.comparisons) {
            RivalComparisonCard(
                player = state.player,
                stats = it,
                onAction = onAction,
            )
        }
    }
}

@Composable
private fun RivalComparisonCard(
    player: Player,
    stats: PlayerRivalsViewModel.State.Data.ComparisonEntry,
    onAction: (PlayerRivalsViewModel.Action) -> Unit,
) {
    RankLayout.ContentCard {

        val size = LocalScreenSize.current

        when (size) {
            RankScreenSize.Compact, RankScreenSize.Medium -> {
                Column(
                    modifier = Modifier.clickable { onAction(PlayerRivalsViewModel.Action.OnPlayerClick(stats.rival.id)) }
                        .padding(16.dp),
                ) {
                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        verticalAlignment = Alignment.CenterVertically,
                    ) {
                        RivalComparisonCardBalance(player, stats)
                    }
                    Spacer(Modifier.height(8.dp))
                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        verticalAlignment = Alignment.CenterVertically,
                    ) {
                        RivalComparisonCardDetails(stats)
                    }
                }
            }

            RankScreenSize.Expanded,
            RankScreenSize.Large,
            RankScreenSize.ExtraLarge,
            -> {
                Row(
                    modifier = Modifier.clickable { onAction(PlayerRivalsViewModel.Action.OnPlayerClick(stats.rival.id)) }
                        .fillMaxWidth()
                        .padding(16.dp),
                    verticalAlignment = Alignment.CenterVertically,
                ) {
                    RivalComparisonCardBalance(player, stats)
                    Spacer(Modifier.width(32.dp))
                    RivalComparisonCardDetails(stats)
                }
            }
        }
    }
}

@Composable
private fun RowScope.RivalComparisonCardBalance(
    player: Player,
    stats: PlayerRivalsViewModel.State.Data.ComparisonEntry,
) {
    Text(
        modifier = Modifier.weight(1f),
        maxLines = 1,
        text = player.name,
        textAlign = TextAlign.End,
    )
    Spacer(Modifier.width(16.dp))
    PlayerAvatar(
        modifier = Modifier.size(32.dp),
        player = player,
    )
    Text(
        modifier = Modifier.padding(horizontal = 16.dp).width(64.dp),
        textAlign = TextAlign.Center,
        text = "${stats.winCount} : ${stats.loseCount}",
    )
    PlayerAvatar(
        modifier = Modifier.size(32.dp),
        player = stats.rival,
    )
    Spacer(Modifier.width(16.dp))
    Text(
        text = stats.rival.name,
        maxLines = 1,
        modifier = Modifier.weight(1f),
    )
}

@Composable
fun RowScope.RivalComparisonCardDetails(
    stats: PlayerRivalsViewModel.State.Data.ComparisonEntry,
) {
    Text(text = "${stats.winCount + stats.loseCount}", modifier = Modifier.weight(1f))
    Text(text = stats.winRatio.asPercentage(), modifier = Modifier.weight(1f))

    Icon(
        modifier = Modifier.padding(top = 4.dp).rotate(if (stats.eloBalance > 0) 180f else 0f).size(32.dp),
        painter = painterResource(Res.drawable.icon_drop_down),
        contentDescription = null,
        tint = if (stats.eloBalance > 0) androidx.compose.ui.graphics.Color.Green else androidx.compose.ui.graphics.Color.Red,
    )
    Modifier.width(4.dp)
    Text(
        modifier = Modifier.weight(1f),
        text = abs(stats.eloBalance).cutAfter(1),
    )
    Text(
        modifier = Modifier.weight(1f),
        maxLines = 1,
        text = stats.lastMatchDate.formatRelative(),
    )
}

private fun formatMode(sortBy: PlayerRivalsViewModel.SortBy): String {
    return when (sortBy) {
        PlayerRivalsViewModel.SortBy.MatchCount -> "Matches count"
        PlayerRivalsViewModel.SortBy.WinRatio -> "Win ratio"
        PlayerRivalsViewModel.SortBy.EloBalance -> "Elo balance"
        PlayerRivalsViewModel.SortBy.LastMatchDate -> "Last match date"
    }
}
