package pl.krystiankaniowski.rank.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ErrorBody(
    @SerialName("timestamp") val timestamp: String,
    @SerialName("status") val status: Int,
    @SerialName("error") val error: String,
    @SerialName("message") val message: String,
)