package pl.krystiankaniowski.rank.feature.match.ui

import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import pl.krystiankaniowski.rank.core.designsystem.RankLayout
import pl.krystiankaniowski.rank.core.formatter.cutAfter
import pl.krystiankaniowski.rank.core.formatter.formatRelative
import pl.krystiankaniowski.rank.core.model.MatchWithPlayers
import pl.krystiankaniowski.rank.feature.player.ui.PlayerAvatar

@Composable
fun RankMatchCard(
    match: MatchWithPlayers,
    exposePlayer: Long? = null,
    onMatchClick: (Long) -> Unit,
) {
    RankLayout.ContentCard {
        RankMatch(
            modifier = Modifier,
            match = match,
            exposePlayer = exposePlayer,
            onMatchClick = onMatchClick,
        )
    }
}

@Composable
fun RankMatch(
    modifier: Modifier = Modifier,
    match: MatchWithPlayers,
    exposePlayer: Long? = null,
    onMatchClick: (Long) -> Unit,
) {
    BoxWithConstraints {
        val maxWidth = maxWidth
        Row(
            modifier = modifier
                .clickable { onMatchClick(match.id) }
                .padding(vertical = 16.dp, horizontal = 16.dp),
            verticalAlignment = Alignment.CenterVertically,
        ) {
            Row(
                modifier = Modifier.weight(4f),
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.CenterVertically,
            ) {
                Text(
                    text = match.winner.name,
                    modifier = Modifier.weight(1f),
                    textAlign = TextAlign.End,
                    maxLines = 1,
                    overflow = TextOverflow.Ellipsis,
                    color = if (match.winner.id == exposePlayer) MaterialTheme.colorScheme.primary else Color.Unspecified,
                )
                PlayerAvatar(
                    modifier = Modifier
                        .padding(horizontal = 16.dp)
                        .size(32.dp),
                    player = match.winner,
                )
                Text(
                    text = "${match.winnerScore} : ${match.loserScore}",
                    fontWeight = androidx.compose.ui.text.font.FontWeight.Bold,
                )
                PlayerAvatar(
                    modifier = Modifier
                        .padding(horizontal = 16.dp)
                        .size(32.dp),
                    player = match.loser,
                )
                Text(
                    text = match.loser.name,
                    overflow = TextOverflow.Ellipsis,
                    modifier = Modifier.weight(1f),
                    textAlign = TextAlign.Start,
                    maxLines = 1,
                    color = if (match.loser.id == exposePlayer) MaterialTheme.colorScheme.primary else Color.Unspecified,
                )
            }
            if (maxWidth > 600.dp) {
                RankMatchColumn(
                    value = match.winnerEloChange.cutAfter(1),
                    label = "elo",
                )
            }
            if (maxWidth > 720.dp) {
                RankMatchColumn(
                    value = match.datetime.formatRelative(),
                    label = "date",
                )
            }
        }
    }
}

@Composable
private fun RowScope.RankMatchColumn(
    value: String,
    label: String,
) {
    Column(
        modifier = Modifier.padding(horizontal = 16.dp).weight(1f),
    ) {
        Text(
            text = value,
            modifier = Modifier.fillMaxWidth(),
            maxLines = 1,
        )
        Text(
            text = label,
            style = MaterialTheme.typography.bodySmall,
            color = MaterialTheme.colorScheme.onSurfaceVariant,
            modifier = Modifier.fillMaxWidth(),
            maxLines = 1,
        )
    }
}
