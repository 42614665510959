package pl.krystiankaniowski.rank.feature.player.summary

import androidx.compose.foundation.layout.*
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import pl.krystiankaniowski.rank.core.designsystem.RankDivider
import pl.krystiankaniowski.rank.core.designsystem.RankLayout
import pl.krystiankaniowski.rank.feature.player.ui.PlayerAvatar
import pl.krystiankaniowski.rank.model.Player

@Composable
fun PlayersCompareCard(
    player1: Player,
    player2: Player,
    player1stats: PlayerStat?,
    player2stats: PlayerStat?,
    modifier: Modifier = Modifier,
) {
    RankLayout.SupportPaneCard {
        Column(
            modifier = modifier
                .padding(bottom = 16.dp)
                .fillMaxWidth(),
        ) {
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(vertical = 16.dp),
                horizontalArrangement = Arrangement.Center,
                verticalAlignment = Alignment.CenterVertically,
            ) {
                PlayerAvatar(
                    modifier = Modifier.size(32.dp),
                    player = player1,
                )
                Text(
                    modifier = Modifier.padding(horizontal = 32.dp),
                    text = "VS",
                    style = MaterialTheme.typography.bodySmall,
                    maxLines = 1,
                )
                PlayerAvatar(
                    modifier = Modifier.size(32.dp),
                    player = player2,
                )
            }
            RankDivider(modifier = Modifier.padding(bottom = 16.dp))
            PlayerCompareCardRow(
                player1stats?.elo?.toString(),
                player2stats?.elo?.toString(),
                "ELO",
            )
            Spacer(modifier = Modifier.height(16.dp))
            PlayerCompareCardRow(
                player1stats?.wins?.times(100)?.toInt()?.let { "$it%" },
                player2stats?.wins?.times(100)?.toInt().let { "$it%" },
                "Wins",
            )
            Spacer(modifier = Modifier.height(16.dp))
            PlayerCompareCardRow(
                player1stats?.matches?.toString(),
                player2stats?.matches?.toString(),
                "Matches",
            )
            Spacer(modifier = Modifier.height(16.dp))
            PlayerCompareCardRow(
                player1stats?.opponents?.toString(),
                player2stats?.opponents?.toString(),
                "Rivals",
            )
        }
    }
}

@Composable
private fun PlayerCompareCardRow(
    player1Value: String?,
    player2Value: String?,
    label: String,
) {
    Row {
        Text(
            modifier = Modifier.weight(1f),
            text = player1Value ?: "---",
            color = MaterialTheme.colorScheme.primary,
            maxLines = 1,
            textAlign = TextAlign.End,
        )
        Text(
            modifier = Modifier.padding(horizontal = 16.dp).weight(1f),
            text = label,
            maxLines = 1,
            textAlign = TextAlign.Center,
        )
        Text(
            modifier = Modifier.weight(1f),
            text = player2Value ?: "---",
            color = MaterialTheme.colorScheme.primary,
            maxLines = 1,
        )
    }
}