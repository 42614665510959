package pl.krystiankaniowski.rank.feature.match.add.pending

import androidx.compose.material3.*
import androidx.compose.runtime.Composable
import kotlinx.datetime.LocalTime
import pl.krystiankaniowski.rank.core.designsystem.RankButton

@OptIn(ExperimentalMaterial3Api::class)
@Composable
internal fun SetMatchTimeDialog(
    currentTime: LocalTime,
    onResult: (LocalTime) -> Unit,
    onDismiss: () -> Unit,
) {

    val timePickerState = rememberTimePickerState(
        initialHour = currentTime.hour,
        initialMinute = currentTime.minute,
        is24Hour = true,
    )

    AlertDialog(
        onDismissRequest = onDismiss,
        text = {
            TimePicker(
                state = timePickerState,
                layoutType = TimePickerLayoutType.Horizontal,
            )
        },
        confirmButton = {
            RankButton.TextButton(
                text = "Set",
                onClick = {
                    onResult(LocalTime(timePickerState.hour, timePickerState.minute))
                    onDismiss()
                },
            )
        },
        dismissButton = {
            RankButton.TextButton(
                text = "Cancel",
                onClick = onDismiss,
            )
        },
    )
}
