package pl.krystiankaniowski.rank.feature.error

import androidx.compose.material3.AlertDialog
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import pl.krystiankaniowski.rank.core.UserError
import pl.krystiankaniowski.rank.core.designsystem.RankButton

@Composable
fun UserErrorDialog(error: UserError, onDismiss: () -> Unit) {
    AlertDialog(
        onDismissRequest = onDismiss,
        title = { Text("Error occurred") },
        text = {
            when (error) {
                is UserError.HttpError -> Text("HTTP Error: ${error.code}")
                is UserError.NetworkError -> Text("Network Error")
                is UserError.SerializationError -> Text("Serialization Error")
                is UserError.OtherError -> Text("Other Error")
            }
        },
        confirmButton = {
            RankButton.TextButton(
                text = "Close",
                onClick = onDismiss,
            )
        },
    )
}