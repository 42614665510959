package pl.krystiankaniowski.rank.feature.player.select

import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.staggeredgrid.LazyVerticalStaggeredGrid
import androidx.compose.foundation.lazy.staggeredgrid.StaggeredGridCells
import androidx.compose.foundation.lazy.staggeredgrid.StaggeredGridItemSpan
import androidx.compose.foundation.lazy.staggeredgrid.items
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedCard
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import pl.krystiankaniowski.rank.core.designsystem.*
import pl.krystiankaniowski.rank.feature.player.ui.PlayerTileSelectable

@Composable
fun PlayersSelectScreen(
    viewModel: PlayersSelectViewModel,
    openAddMatchScreen: (List<Long>) -> Unit,
    onBackPressed: () -> Unit,
) {

    LaunchedEffect(Unit) {
        viewModel.events.collect { event ->
            when (event) {
                is PlayersSelectViewModel.Event.OnPlayersConfirmed -> openAddMatchScreen(event.selectedPlayers)
            }
        }
    }

    RankLayout.Page(
        topBar = { RankTopBar.Solid(title = "Select players", onBackPressed = onBackPressed) },
    ) {
        when (val state = viewModel.state.collectAsState().value) {
            PlayersSelectViewModel.State.Loading -> RankScreenState.Loading()
            is PlayersSelectViewModel.State.SelectPlayers -> PlayersSelectScreenContent(
                state = state,
                onAction = viewModel::onAction,
            )
        }
    }
}

@Composable
private fun PlayersSelectScreenContent(
    state: PlayersSelectViewModel.State.SelectPlayers,
    onAction: (PlayersSelectViewModel.Action) -> Unit,
) {

    val screenSize = LocalScreenSize.current
    val padding = if (screenSize == RankScreenSize.Compact || screenSize == RankScreenSize.Medium) 16.dp else 24.dp
    val columnWidth = if (screenSize == RankScreenSize.Compact || screenSize == RankScreenSize.Medium) 96.dp else 128.dp

    Box {
        LazyVerticalStaggeredGrid(
            modifier = Modifier.fillMaxWidth(),
            contentPadding = PaddingValues(top = 16.dp, bottom = 96.dp + 16.dp),
            columns = StaggeredGridCells.Adaptive(columnWidth),
            verticalItemSpacing = padding,
            horizontalArrangement = Arrangement.spacedBy(padding),
        ) {
            item(span = StaggeredGridItemSpan.FullLine) {
                RankListItems.Header("Recent players")
            }
            items(state.recentPlayers) { player ->
                PlayerTileSelectable(
                    player = player,
                    isSelected = player in state.selectedPlayers,
                    onClick = { onAction(PlayersSelectViewModel.Action.OnPlayerClick(player)) },
                )
            }
            item(span = StaggeredGridItemSpan.FullLine) {
                RankListItems.Header("All players")
            }
            items(state.allPlayers) { player ->
                PlayerTileSelectable(
                    player = player,
                    isSelected = player in state.selectedPlayers,
                    onClick = { onAction(PlayersSelectViewModel.Action.OnPlayerClick(player)) },
                )
            }
        }
        Box(
            modifier = Modifier
                .align(Alignment.BottomCenter)
                .padding(bottom = 16.dp),
        ) {
            OutlinedCard(
                colors = CardDefaults.outlinedCardColors(
                    containerColor = MaterialTheme.colorScheme.surfaceContainer,
                ),
                elevation = CardDefaults.outlinedCardElevation(4.dp),
            ) {
                Row(
                    modifier = Modifier.padding(16.dp),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.Center,
                ) {
                    Text(
                        text = "${state.selectedPlayers.elementAtOrNull(0)?.name ?: "_____"} vs ${state.selectedPlayers.elementAtOrNull(1)?.name ?: "_____"}",
                        style = MaterialTheme.typography.headlineSmall,
                    )
                    Spacer(modifier = Modifier.width(16.dp))
                    RankButton.Primary(
                        enabled = state.isNextButtonEnabled,
                        text = "Next",
                        onClick = { onAction(PlayersSelectViewModel.Action.ConfirmPlayers) },
                    )
                }
            }
        }
    }
}