package pl.krystiankaniowski.rank.feature.match.ui

import androidx.compose.foundation.layout.*
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontWeight.Companion.Bold
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.dp
import pl.krystiankaniowski.rank.feature.player.ui.PlayerAvatar
import pl.krystiankaniowski.rank.model.Player

@Composable
fun UiMatchSet(
    @Suppress("UnusedParameter") setNumber: Int,
    player1: Player,
    player2: Player,
    player1Score: Long?,
    player2Score: Long?,
    setWinnerId: Long,
) {
    when {
        player1Score != null && player2Score != null -> SetScoreRow(player1, player1Score, player2Score, player2)
        else -> SetWinnerRow(if (player1.id == setWinnerId) player1 else player2)
    }
}

@Composable
private fun SetScoreRow(
    player1: Player,
    player1Score: Long?,
    player2Score: Long?,
    player2: Player,
) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .padding(vertical = 16.dp, horizontal = 16.dp),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically,
    ) {
        Text(
            text = player1.name,
            modifier = Modifier.weight(1f),
            textAlign = TextAlign.End,
            maxLines = 1,
            overflow = TextOverflow.Ellipsis,
        )
        PlayerAvatar(
            modifier = Modifier
                .padding(horizontal = 16.dp)
                .size(32.dp),
            player = player1,
        )
        Text(
            text = buildAnnotatedString {
                if ((player1Score ?: 0) > (player2Score ?: 0)) {
                    withStyle(style = SpanStyle(fontWeight = Bold)) {
                        append(player1Score.toString())
                    }
                } else {
                    append(player1Score.toString())
                }
                append(" : ")
                if ((player2Score ?: 0) > (player1Score ?: 0)) {
                    withStyle(style = SpanStyle(fontWeight = Bold)) {
                        append(player2Score.toString())
                    }
                } else {
                    append(player2Score.toString())
                }
            },
        )
        PlayerAvatar(
            modifier = Modifier
                .padding(horizontal = 16.dp)
                .size(32.dp),
            player = player2,
        )
        Text(
            text = player2.name,
            overflow = TextOverflow.Ellipsis,
            modifier = Modifier.weight(1f),
            textAlign = TextAlign.Start,
            maxLines = 1,
        )
    }
}

@Composable
private fun SetWinnerRow(
    winner: Player,
) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .padding(vertical = 16.dp, horizontal = 16.dp),
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically,
    ) {
        PlayerAvatar(
            modifier = Modifier
                .padding(horizontal = 16.dp)
                .size(32.dp),
            player = winner,
        )
        Text(
            text = "${winner.name} won",
            overflow = TextOverflow.Ellipsis,
            textAlign = TextAlign.Start,
            maxLines = 1,
        )
    }
}