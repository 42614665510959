package pl.krystiankaniowski.rank.core

import io.ktor.client.plugins.*
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.FlowCollector
import kotlinx.coroutines.flow.catch
import pl.krystiankaniowski.rank.core.networking.Response
import pl.krystiankaniowski.rank.model.ErrorBody

sealed class UserError {
    data class HttpError(val code: Int, val error: ErrorBody?) : UserError()
    data object NetworkError : UserError()
    data object SerializationError : UserError()
    data object OtherError : UserError()

    companion object {
        fun from(e: Exception): UserError = when (e) {
            is ClientRequestException -> NetworkError
            else -> OtherError
        }
    }
}

fun Response.Error.toUserError(): UserError {
    return when (this) {
        is Response.Error.HttpError -> UserError.HttpError(this.code, this.error)
        Response.Error.NetworkError -> UserError.NetworkError
        Response.Error.SerializationError -> UserError.SerializationError
    }
}

class UserException(val userError: UserError) : Exception()

fun <T> Flow<T>.catchUserException(action: suspend FlowCollector<T>.(cause: UserException) -> Unit): Flow<T> = catch<T> {
    when (it) {
        is UserException -> action(it)
        else -> throw it
    }
}