package pl.krystiankaniowski.rank.feature.match.detail

import androidx.compose.foundation.layout.padding
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import pl.krystiankaniowski.rank.core.designsystem.*
import pl.krystiankaniowski.rank.core.formatter.cutAfter
import pl.krystiankaniowski.rank.feature.match.ui.RankMatchHeader
import pl.krystiankaniowski.rank.feature.match.ui.UiMatchSet

@Composable
fun MatchScreen(
    viewModel: MatchViewModel,
    onBackPressed: () -> Unit,
) {

    RankLayout.Page(
        topBar = { RankTopBar.Solid(title = "Match", onBackPressed = onBackPressed) },
    ) {
        when (val state = viewModel.state.collectAsState().value) {
            is MatchViewModel.State.Loaded -> MatchScreenLoaded(state)
            MatchViewModel.State.Loading -> RankScreenState.Loading()
            is MatchViewModel.State.Error -> RankScreenState.Error(userError = state.userError)
        }
    }
}

@Composable
private fun MatchScreenLoaded(state: MatchViewModel.State.Loaded) {
    RankLayout.ContentScrollable {
        RankLayout.ContentCardExposed(
            modifier = Modifier.padding(16.dp),
        ) {
            RankMatchHeader(
                player1 = state.winner,
                player2 = state.loser,
                player1Score = state.winnerScore,
                player2Score = state.loserScore,
            )
        }
        state.history?.let { history ->
            RankLayout.ContentCard {
                history.forEachIndexed { index, matchSet ->
                    if (index > 0) {
                        RankDivider()
                    }
                    UiMatchSet(
                        setNumber = matchSet.number.toInt(),
                        player1 = state.winner,
                        player2 = state.loser,
                        player1Score = if (matchSet.winnerId == state.winner.id) matchSet.winnerScore else matchSet.loserScore,
                        player2Score = if (matchSet.winnerId == state.winner.id) matchSet.loserScore else matchSet.winnerScore,
                        setWinnerId = matchSet.winnerId,
                    )
                }
            }
        }
        RankLayout.ContentCard {
            RankListItems.Item(label = "Date", value = state.date)
        }
        RankLayout.ContentCard {
            RankListItems.Item(label = "Elo change", value = state.winnerEloChange.cutAfter(1))
        }
    }
}