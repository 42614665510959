package pl.krystiankaniowski.rank.feature.match.add.pending

import androidx.compose.foundation.*
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.AlertDialog
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import pl.krystiankaniowski.rank.core.designsystem.LocalScreenSize
import pl.krystiankaniowski.rank.core.designsystem.RankButton
import pl.krystiankaniowski.rank.core.designsystem.RankScreenSize
import pl.krystiankaniowski.rank.feature.player.ui.PlayerTile
import pl.krystiankaniowski.rank.model.Player
import kotlin.math.abs
import kotlin.math.max

@Composable
internal fun MatchAddSetResultDialog(
    player1: Player,
    player2: Player,
    onResult: (Int, Int) -> Unit,
    onDismiss: () -> Unit,
) {

    var player1Score: Int? by remember { mutableStateOf(null) }
    var player2Score: Int? by remember { mutableStateOf(null) }
    val isAddButtonEnabled: Boolean = remember(player1Score, player2Score) {
        val score1 = player1Score
        val score2 = player2Score
        when {
            score1 == null -> false
            score2 == null -> false
            score1 < 11 && score2 < 11 -> false
            abs(score2 - score1) < 2 -> false
            else -> true
        }
    }

    AlertDialog(
        onDismissRequest = onDismiss,
        title = { Text(text = "Set player scores") },
        text = {
            AddSetResultDialogContent(
                player1 = player1,
                player2 = player2,
                player1Score = player1Score,
                player2Score = player2Score,
                onSetPlayer1Score = {
                    player1Score = it
                    if (player2Score == null) {
                        player2Score = max(it + 2, 11)
                    }
                },
                onSetPlayer2Score = {
                    player2Score = it
                    if (player1Score == null) {
                        player1Score = max(it + 2, 11)
                    }
                },
            )
        },
        confirmButton = {
            RankButton.TextButton(
                text = "Add",
                enabled = isAddButtonEnabled,
                onClick = {
                    onResult(player1Score!!, player2Score!!)
                    player1Score = null
                    player2Score = null
                },
            )
        },
        dismissButton = {
            RankButton.TextButton(
                text = "Close",
                onClick = onDismiss,
            )
        },
    )
}

@Composable
private fun AddSetResultDialogContent(
    player1: Player,
    player2: Player,
    player1Score: Int?,
    player2Score: Int?,
    onSetPlayer1Score: (Int) -> Unit,
    onSetPlayer2Score: (Int) -> Unit,
) {
    Column(modifier = Modifier.width(IntrinsicSize.Max)) {

        val size = LocalScreenSize.current
        when (size) {
            RankScreenSize.Compact -> {
                Row(modifier = Modifier.fillMaxWidth()) {
                    Text(
                        modifier = Modifier.weight(1f),
                        textAlign = TextAlign.Center,
                        text = player1.name,
                    )
                    Spacer(Modifier.width(16.dp))
                    Text(
                        modifier = Modifier.weight(1f),
                        textAlign = TextAlign.Center,
                        text = player2.name,
                    )
                }
                Spacer(Modifier.height(16.dp))
                Column(
                    modifier = Modifier.verticalScroll(rememberScrollState()),
                    horizontalAlignment = Alignment.CenterHorizontally,
                ) {
                    Board(
                        rows = 8,
                        tilesInRow = 3,
                        player1Score = player1Score,
                        player2Score = player2Score,
                        onSetPlayer1Score = onSetPlayer1Score,
                        onSetPlayer2Score = onSetPlayer2Score,
                    )
                }
            }

            RankScreenSize.Medium -> {
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.SpaceEvenly,
                ) {
                    PlayerTile(modifier = Modifier.size(64.dp), player = player1, {})
                    Spacer(Modifier.width(16.dp))
                    PlayerTile(modifier = Modifier.size(64.dp), player = player2, {})
                }
                Spacer(Modifier.height(16.dp))
                Column(
                    modifier = Modifier.verticalScroll(rememberScrollState()),
                    horizontalAlignment = Alignment.CenterHorizontally,
                ) {
                    Board(
                        rows = 8,
                        tilesInRow = 3,
                        player1Score = player1Score,
                        player2Score = player2Score,
                        onSetPlayer1Score = onSetPlayer1Score,
                        onSetPlayer2Score = onSetPlayer2Score,
                    )
                }
            }

            RankScreenSize.Expanded, RankScreenSize.Large, RankScreenSize.ExtraLarge -> {
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.SpaceEvenly,
                ) {
                    PlayerTile(modifier = Modifier.size(96.dp), player = player1, {})
                    Spacer(Modifier.width(16.dp))
                    PlayerTile(modifier = Modifier.size(96.dp), player = player2, {})
                }
                Spacer(Modifier.height(16.dp))
                Column(
                    modifier = Modifier.verticalScroll(rememberScrollState()),
                    horizontalAlignment = Alignment.CenterHorizontally,
                ) {
                    Board(
                        rows = 6,
                        tilesInRow = 4,
                        player1Score = player1Score,
                        player2Score = player2Score,
                        onSetPlayer1Score = onSetPlayer1Score,
                        onSetPlayer2Score = onSetPlayer2Score,
                    )
                }
            }
        }
    }
}

@Composable
private fun Board(
    rows: Int,
    tilesInRow: Int,
    player1Score: Int?,
    player2Score: Int?,
    onSetPlayer1Score: (Int) -> Unit,
    onSetPlayer2Score: (Int) -> Unit,
) {
    for (row in 0..<rows) {
        Row {
            for (i in 0..<tilesInRow) {
                Cell(tilesInRow * row + i, tilesInRow * row + i == player1Score, onSetPlayer1Score)
            }
            Spacer(Modifier.width(16.dp))
            for (i in 0..<tilesInRow) {
                Cell(tilesInRow * row + i, tilesInRow * row + i == player2Score, onSetPlayer2Score)
            }
        }
    }
}

@Composable
private fun RowScope.Cell(value: Int, selected: Boolean, onSelect: (Int) -> Unit) {
    Box(
        modifier = Modifier
            .padding(1.dp)
            .border(
                width = 1.dp,
                color = if (selected) MaterialTheme.colorScheme.primary else MaterialTheme.colorScheme.surfaceVariant,
                shape = RoundedCornerShape(4.dp),
            )
            .background(
                color = if (selected) MaterialTheme.colorScheme.primaryContainer else MaterialTheme.colorScheme.background.copy(alpha = if (value <= 11) 1f else 0.4f),
                shape = RoundedCornerShape(4.dp),
            )
            .widthIn(min = 48.dp)
            .weight(1f)
            .aspectRatio(1f)
            .clickable { onSelect(value) },
        contentAlignment = Alignment.Center,
        content = {
            Text(
                text = value.toString(),
                style = MaterialTheme.typography.bodyLarge,
            )
        },
    )
}
