package pl.krystiankaniowski.rank.feature.player.detail

import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.staggeredgrid.LazyVerticalStaggeredGrid
import androidx.compose.foundation.lazy.staggeredgrid.StaggeredGridCells
import androidx.compose.foundation.lazy.staggeredgrid.rememberLazyStaggeredGridState
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import kotlinx.datetime.LocalDateTime
import pl.krystiankaniowski.rank.core.designsystem.*
import pl.krystiankaniowski.rank.feature.match.ui.RankMatch
import pl.krystiankaniowski.rank.feature.player.elo.EloCard
import pl.krystiankaniowski.rank.feature.player.ui.PlayerAvatar
import pl.krystiankaniowski.rank.model.Player
import rank.client.shared.generated.resources.Res
import rank.client.shared.generated.resources.icon_archive
import rank.client.shared.generated.resources.icon_unarchive

@Composable
fun PlayerDetailScreen(
    viewModel: PlayerDetailViewModel,
    openPlayerCompareScreen: (Long, Long) -> Unit,
    openPlayerMatchesScreen: (Long) -> Unit,
    openMatchScreen: (Long) -> Unit,
    openPlayerOpponentsScreen: (Long) -> Unit,
    onBackPressed: () -> Unit,
) {
    LaunchedEffect(Unit) {
        viewModel.events.collect {
            when (it) {
                is PlayerDetailViewModel.Event.OpenCompareScreen -> openPlayerCompareScreen(it.player1Id, it.player2Id)
                is PlayerDetailViewModel.Event.OpenPlayerMatchesScreen -> openPlayerMatchesScreen(it.playerId)
                is PlayerDetailViewModel.Event.OpenMatchScreen -> openMatchScreen(it.matchId)
                is PlayerDetailViewModel.Event.OpenPlayerOpponentsScreen -> openPlayerOpponentsScreen(it.playerId)
            }
        }
    }

    RankLayout.Page(
        topBar = {
            RankTopBar.Solid(
                title = "Player",
                actions = when (val state = viewModel.state.collectAsState().value) {
                    is PlayerDetailViewModel.State.Error -> emptyList()
                    is PlayerDetailViewModel.State.Loaded -> listOf(
                        RankTopBar.Action.Icon(
                            icon = if (state.player.isActive) Res.drawable.icon_archive else Res.drawable.icon_unarchive,
                            onClick = { viewModel.onAction(PlayerDetailViewModel.Action.OnArchiveButtonClicked) },
                        ),
                    )

                    PlayerDetailViewModel.State.Loading -> emptyList()
                },
                onBackPressed = onBackPressed,
            )
        },
    ) {
        when (val state = viewModel.state.collectAsState().value) {
            is PlayerDetailViewModel.State.Loaded -> PlayerDetailScreenLoaded(state = state, onAction = viewModel::onAction)
            PlayerDetailViewModel.State.Loading -> RankScreenState.Loading()
            is PlayerDetailViewModel.State.Error -> RankScreenState.Error(userError = state.userError)
        }
    }
}

@Composable
private fun PlayerDetailScreenLoaded(
    state: PlayerDetailViewModel.State.Loaded,
    onAction: (PlayerDetailViewModel.Action) -> Unit,
) {
    BoxWithConstraints {
        LazyVerticalStaggeredGrid(
            columns = StaggeredGridCells.Fixed(if (maxWidth > 1000.dp) 2 else 1),
            modifier = Modifier.fillMaxSize(),
            state = rememberLazyStaggeredGridState(),
            horizontalArrangement = Arrangement.spacedBy(16.dp),
            verticalItemSpacing = 16.dp,
            contentPadding = PaddingValues(vertical = 16.dp),
            content = {
                item {
                    PlayerHeader(
                        player = state.player,
                    )
                }
                item {
                    PlayerStats(
                        numberOfMatches = state.matchesCount,
                        winRation = state.winRatio,
                        opponentCount = state.opponentCount,
                        bestStreak = state.bestStreak,
                    )
                }
                item {
                    PlayerMatches(
                        state = state,
                        onAction = onAction,
                    )
                }
                item {
                    PlayerOpponents(
                        onAction = onAction,
                        players = state.favouritePlayers.players,
                    )
                }
                item {
                    PlayerElo(
                        history = state.eloHistory,
                    )
                }
            },
        )
    }
}

@Composable
private fun PlayerHeader(
    player: Player,
) {
    RankLayout.ContentCardExposed {
        Row(
            modifier = Modifier.padding(16.dp),
            verticalAlignment = Alignment.CenterVertically,
        ) {
            PlayerAvatar(
                modifier = Modifier.size(128.dp),
                player = player,
            )
            Spacer(modifier = Modifier.width(24.dp))
            Column(
                modifier = Modifier
                    .fillMaxHeight(),
                verticalArrangement = Arrangement.Center,
            ) {
                Text(
                    text = player.name,
                    style = MaterialTheme.typography.displaySmall,
                    maxLines = 1,
                )
                Text(
                    text = player.elo.toInt().toString(),
                    style = MaterialTheme.typography.headlineSmall,
                    color = MaterialTheme.colorScheme.primary,
                    maxLines = 1,
                )
            }
        }
    }
}

@Composable
private fun PlayerStats(
    numberOfMatches: Int,
    bestStreak: Int,
    opponentCount: Int,
    winRation: String,
) {
    RankLayout.ContentCard {
        Row(
            modifier = Modifier
                .height(160.dp)
                .fillMaxWidth()
                .padding(16.dp),
            verticalAlignment = Alignment.CenterVertically,
        ) {
            PlayerStatsTile(
                value = winRation,
                label = "Wins",
            )
            PlayerStatsTile(
                value = numberOfMatches.toString(),
                label = "Matches",
            )
            PlayerStatsTile(
                value = opponentCount.toString(),
                label = "Rivals",
            )
            PlayerStatsTile(
                value = bestStreak.toString(),
                label = "Streak",
            )
        }
    }
}

@Composable
private fun RowScope.PlayerStatsTile(
    value: String,
    label: String,
) {
    Column(
        modifier = Modifier.weight(1f).padding(8.dp),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally,
    ) {
        Text(value, style = MaterialTheme.typography.headlineSmall)
        Spacer(modifier = Modifier.height(4.dp))
        Text(label, style = MaterialTheme.typography.bodySmall)
    }
}

@Composable
private fun PlayerMatches(
    state: PlayerDetailViewModel.State.Loaded,
    onAction: (PlayerDetailViewModel.Action) -> Unit,
) {
    RankLayout.ContentCard {
        RankListItems.Header(text = "Last matches")
        Column {
            state.lastMatches.forEach { match ->
                RankDivider()
                RankMatch(
                    match = match,
                    exposePlayer = state.player.id,
                    onMatchClick = { onAction(PlayerDetailViewModel.Action.OnMatchClick(match.id)) },
                )
            }
        }
        if (state.isMoreMatchesButtonVisible) {
            RankDivider()
            Row(
                modifier = Modifier.fillMaxWidth().padding(16.dp),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.End,
            ) {
                RankButton.TextButton(
                    text = "Show more",
                    onClick = { onAction(PlayerDetailViewModel.Action.OnShowMoreMatchesClick) },
                )
            }
        }
    }
}

@OptIn(ExperimentalLayoutApi::class)
@Composable
private fun PlayerOpponents(
    onAction: (PlayerDetailViewModel.Action) -> Unit,
    players: List<Player>,
) {
    RankLayout.ContentCard {
        RankListItems.Header(text = "Rivals")
        RankDivider()
        FlowRow(
            modifier = Modifier
                .fillMaxWidth()
                .padding(16.dp),
            horizontalArrangement = Arrangement.spacedBy(16.dp),
            verticalArrangement = Arrangement.spacedBy(16.dp),
        ) {
            players.forEach { player ->
                PlayerAvatar(
                    modifier = Modifier
                        .size(64.dp)
                        .clickable { onAction(PlayerDetailViewModel.Action.OnRivalClick(player.id)) },
                    player = player,
                )
            }
        }
        RankDivider()
        Row(
            modifier = Modifier.fillMaxWidth().padding(16.dp),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.End,
        ) {
            RankButton.TextButton(
                text = "Show more",
                onClick = { onAction(PlayerDetailViewModel.Action.OnRivalsClick) },
            )
        }
    }
}

@Composable
private fun PlayerElo(history: Map<LocalDateTime, Double>) {
    RankLayout.ContentCard {
        RankListItems.Header(text = "Elo history")
        RankDivider()
        EloCard(eloHistory = history)
    }
}