package pl.krystiankaniowski.rank.feature.main

import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.SharedFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.DrawableResource
import org.koin.core.annotation.Single
import pl.krystiankaniowski.rank.Graph
import pl.krystiankaniowski.rank.feature.setting.Settings
import rank.client.shared.generated.resources.*

@Single
class MainViewModel(
    private val settings: Settings,
) : ViewModel() {

    private val _state: MutableStateFlow<State> = MutableStateFlow(State())
    val state: StateFlow<State> = _state

    private val _events: MutableSharedFlow<Event> = MutableSharedFlow()
    val events: SharedFlow<Event> = _events

    init {
        viewModelScope.launch {
            settings.arbiterModeFlow.collect {
                _state.value = _state.value.copy(
                    isAddButtonVisible = it,
                )
            }
        }
    }

    fun onMainNavigationItemClick(screen: MainDestination) {
        viewModelScope.launch {
            _state.value = _state.value.copy(current = screen)
            _events.emit(Event.ChangeScreen(screen))
        }
    }

    data class State(
        val navigationItems: List<MainDestination> = MainDestination.entries,
        val current: MainDestination = MainDestination.entries.first(),
        val isAddButtonVisible: Boolean = false,
    )

    sealed class Event {
        data class ChangeScreen(val destination: MainDestination) : Event()
    }
}

enum class MainDestination(
    val id: String,
    val title: String,
    val icon: DrawableResource,
    val destination: Graph,
) {
    Rank(
        id = "rank",
        title = "Rank",
        icon = Res.drawable.icon_rank,
        destination = Graph.Rank,
    ),
    Matches(
        id = "matches",
        title = "Matches",
        icon = Res.drawable.icon_history,
        destination = Graph.History,
    ),
    Players(
        id = "players",
        title = "Players",
        icon = Res.drawable.icon_players,
        destination = Graph.Players,
    ),
    Settings(
        id = "settings",
        title = "Settings",
        icon = Res.drawable.icon_settings,
        destination = Graph.Settings,
    ),
}