package pl.krystiankaniowski.rank.feature.dev.usecase

import org.koin.core.annotation.Factory
import pl.krystiankaniowski.rank.core.networking.RankApi

@Factory
class ForceRecalculateEloUseCase(
    private val rankApi: RankApi,
    private val forceReloadAppDataUseCase: ForceReloadAppDataUseCase,
) {

    suspend operator fun invoke() {
        rankApi.recalculateElo()
        forceReloadAppDataUseCase()
    }
}