package pl.krystiankaniowski.rank.feature.match.detail

import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import org.koin.core.annotation.Factory
import org.koin.core.annotation.InjectedParam
import pl.krystiankaniowski.rank.core.UserError
import pl.krystiankaniowski.rank.core.catchUserException
import pl.krystiankaniowski.rank.core.formatter.DateTimeFormatter
import pl.krystiankaniowski.rank.core.repository.MatchRepository
import pl.krystiankaniowski.rank.model.MatchSet
import pl.krystiankaniowski.rank.model.Player

@Factory
class MatchViewModel(
    @InjectedParam private val id: Long,
    private val matchRepository: MatchRepository,
    private val dateTimeFormatter: DateTimeFormatter,
) : ViewModel() {

    private val _state: MutableStateFlow<State> = MutableStateFlow(State.Loading)
    val state: StateFlow<State> = _state

    init {
        viewModelScope.launch {
            matchRepository.getMatch(id)
                .catchUserException {
                    _state.value = State.Error(it.userError)
                }
                .map {
                    when (it) {
                        null -> State.Error(UserError.OtherError)
                        else -> State.Loaded(
                            id = it.id,
                            date = dateTimeFormatter.format(
                                date = it.datetime,
                                mode = DateTimeFormatter.Mode.DATETIME,
                            ),
                            winner = it.winner,
                            loser = it.loser,
                            winnerScore = it.winnerScore.toInt(),
                            loserScore = it.loserScore.toInt(),
                            winnerEloChange = it.winnerEloChange,
                            loserEloChange = it.loserEloChange,
                            history = it.history,
                        )
                    }
                }
                .collect {
                    _state.value = it
                }
        }
    }

    sealed interface State {
        data object Loading : State
        data class Error(val userError: UserError) : State
        data class Loaded(
            val id: Long,
            val date: String,
            val winner: Player,
            val loser: Player,
            val winnerScore: Int,
            val loserScore: Int,
            val winnerEloChange: Double,
            val loserEloChange: Double,
            val history: List<MatchSet>?,
        ) : State
    }
}