package pl.krystiankaniowski.rank.feature.stat

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import pl.krystiankaniowski.rank.core.designsystem.RankLayout
import pl.krystiankaniowski.rank.core.designsystem.RankListItems
import pl.krystiankaniowski.rank.core.designsystem.RankScreenState
import pl.krystiankaniowski.rank.core.designsystem.RankTopBar

@Composable
fun StatScreen(
    viewModel: StatViewModel,
    onBackPressed: () -> Unit,
) {
    RankLayout.Page(
        topBar = {
            RankTopBar.Solid(
                title = "Stats",
                onBackPressed = onBackPressed,
            )
        },
    ) {
        when (val state = viewModel.state.collectAsState().value) {
            is StatViewModel.State.Loaded -> StatScreenLoaded(state)
            StatViewModel.State.Loading -> RankScreenState.Loading()
            is StatViewModel.State.Error -> RankScreenState.Error(userError = state.userError)
        }
    }
}

@Composable
private fun StatScreenLoaded(
    state: StatViewModel.State.Loaded,
) {
    RankLayout.ContentScrollable {
        RankLayout.ContentCard {
            RankListItems.Header("Numbers")
            RankListItems.Item("Matches", state.numberOfMatches.toString())
            RankListItems.Item("Sets", state.numberOfSets.toString())
            RankListItems.Item("Points", state.numberOfPoints.toString())
            RankListItems.Item("Players", state.numberOfPlayers.toString())
        }
    }
}