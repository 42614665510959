package pl.krystiankaniowski.rank.core.networking

import io.ktor.client.*
import org.koin.core.annotation.Module
import org.koin.core.annotation.Single

@Module
class NetworkingModule {

    @Single
    fun httpClient(): HttpClient = HttpClientBuilder().build()
}