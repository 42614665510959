package pl.krystiankaniowski.rank.feature.setting

import org.jetbrains.compose.resources.DrawableResource

sealed interface SettingEntry {

    data class Item(
        val label: String,
        val description: String? = null,
        val icon: DrawableResource? = null,
        val action: (() -> Unit)? = null,
    ) : SettingEntry

    data class Group(
        val title: String,
        val items: List<Item>,
    ) : SettingEntry
}