package pl.krystiankaniowski.rank.core.designsystem

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.LazyListScope
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.ElevatedCard
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Scaffold
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import pl.krystiankaniowski.rank.core.designsystem.RankScreenSize.Companion.margin

object RankLayout {

    @Composable
    fun Page(
        modifier: Modifier = Modifier,
        topBar: @Composable () -> Unit,
        floatingActionButton: @Composable () -> Unit = {},
        supportPane: (@Composable () -> Unit)? = null,
        content: @Composable () -> Unit,
    ) {

        val screenSize = LocalScreenSize.current

        Box(
            modifier = modifier
                .fillMaxSize()
                .background(MaterialTheme.colorScheme.surface),
            contentAlignment = Alignment.TopCenter,
        ) {
            Box(
                modifier = Modifier.widthIn(max = 1280.dp),
                contentAlignment = Alignment.TopCenter,
            ) {
                Scaffold(
                    containerColor = MaterialTheme.colorScheme.surface,
                    topBar = { topBar() },
                    floatingActionButton = floatingActionButton,
                ) { paddingValues ->
                    BoxWithConstraints(
                        modifier = Modifier.padding(paddingValues)
                            .then(
                                when (screenSize) {
                                    RankScreenSize.Compact -> Modifier.padding(horizontal = screenSize.margin())
                                    RankScreenSize.Medium -> Modifier.padding(end = screenSize.margin())
                                    RankScreenSize.Expanded -> Modifier.padding(end = screenSize.margin())
                                    RankScreenSize.Large -> Modifier.padding(end = screenSize.margin())
                                    RankScreenSize.ExtraLarge -> Modifier.padding(end = screenSize.margin())
                                },
                            ),
                    ) {
                        Row(modifier = Modifier.fillMaxWidth()) {
                            Box(
                                modifier = Modifier.weight(1f),
                                content = { content() },
                            )
                            if (this@BoxWithConstraints.maxWidth > 800.dp && supportPane != null) {
                                Spacer(Modifier.width(screenSize.margin()))
                                Box(
                                    modifier = Modifier.width(320.dp),
                                    content = { supportPane() },
                                )
                            }
                        }
                    }
                }
            }
        }
    }

    @Composable
    fun Content(
        modifier: Modifier = Modifier,
        content: @Composable ColumnScope.() -> Unit,
    ) {
        Column(
            modifier = Modifier
                .padding(vertical = 16.dp)
                .then(modifier),
            content = content,
            verticalArrangement = Arrangement.spacedBy(16.dp),
        )
    }

    @Composable
    fun ContentScrollable(
        modifier: Modifier = Modifier,
        content: @Composable ColumnScope.() -> Unit,
    ) {
        Column(
            modifier = Modifier
                .verticalScroll(rememberScrollState())
                .padding(vertical = 16.dp)
                .then(modifier),
            content = content,
            verticalArrangement = Arrangement.spacedBy(16.dp),
        )
    }

    @Composable
    fun ContentLazy(
        modifier: Modifier = Modifier,
        content: LazyListScope.() -> Unit,
    ) {
        LazyColumn(
            modifier = modifier,
            content = content,
            verticalArrangement = Arrangement.spacedBy(16.dp),
            contentPadding = PaddingValues(vertical = 16.dp),
        )
    }

    @Composable
    fun ContentCard(
        modifier: Modifier = Modifier,
        content: @Composable ColumnScope.() -> Unit,
    ) {
        ElevatedCard(
            modifier = Modifier.fillMaxWidth(),
            colors = CardDefaults.elevatedCardColors(
                containerColor = MaterialTheme.colorScheme.surfaceContainer,
            ),
            content = {
                Column(
                    modifier = modifier,
                    content = content,
                )
            },
        )
    }

    @Composable
    fun ContentCardExposed(
        modifier: Modifier = Modifier,
        content: @Composable ColumnScope.() -> Unit,
    ) {
        ElevatedCard(
            modifier = Modifier.fillMaxWidth(),
            colors = CardDefaults.elevatedCardColors(
                containerColor = MaterialTheme.colorScheme.surfaceContainerHighest,
            ),
            content = {
                Column(
                    modifier = modifier,
                    content = content,
                )
            },
        )
    }

    @Composable
    fun ContentRow(
        modifier: Modifier = Modifier,
        content: @Composable ColumnScope.() -> Unit,
    ) {
        ElevatedCard(
            modifier = Modifier.fillMaxWidth(),
            shape = RoundedCornerShape(4.dp),
            content = {
                Column(
                    modifier = modifier,
                    content = content,
                )
            },
        )
    }

    @Composable
    fun SupportPane(
        modifier: Modifier = Modifier,
        content: @Composable ColumnScope.() -> Unit,
    ) {
        Column(
            modifier = Modifier
                .verticalScroll(rememberScrollState())
                .then(modifier),
            verticalArrangement = Arrangement.spacedBy(16.dp),
            content = content,
        )
    }

    @Composable
    fun SupportPaneCard(
        modifier: Modifier = Modifier,
        content: @Composable ColumnScope.() -> Unit,
    ) {
        ElevatedCard(
            modifier = Modifier.fillMaxWidth(),
            colors = CardDefaults.elevatedCardColors(
                containerColor = MaterialTheme.colorScheme.surfaceContainerLow,
            ),
            content = {
                Column(
                    modifier = modifier,
                    content = content,
                )
            },
        )
    }
}