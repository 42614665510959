package pl.krystiankaniowski.rank.feature.player.compare

import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.itemsIndexed
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.unit.dp
import pl.krystiankaniowski.rank.core.designsystem.*
import pl.krystiankaniowski.rank.feature.match.ui.RankMatch
import pl.krystiankaniowski.rank.feature.match.ui.RankMatchHeader

@Composable
fun PlayerCompareScreen(
    viewModel: PlayerCompareViewModel,
    openMatchScreen: (Long) -> Unit,
    onBackPressed: () -> Unit,
) {

    LaunchedEffect(Unit) {
        viewModel.events.collect {
            when (it) {
                is PlayerCompareViewModel.Event.OpenMatchScreen -> openMatchScreen(it.matchId)
            }
        }
    }

    RankLayout.Page(
        topBar = { RankTopBar.Solid(title = "Compare", onBackPressed = onBackPressed) },
    ) {
        when (val state = viewModel.state.collectAsState().value) {
            is PlayerCompareViewModel.State.Data -> PlayerCompareScreenLoaded(state = state, onAction = viewModel::onAction)
            PlayerCompareViewModel.State.Error -> RankScreenState.Error("TODO()")
            PlayerCompareViewModel.State.Loading -> RankScreenState.Loading()
        }
    }
}

@OptIn(ExperimentalFoundationApi::class)
@Composable
private fun PlayerCompareScreenLoaded(
    state: PlayerCompareViewModel.State.Data,
    onAction: (PlayerCompareViewModel.Action) -> Unit,
) {
    LazyColumn(
        contentPadding = PaddingValues(vertical = 16.dp),
    ) {
        stickyHeader {
            RankLayout.ContentCardExposed(
                modifier = Modifier.padding(16.dp),
            ) {
                RankMatchHeader(
                    player1 = state.player1,
                    player2 = state.player2,
                    player1Score = state.player1stats.winsAgainst,
                    player2Score = state.player2stats.winsAgainst,
                )
            }
        }
        state.matches.forEach { matchesAtDate ->
            item(key = matchesAtDate.key.toString()) {
                RankListItems.Header(matchesAtDate.key.toString())
            }
            itemsIndexed(matchesAtDate.value) { index, match ->
                if (index > 0) RankDivider()
                RankMatch(
                    modifier = Modifier
                        .clip(RankListUtils.getListShape(matchesAtDate.value, index))
                        .background(MaterialTheme.colorScheme.surfaceContainer),
                    match = match,
                    onMatchClick = { onAction(PlayerCompareViewModel.Action.OnMatchClick(it)) },
                )
            }
        }
    }
}