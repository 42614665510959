package pl.krystiankaniowski.rank.feature.rank

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.itemsIndexed
import androidx.compose.foundation.shape.CornerSize
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment.Companion.CenterVertically
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import pl.krystiankaniowski.rank.core.designsystem.*
import pl.krystiankaniowski.rank.feature.player.ui.PlayerAvatar
import pl.krystiankaniowski.rank.feature.rank.RankViewModel.SortBy
import rank.client.shared.generated.resources.Res
import rank.client.shared.generated.resources.icon_filter_all
import rank.client.shared.generated.resources.icon_filter_classified
import rank.client.shared.generated.resources.icon_sort

@Composable
fun RankScreen(
    viewModel: RankViewModel,
    openPlayerDetailsScreen: (Long) -> Unit,
) {

    LaunchedEffect(Unit) {
        viewModel.events.collect { event ->
            when (event) {
                is RankViewModel.Event.OpenPlayerDetailsScreen -> openPlayerDetailsScreen(event.id)
            }
        }
    }

    when (val state = viewModel.state.collectAsState().value) {
        RankViewModel.State.Loading -> {
            RankLayout.Page(
                topBar = { RankTopBar.Solid(title = "Rank") },
                content = { RankScreenState.Loading() },
            )
        }

        is RankViewModel.State.Error -> {
            RankLayout.Page(
                topBar = { RankTopBar.Solid(title = "Rank") },
                content = { RankScreenState.Error(userError = state.userError) },
            )
        }

        is RankViewModel.State.Loaded -> {

            var openSortDialog by remember { mutableStateOf(false) }

            if (openSortDialog) {
                RankDialog.Select(
                    title = "Rank by",
                    items = SortBy.entries,
                    selectedItem = state.sortBy,
                    formatter = ::formatMode,
                    onSelect = { mode -> viewModel.onAction(RankViewModel.Action.OnModeChange(mode)) },
                    onDismiss = { openSortDialog = false },
                )
            }

            RankLayout.Page(
                topBar = {
                    RankTopBar.Solid(
                        title = "Rank",
                        actions = listOf(
                            RankTopBar.Action.Icon(
                                icon = when (state.display) {
                                    RankViewModel.Display.All -> Res.drawable.icon_filter_all
                                    RankViewModel.Display.Classified -> Res.drawable.icon_filter_classified
                                },
                                onClick = { viewModel.onAction(RankViewModel.Action.OnDisplayClick) },
                            ),
                            RankTopBar.Action.Icon(
                                icon = Res.drawable.icon_sort,
                                onClick = { openSortDialog = true },
                            ),
                        ),
                    )
                },
                content = {
                    RankScreenLoaded(
                        state = state,
                        onAction = viewModel::onAction,
                    )
                },
            )
        }
    }
}

@Composable
private fun RankScreenLoaded(
    state: RankViewModel.State.Loaded,
    onAction: (RankViewModel.Action) -> Unit,
) {
    Column(
        modifier = Modifier.padding(top = 16.dp),
    ) {
        Column(
            modifier = Modifier
                .clip(shape = MaterialTheme.shapes.medium.copy(bottomStart = CornerSize(0.dp), bottomEnd = CornerSize(0.dp)))
                .fillMaxWidth()
                .background(MaterialTheme.colorScheme.surfaceContainer),
        ) {
            Column {
                Row(
                    modifier = Modifier
                        .background(MaterialTheme.colorScheme.surfaceContainer)
                        .padding(16.dp),
                ) {
                    Spacer(modifier = Modifier.width(64.dp))
                    Text(
                        modifier = Modifier.weight(1.5f),
                        text = "Player",
                        fontWeight = FontWeight.Bold,
                        maxLines = 1,
                    )
                    Text(
                        modifier = Modifier.weight(1f),
                        text = "Elo",
                        fontWeight = FontWeight.Bold,
                        maxLines = 1,
                    )
                    Text(
                        modifier = Modifier.weight(1f),
                        text = "Wins",
                        fontWeight = FontWeight.Bold,
                        maxLines = 1,
                    )
                    Text(
                        modifier = Modifier.weight(1f),
                        text = "Matches",
                        fontWeight = FontWeight.Bold,
                        maxLines = 1,
                    )
                    Text(
                        modifier = Modifier.weight(1f),
                        text = "Rivals",
                        fontWeight = FontWeight.Bold,
                        maxLines = 1,
                    )
                    Text(
                        modifier = Modifier.weight(1f),
                        text = "Strike",
                        fontWeight = FontWeight.Bold,
                        maxLines = 1,
                    )
                }
                RankDivider()
            }
        }
        LazyColumn(
            modifier = Modifier.fillMaxWidth(),
            contentPadding = PaddingValues(top = 0.dp, bottom = 16.dp),
        ) {
            itemsIndexed(state.displayData) { index, stat ->
                if (index != 0) {
                    RankDivider()
                }
                Row(
                    modifier = Modifier
                        .clickable { onAction(RankViewModel.Action.OnPlayerClick(stat.player.id)) }
                        .background(MaterialTheme.colorScheme.surfaceContainer)
                        .padding(16.dp),
                    verticalAlignment = CenterVertically,
                ) {
                    Text(
                        modifier = Modifier.width(64.dp),
                        text = stat.position.toString(),
                    )
                    Row(
                        modifier = Modifier.weight(1.5f),
                        verticalAlignment = CenterVertically,
                    ) {
                        PlayerAvatar(modifier = Modifier.size(32.dp), player = stat.player)
                        Spacer(modifier = Modifier.width(16.dp))
                        Text(
                            text = stat.player.name,
                        )
                    }
                    Text(
                        modifier = Modifier.weight(1f),
                        text = if (stat.isClassified) stat.elo.toString() else "-----",
                    )
                    Text(
                        modifier = Modifier.weight(1f),
                        text = stat.winRatioString,
                    )
                    Text(
                        modifier = Modifier.weight(1f),
                        text = stat.matchesCount.toString(),
                    )
                    Text(
                        modifier = Modifier.weight(1f),
                        text = stat.rivals.toString(),
                    )
                    Text(
                        modifier = Modifier.weight(1f),
                        text = stat.longestStrike.toString(),
                    )
                }
            }
            item {
                Box(
                    modifier = Modifier
                        .fillMaxWidth()
                        .height(16.dp)
                        .clip(shape = MaterialTheme.shapes.medium.copy(topStart = CornerSize(0.dp), topEnd = CornerSize(0.dp)))
                        .background(MaterialTheme.colorScheme.surfaceContainer),
                )
            }
        }
    }
}

private fun formatMode(sortBy: SortBy): String {
    return when (sortBy) {
        SortBy.Elo -> "Elo"
        SortBy.Wins -> "Wins"
        SortBy.Matches -> "Matches"
        SortBy.Rivals -> "Rivals"
        SortBy.LongestStrike -> "Strike"
    }
}