package pl.krystiankaniowski.rank.feature.main

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import androidx.navigation.NavHostController
import org.jetbrains.compose.resources.painterResource
import pl.krystiankaniowski.rank.AppGraph
import pl.krystiankaniowski.rank.core.designsystem.LocalScreenSize
import pl.krystiankaniowski.rank.core.designsystem.RankButton
import pl.krystiankaniowski.rank.core.designsystem.RankListItems
import pl.krystiankaniowski.rank.core.designsystem.RankScreenSize.*
import rank.client.shared.generated.resources.Res
import rank.client.shared.generated.resources.icon_add

@Composable
fun MainScreen(
    viewModel: MainViewModel,
    navHostController: NavHostController,
) {

    val state by viewModel.state.collectAsState()
    val mode = LocalScreenSize.current

    var openAddDialog by remember { mutableStateOf(false) }

    LaunchedEffect(Unit) {
        viewModel.events.collect { event ->
            when (event) {
                is MainViewModel.Event.ChangeScreen -> {
                    navHostController.navigate(event.destination.destination.route)
                }
            }
        }
    }

    PermanentNavigationDrawer(
        drawerContent = {
            when (mode) {
                Compact -> Unit
                Medium, Expanded -> RailMenu(
                    floatingActionButton = if (state.isAddButtonVisible) {
                        {
                            if (state.isAddButtonVisible) {
                                FloatingActionButton(
                                    onClick = { openAddDialog = true },
                                    content = { Icon(painter = painterResource(Res.drawable.icon_add), contentDescription = "Add") },
                                )
                            }
                        }
                    } else {
                        null
                    },
                    menu = state.navigationItems,
                    selected = state.current,
                    onSelect = viewModel::onMainNavigationItemClick,
                )

                Large, ExtraLarge -> SideMenu(
                    extendedFloatingActionButton = if (state.isAddButtonVisible) {
                        {
                            if (state.isAddButtonVisible) {
                                ExtendedFloatingActionButton(
                                    onClick = { openAddDialog = true },
                                    icon = { Icon(painter = painterResource(Res.drawable.icon_add), contentDescription = "Add") },
                                    text = { Text("Add") },
                                )
                            }
                        }
                    } else {
                        null
                    },
                    menu = state.navigationItems,
                    selected = state.current,
                    onSelect = viewModel::onMainNavigationItemClick,
                )
            }
        },
    ) {
        Scaffold(
            bottomBar = {
                when (mode) {
                    Compact -> BottomMenu(
                        menu = state.navigationItems,
                        selected = state.current,
                        onSelect = viewModel::onMainNavigationItemClick,
                    )

                    else -> Unit
                }
            },
        ) { paddingValues ->
            Box(Modifier.padding(paddingValues)) {
                AppGraph(
                    navHostController = navHostController,
                    state = viewModel.state.value,
                )
            }
            if (openAddDialog) {
                AddDialog(
                    openPlayerAddScreen = { navHostController.navigate("playerAdd") },
                    openAddMatchScreen = { navHostController.navigate("player-select") },
                    onDismiss = { openAddDialog = false },
                )
            }
        }
    }
}

@Composable
private fun AddDialog(
    openPlayerAddScreen: () -> Unit,
    openAddMatchScreen: () -> Unit,
    onDismiss: () -> Unit,
) {
    AlertDialog(
        text = {
            Column {
                RankListItems.Item(
                    label = "Add new player",
                    onClick = {
                        onDismiss()
                        openPlayerAddScreen()
                    },
                )
                RankListItems.Item(
                    label = "Start new match",
                    onClick = {
                        onDismiss()
                        openAddMatchScreen()
                    },
                )
            }
        },
        onDismissRequest = onDismiss,
        confirmButton = {
            RankButton.TextButton(
                onClick = onDismiss,
                text = "Cancel",
            )
        },
    )
}
