package pl.krystiankaniowski.rank.feature.match.add

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.padding
import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import pl.krystiankaniowski.rank.core.designsystem.*
import pl.krystiankaniowski.rank.feature.match.add.pending.MatchAddSetDateDialog
import pl.krystiankaniowski.rank.feature.match.add.pending.MatchAddSetResultDialog
import pl.krystiankaniowski.rank.feature.match.add.pending.MatchAddSetWinnerDialog
import pl.krystiankaniowski.rank.feature.match.add.pending.SetMatchTimeDialog
import pl.krystiankaniowski.rank.feature.match.ui.RankMatchHeader
import pl.krystiankaniowski.rank.feature.match.ui.RankRecentMatchesCard
import pl.krystiankaniowski.rank.feature.match.ui.UiMatchSet
import pl.krystiankaniowski.rank.feature.player.summary.PlayersCompareCard

@Composable
fun MatchAddScreenContent(
    state: MatchAddViewModel.State.PendingMatch,
    onAction: (MatchAddViewModel.Action) -> Unit,
) {

    var openAddSetWinnerDialog by remember { mutableStateOf(false) }
    var openAddSetScoreDialog by remember { mutableStateOf(false) }
    var openSetDateDialog by remember { mutableStateOf(false) }
    var openSetTimeDialog by remember { mutableStateOf(false) }

    RankLayout.Page(
        topBar = { RankTopBar.Solid("Pending match") },
        supportPane = {
            RankLayout.SupportPane(
                modifier = Modifier.padding(vertical = 16.dp),
            ) {
                PlayersCompareCard(
                    player1 = state.player1,
                    player2 = state.player2,
                    player1stats = state.player1Stats,
                    player2stats = state.player2Stats,
                )
                RankRecentMatchesCard(
                    matches = state.recentMatches,
                )
            }
        },
    ) {
        RankLayout.ContentScrollable {
            RankLayout.ContentCardExposed(
                modifier = Modifier.padding(16.dp),
            ) {
                RankMatchHeader(
                    player1 = state.player1,
                    player2 = state.player2,
                    player1Score = state.player1wins,
                    player2Score = state.player2wins,
                )
            }
            RankLayout.ContentCard {
                RankListItems.Header("Sets")
                state.history.forEachIndexed { index, matchSet ->
                    RankDivider()
                    UiMatchSet(
                        setNumber = index,
                        player1 = state.player1,
                        player2 = state.player2,
                        player1Score = if (matchSet.winnerId == state.player1.id) matchSet.winnerScore else matchSet.loserScore,
                        player2Score = if (matchSet.winnerId == state.player1.id) matchSet.loserScore else matchSet.winnerScore,
                        setWinnerId = matchSet.winnerId,
                    )
                }
                RankDivider()
                Row(
                    modifier = Modifier.padding(16.dp),
                    horizontalArrangement = Arrangement.spacedBy(16.dp),
                ) {
                    RankButton.Primary(
                        text = "Add set (score)",
                        onClick = { openAddSetScoreDialog = true },
                    )
                    RankButton.Secondary(
                        text = "Add set (winner)",
                        onClick = { openAddSetWinnerDialog = true },
                    )
                    RankButton.Secondary(
                        enabled = state.isRemoveLastSetButtonEnabled,
                        text = "Remove last set",
                        onClick = { onAction(MatchAddViewModel.Action.RemoveLastSet) },
                    )
                }
            }
            RankLayout.ContentCard {
                Row {
                    Box(modifier = Modifier.weight(1f)) {
                        RankListItems.Item(
                            label = "Date",
                            value = state.date.toString(),
                            onClick = { openSetDateDialog = true },
                        )
                    }
                    Box(modifier = Modifier.weight(1f)) {
                        RankListItems.Item(
                            label = "Time",
                            value = "${state.time.hour}:${state.time.minute.toString().padStart(2, '0')}",
                            onClick = { openSetTimeDialog = true },
                        )
                    }
                }
            }
            RankLayout.ContentCard(modifier = Modifier.padding(16.dp)) {
                RankButton.Primary(
                    enabled = state.isFinishButtonEnabled,
                    text = "Finish match",
                    onClick = { onAction(MatchAddViewModel.Action.OnFinishClick) },
                )
            }
        }
    }

    if (openAddSetWinnerDialog) {
        MatchAddSetWinnerDialog(
            player1 = state.player1,
            player2 = state.player2,
            onResult = { winner ->
                onAction(
                    MatchAddViewModel.Action.AddSet(
                        winnerId = winner,
                        loserId = if (winner == state.player1.id) state.player2.id else state.player1.id,
                        winnerScore = null,
                        loserScore = null,
                    ),
                )
            },
            onDismiss = { openAddSetWinnerDialog = false },
        )
    }
    if (openAddSetScoreDialog) {
        MatchAddSetResultDialog(
            player1 = state.player1,
            player2 = state.player2,
            onResult = { player1Score, player2Score ->
                val winner = if (player1Score > player2Score) state.player1.id else state.player2.id
                onAction(
                    MatchAddViewModel.Action.AddSet(
                        winnerId = winner,
                        loserId = if (winner == state.player1.id) state.player2.id else state.player1.id,
                        winnerScore = if (winner == state.player1.id) player1Score.toLong() else player2Score.toLong(),
                        loserScore = if (winner == state.player1.id) player2Score.toLong() else player1Score.toLong(),
                    ),
                )
            },
            onDismiss = { openAddSetScoreDialog = false },
        )
    }
    if (openSetDateDialog) {
        MatchAddSetDateDialog(
            currentDate = state.date,
            onResult = {
                onAction(
                    MatchAddViewModel.Action.ChangeDate(
                        date = it,
                    ),
                )
            },
            onDismiss = { openSetDateDialog = false },
        )
    }
    if (openSetTimeDialog) {
        SetMatchTimeDialog(
            currentTime = state.time,
            onResult = {
                onAction(
                    MatchAddViewModel.Action.ChangeTime(
                        time = it,
                    ),
                )
            },
            onDismiss = { openSetTimeDialog = false },
        )
    }
}