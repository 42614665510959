package pl.krystiankaniowski.rank.core.networking

import io.ktor.client.*
import io.ktor.client.request.*
import io.ktor.http.*
import org.koin.core.annotation.Single
import pl.krystiankaniowski.rank.RankConfig
import pl.krystiankaniowski.rank.feature.setting.Settings
import pl.krystiankaniowski.rank.model.*

@Single
class RankApi(
    private val httpClient: HttpClient,
    private val settings: Settings,
) {

    private val endpoint = RankConfig.ENDPOINT

    suspend fun players(): Response<Players> = httpClient.safeRequest {
        method = HttpMethod.Get
        url("$endpoint/players")
    }

    suspend fun player(id: Long): Response<Player> = httpClient.safeRequest {
        method = HttpMethod.Get
        url("$endpoint/players/$id")
    }

    suspend fun addPlayer(newPlayer: NewPlayer): Response<Unit> = httpClient.safeRequest {
        method = HttpMethod.Post
        bearerAuth(settings.arbiterToken)
        contentType(ContentType.Application.Json)
        url("$endpoint/players")
        setBody(newPlayer)
    }

    suspend fun setPlayerActive(id: Long, isActive: Boolean): Response<Unit> = httpClient.safeRequest {
        method = HttpMethod.Put
        bearerAuth(settings.arbiterToken)
        contentType(ContentType.Application.Json)
        url("$endpoint/players/$id/active")
        setBody(isActive)
    }

    suspend fun matches(): Response<Matches> = httpClient.safeRequest {
        method = HttpMethod.Get
        url("$endpoint/matches")
    }

    suspend fun addMatch(newMatch: NewMatch): Response<Unit> = httpClient.safeRequest {
        method = HttpMethod.Post
        bearerAuth(settings.arbiterToken)
        contentType(ContentType.Application.Json)
        url("$endpoint/matches")
        setBody(newMatch)
    }

    suspend fun recalculateElo() = httpClient.request {
        method = HttpMethod.Post
        bearerAuth(settings.arbiterToken)
        contentType(ContentType.Application.Json)
        url("$endpoint/elo/calculate")
    }

    suspend fun devRemoveDuplicatedMatches() = httpClient.request {
        method = HttpMethod.Post
        bearerAuth(settings.arbiterToken)
        contentType(ContentType.Application.Json)
        url("$endpoint/dev/remove-duplicated-matches")
    }
}
