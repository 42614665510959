package pl.krystiankaniowski.rank.feature.player.compare

import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import kotlinx.coroutines.flow.*
import kotlinx.coroutines.launch
import kotlinx.datetime.LocalDate
import org.koin.core.annotation.Factory
import org.koin.core.annotation.InjectedParam
import pl.krystiankaniowski.rank.core.model.MatchWithPlayers
import pl.krystiankaniowski.rank.core.repository.MatchRepository
import pl.krystiankaniowski.rank.core.repository.PlayerRepository
import pl.krystiankaniowski.rank.model.Player

@Factory
class PlayerCompareViewModel(
    private val playerRepository: PlayerRepository,
    private val matchRepository: MatchRepository,
    @InjectedParam private val player1Id: Long,
    @InjectedParam private val player2Id: Long,
) : ViewModel() {

    sealed interface State {
        data object Loading : State
        data object Error : State
        data class Data(
            val player1: Player,
            val player2: Player,
            val player1stats: PlayerStats,
            val player2stats: PlayerStats,
            val matches: Map<LocalDate, List<MatchWithPlayers>>,
        ) : State {
            data class PlayerStats(
                val winsAgainst: Int,
            )
        }
    }

    sealed interface Action {
        data class OnMatchClick(val matchId: Long) : Action
    }

    sealed interface Event {
        data class OpenMatchScreen(val matchId: Long) : Event
    }

    private val _state: MutableStateFlow<State> = MutableStateFlow(State.Loading)
    val state: StateFlow<State> = _state

    private val _events: MutableSharedFlow<Event> = MutableSharedFlow()
    val events: SharedFlow<Event> = _events

    init {
        viewModelScope.launch {
            try {
                val player1 = playerRepository.getPlayer(player1Id).first() ?: error("Player not found")
                val player2 = playerRepository.getPlayer(player2Id).first() ?: error("Player not found")
                matchRepository.getMatchesGroupedByDate(
                    filter = { matchWithPlayers -> matchWithPlayers.hasPlayer(player1Id) && matchWithPlayers.hasPlayer(player2Id) },
                ).collect { matches ->
                    val player1Stats = State.Data.PlayerStats(
                        winsAgainst = matches.values.sumOf {
                            it.count { match -> match.winner.id == player1.id }
                        },
                    )
                    val player2Stats = State.Data.PlayerStats(
                        winsAgainst = matches.values.sumOf {
                            it.count { match -> match.winner.id == player2.id }
                        },
                    )
                    _state.value = State.Data(
                        player1 = player1,
                        player2 = player2,
                        player1stats = player1Stats,
                        player2stats = player2Stats,
                        matches = matches,
                    )
                }
            } catch (e: Exception) {
                _state.value = State.Error
            }
        }
    }

    fun onAction(action: Action) {
        when (action) {
            is Action.OnMatchClick -> onMatchClick(action)
        }
    }

    private fun onMatchClick(action: Action.OnMatchClick) {
        viewModelScope.launch {
            _events.emit(Event.OpenMatchScreen(action.matchId))
        }
    }
}