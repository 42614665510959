package pl.krystiankaniowski.rank.feature.match.add.pending

import androidx.compose.material3.*
import androidx.compose.runtime.Composable
import kotlinx.datetime.*
import pl.krystiankaniowski.rank.core.designsystem.RankButton

@OptIn(ExperimentalMaterial3Api::class)
@Composable
internal fun MatchAddSetDateDialog(
    currentDate: LocalDate,
    onResult: (LocalDate) -> Unit,
    onDismiss: () -> Unit,
) {

    val datePickerState = rememberDatePickerState(
        currentDate.atStartOfDayIn(TimeZone.currentSystemDefault()).toEpochMilliseconds(),
    )

    AlertDialog(
        onDismissRequest = onDismiss,
        text = {
            DatePicker(
                state = datePickerState,
                title = { Text("Select date") },
            )
        },
        confirmButton = {
            RankButton.TextButton(
                text = "Set",
                enabled = datePickerState.selectedDateMillis != null,
                onClick = {
                    onResult(Instant.fromEpochMilliseconds(datePickerState.selectedDateMillis!!).toLocalDateTime(TimeZone.currentSystemDefault()).date)
                    onDismiss()
                },
            )
        },
        dismissButton = {
            RankButton.TextButton(
                text = "Cancel",
                onClick = onDismiss,
            )
        },
    )
}
