package pl.krystiankaniowski.rank.core.viewmodel

import androidx.lifecycle.ViewModel
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.SharedFlow
import kotlinx.coroutines.flow.StateFlow

abstract class StateViewModel<State, Event, Action> : ViewModel() {

    protected val _state: MutableStateFlow<State> = MutableStateFlow(initState())
    val state: StateFlow<State> = _state

    protected val _events: MutableSharedFlow<Event> = MutableSharedFlow()
    val events: SharedFlow<Event> = _events

    abstract fun initState(): State

    abstract fun onAction(action: Action)
}