package pl.krystiankaniowski.rank.feature.player.match

import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.launch
import kotlinx.datetime.LocalDate
import org.koin.core.annotation.Factory
import org.koin.core.annotation.InjectedParam
import pl.krystiankaniowski.rank.core.UserError
import pl.krystiankaniowski.rank.core.UserException
import pl.krystiankaniowski.rank.core.model.MatchWithPlayers
import pl.krystiankaniowski.rank.core.repository.MatchRepository
import pl.krystiankaniowski.rank.core.repository.PlayerRepository

@Factory
class PlayerMatchListViewModel(
    @InjectedParam private val id: Long,
    private val playerRepository: PlayerRepository,
    private val matchRepository: MatchRepository,
) : ViewModel() {

    private val _state: MutableStateFlow<State> = MutableStateFlow(State.Loading)
    val state: StateFlow<State> = _state

    init {
        viewModelScope.launch {
            try {
                val player = playerRepository.getPlayer(id).first() ?: return@launch run {
                    _state.value = State.Error(UserError.OtherError)
                }

                matchRepository.getMatchesGroupedByDate(
                    filter = { matchWithPlayers -> matchWithPlayers.hasPlayer(player.id) },
                ).collect { matches ->
                    _state.value = State.Loaded(
                        id = player.id,
                        name = player.name,
                        matches = matches,
                    )
                }
            } catch (e: UserException) {
                _state.value = State.Error(e.userError)
            }
        }
    }

    sealed interface State {
        data object Loading : State
        data class Error(val userError: UserError) : State
        data class Loaded(
            val id: Long,
            val name: String,
            val matches: Map<LocalDate, List<MatchWithPlayers>>,
        ) : State
    }
}
