package pl.krystiankaniowski.rank.feature.setting

import com.russhwolf.settings.Settings
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import org.koin.core.annotation.Singleton

@Singleton
class Settings {

    private val properties = Settings()

    var arbiterMode: Boolean
        get() = properties.getBoolean(ARBITER_MODE, false)
        set(value) {
            properties.putBoolean(ARBITER_MODE, value)
            _arbiterModeFlow.value = value
        }

    private val _arbiterModeFlow: MutableStateFlow<Boolean> = MutableStateFlow(arbiterMode)
    val arbiterModeFlow: StateFlow<Boolean> = _arbiterModeFlow

    var arbiterToken: String
        get() = properties.getString(ARBITER_TOKEN, "")
        set(value) = properties.putString(ARBITER_TOKEN, value)

    private companion object {
        const val ARBITER_MODE = "arbiterMode"
        const val ARBITER_TOKEN = "arbiterToken"
    }
}