package pl.krystiankaniowski.rank.feature.stat.usecase

import kotlinx.coroutines.flow.first
import org.koin.core.annotation.Factory
import pl.krystiankaniowski.rank.core.repository.PlayerRepository

@Factory
class StatGetNumberOfPlayersUseCase(
    private val playerRepository: PlayerRepository,
) {

    suspend operator fun invoke(): Int = playerRepository.getPlayers().first().size
}