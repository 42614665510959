package pl.krystiankaniowski.rank.feature.player.add

import androidx.lifecycle.viewModelScope
import kotlinx.coroutines.launch
import org.koin.core.annotation.Factory
import pl.krystiankaniowski.rank.core.UserError
import pl.krystiankaniowski.rank.core.repository.PlayerRepository
import pl.krystiankaniowski.rank.core.transform
import pl.krystiankaniowski.rank.core.viewmodel.StateViewModel
import pl.krystiankaniowski.rank.model.NewPlayer

@Factory
class PlayerAddViewModel(
    private val playerRepository: PlayerRepository,
) : StateViewModel<PlayerAddViewModel.State, PlayerAddViewModel.Event, PlayerAddViewModel.Action>() {

    data class State(
        val name: String? = null,
    ) {
        val isAddButtonEnable = name != null
    }

    sealed interface Event {
        data class ErrorOccurred(val userError: UserError) : Event
        data object CloseView : Event
    }

    sealed interface Action {
        data class OnNameChange(val name: String) : Action
        data object OnAddButtonClick : Action
    }

    override fun initState() = State()

    override fun onAction(action: Action) {
        when (action) {
            Action.OnAddButtonClick -> onAddButtonClick()
            is Action.OnNameChange -> onNameChanged(action.name)
        }
    }

    private fun onNameChanged(name: String) {
        _state.transform { copy(name = name) }
    }

    private fun onAddButtonClick() {
        viewModelScope.launch {
            try {
                if (state.value.isAddButtonEnable) {
                    playerRepository.addPlayer(
                        NewPlayer(
                            name = state.value.name!!,
                        ),
                    )
                    _events.emit(Event.CloseView)
                }
            } catch (e: Exception) {
                _events.emit(Event.ErrorOccurred(UserError.from(e)))
            }
        }
    }
}