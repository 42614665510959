package pl.krystiankaniowski.rank.feature.match.add

import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import kotlinx.coroutines.flow.*
import kotlinx.coroutines.launch
import kotlinx.datetime.*
import org.koin.core.annotation.Factory
import org.koin.core.annotation.InjectedParam
import pl.krystiankaniowski.rank.core.UserError
import pl.krystiankaniowski.rank.core.model.MatchWithPlayers
import pl.krystiankaniowski.rank.core.repository.MatchRepository
import pl.krystiankaniowski.rank.core.repository.PlayerRepository
import pl.krystiankaniowski.rank.core.repository.RankRepository
import pl.krystiankaniowski.rank.core.runIf
import pl.krystiankaniowski.rank.core.transformIf
import pl.krystiankaniowski.rank.core.update
import pl.krystiankaniowski.rank.feature.player.summary.PlayerStat
import pl.krystiankaniowski.rank.model.NewMatch
import pl.krystiankaniowski.rank.model.NewMatchSet
import pl.krystiankaniowski.rank.model.Player
import kotlin.math.max

@Factory
class MatchAddViewModel(
    @InjectedParam private val playerIds: List<Long>,
    private val playerRepository: PlayerRepository,
    private val matchRepository: MatchRepository,
    private val rankRepository: RankRepository,
) : ViewModel() {

    private val _state: MutableStateFlow<State> = MutableStateFlow(State.Loading)
    val state: StateFlow<State> = _state

    private val _events: MutableSharedFlow<Event> = MutableSharedFlow()
    val events: SharedFlow<Event> = _events

    init {
        viewModelScope.launch {

            val player1 = playerRepository.getPlayer(playerIds[0]).firstOrNull()
            val player2 = playerRepository.getPlayer(playerIds[1]).firstOrNull()

            _state.update(
                State.PendingMatch(
                    datetime = Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault()),
                    player1 = player1!!,
                    player1Stats = rankRepository.getPlayerStats(player1.id),
                    player2 = player2!!,
                    player2Stats = rankRepository.getPlayerStats(player2.id),
                ),
            )

            val recentMatches = matchRepository.getMatchesBetween(player1.id, player2.id).take(5)
            _state.transformIf<State.PendingMatch> { copy(recentMatches = recentMatches) }
        }
    }

    fun onAction(action: Action) {
        when (action) {
            is Action.AddSet -> addSet(winnerId = action.winnerId, loserId = action.loserId, winnerScore = action.winnerScore, loserScore = action.loserScore)
            is Action.RemoveLastSet -> removeLastSet()
            is Action.ChangeDate -> changeDate(date = action.date)
            is Action.ChangeTime -> changeTime(time = action.time)
            Action.OnFinishClick -> onFinishClick()
            Action.OnMatchConfirmClick -> confirmMatch()
        }
    }

    private fun addSet(winnerId: Long, loserId: Long, winnerScore: Long?, loserScore: Long?) {
        _state.transformIf<State.PendingMatch> {
            val set = NewMatchSet(
                winnerId = winnerId,
                loserId = loserId,
                winnerScore = winnerScore,
                loserScore = loserScore,
            )
            copy(history = history + set)
        }
    }

    private fun removeLastSet() {
        _state.transformIf<State.PendingMatch> {
            copy(history = history.dropLast(1))
        }
    }

    private fun changeDate(date: LocalDate) {
        _state.transformIf<State.PendingMatch> {
            copy(datetime = date.atTime(time))
        }
    }

    private fun changeTime(time: LocalTime) {
        _state.transformIf<State.PendingMatch> {
            copy(datetime = date.atTime(time))
        }
    }

    private fun onFinishClick() {
        _state.runIf<State.PendingMatch>(viewModelScope) {
            if (!isFinishButtonEnabled) return@runIf
            _events.emit(
                Event.OpenConfirmDialog(
                    player1 = player1,
                    player2 = player2,
                    player1Score = player1wins,
                    player2Score = player2wins,
                ),
            )
        }
    }

    private fun confirmMatch() {
        _state.runIf<State.PendingMatch>(viewModelScope) {
            try {
                if (!isFinishButtonEnabled) return@runIf
                val result = listOf(player1 to player1wins, player2 to player2wins).sortedByDescending { it.second }
                matchRepository.addMatch(
                    NewMatch(
                        timestamp = datetime.toInstant(TimeZone.currentSystemDefault()),
                        winnerId = result[0].first.id,
                        loserId = result[1].first.id,
                        winnerScore = result[0].second.toLong(),
                        loserScore = result[1].second.toLong(),
                        history = history,
                    ),
                )
                _events.emit(Event.Close)
            } catch (e: Exception) {
                _events.emit(Event.ErrorOccurred(UserError.from(e)))
            }
        }
    }

    sealed interface State {
        data object Loading : State
        data class PendingMatch(
            val datetime: LocalDateTime,
            val player1: Player,
            val player1Stats: PlayerStat?,
            val player2: Player,
            val player2Stats: PlayerStat?,
            val history: List<NewMatchSet> = emptyList(),
            val recentMatches: List<MatchWithPlayers> = emptyList(),
        ) : State {
            val player1wins: Int = history.count { it.winnerId == player1.id }
            val player2wins: Int = history.count { it.winnerId == player2.id }
            val date: LocalDate = datetime.date
            val time: LocalTime = datetime.time
            val isRemoveLastSetButtonEnabled: Boolean = history.isNotEmpty()
            val isFinishButtonEnabled: Boolean = max(player1wins, player2wins) > 1
        }
    }

    sealed interface Event {
        data class OpenConfirmDialog(val player1: Player, val player2: Player, val player1Score: Int, val player2Score: Int) : Event
        data class ErrorOccurred(val error: UserError) : Event
        data object Close : Event
    }

    sealed interface Action {
        data class ChangeDate(val date: LocalDate) : Action
        data class ChangeTime(val time: LocalTime) : Action
        data class AddSet(val winnerId: Long, val loserId: Long, val winnerScore: Long?, val loserScore: Long?) : Action
        data object RemoveLastSet : Action
        data object OnFinishClick : Action
        data object OnMatchConfirmClick : Action
    }
}