package pl.krystiankaniowski.rank.feature.player.ui

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.CornerSize
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedCard
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.alpha
import androidx.compose.ui.draw.clip
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import pl.krystiankaniowski.rank.model.Player

@Composable
fun PlayerTile(
    modifier: Modifier = Modifier,
    player: Player,
    onClick: (() -> Unit)? = null,
) {
    OutlinedCard(
        modifier = modifier,
        shape = MaterialTheme.shapes.small,
    ) {
        Box(
            modifier = Modifier
                .clickable(enabled = onClick != null) { onClick!!() }
                .fillMaxWidth(),
        ) {
            PlayerAvatarSquare(
                modifier = Modifier
                    .fillMaxWidth()
                    .aspectRatio(1f),
                player = player,
            )
            Text(
                modifier = Modifier
                    .align(Alignment.BottomEnd)
                    .clip(
                        RoundedCornerShape(CornerSize(0.dp)).copy(topStart = MaterialTheme.shapes.small.topStart),
                    )
                    .border(
                        width = 1.dp,
                        color = if (player.isActive) MaterialTheme.colorScheme.primary else MaterialTheme.colorScheme.surface,
                        shape = RoundedCornerShape(CornerSize(0.dp)).copy(topStart = MaterialTheme.shapes.small.topStart),
                    )
                    .background(MaterialTheme.colorScheme.surface)
                    .padding(vertical = 4.dp, horizontal = 8.dp),
                text = player.name,
                style = MaterialTheme.typography.bodySmall,
                textAlign = TextAlign.Center,
                maxLines = 1,
            )
        }
    }
}

@Composable
fun PlayerTileSelectable(
    modifier: Modifier = Modifier,
    player: Player,
    isSelected: Boolean = false,
    onClick: () -> Unit,
) {
    OutlinedCard(
        modifier = modifier.alpha(if (isSelected) 1f else 0.25f),
        shape = MaterialTheme.shapes.small,
        colors = CardDefaults.outlinedCardColors(
            containerColor = when (isSelected) {
                true -> MaterialTheme.colorScheme.primaryContainer
                false -> MaterialTheme.colorScheme.surfaceContainer
            },
        ),
    ) {
        Box(
            modifier = Modifier
                .clickable { onClick() }
                .fillMaxWidth(),
        ) {
            PlayerAvatarSquare(
                modifier = Modifier
                    .fillMaxWidth()
                    .aspectRatio(1f),
                player = player,
            )
            Text(
                modifier = Modifier
                    .align(Alignment.BottomEnd)
                    .clip(
                        RoundedCornerShape(CornerSize(0.dp)).copy(
                            topStart = MaterialTheme.shapes.small.topStart,
                        ),
                    )
                    .border(
                        width = 1.dp,
                        color = if (player.isActive) MaterialTheme.colorScheme.primary else MaterialTheme.colorScheme.surface,
                        shape = RoundedCornerShape(CornerSize(0.dp)).copy(topStart = MaterialTheme.shapes.small.topStart),
                    )
                    .background(MaterialTheme.colorScheme.surface)
                    .padding(vertical = 4.dp, horizontal = 8.dp),
                text = player.name,
                color = MaterialTheme.colorScheme.primary,
                style = MaterialTheme.typography.bodySmall,
                textAlign = TextAlign.Center,
                maxLines = 1,
            )
        }
    }
}