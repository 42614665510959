package pl.krystiankaniowski.rank.feature.match.ui

import androidx.compose.foundation.layout.*
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import pl.krystiankaniowski.rank.feature.player.ui.PlayerAvatar
import pl.krystiankaniowski.rank.model.Player

@Composable
fun RankMatchHeader(
    player1: Player,
    player2: Player,
    player1Score: Int,
    player2Score: Int,
) {
    BoxWithConstraints {
        when {
            maxWidth < 540.dp -> RankMatchHeaderSmall(player1, player2, player1Score, player2Score)
            else -> RankMatchHeaderBig(player1, player2, player1Score, player2Score)
        }
    }
}

@Composable
private fun RankMatchHeaderSmall(
    player1: Player,
    player2: Player,
    player1Score: Int,
    player2Score: Int,
) {
    Row(
        modifier = Modifier.fillMaxWidth(),
        horizontalArrangement = Arrangement.SpaceAround,
        verticalAlignment = Alignment.CenterVertically,
    ) {
        Column(
            modifier = Modifier.weight(1f),
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            PlayerAvatar(modifier = Modifier.size(64.dp), player = player1)
            Spacer(modifier = Modifier.height(8.dp))
            Text(
                modifier = Modifier.fillMaxWidth(),
                textAlign = TextAlign.Center,
                maxLines = 1,
                overflow = TextOverflow.Ellipsis,
                text = player1.name,
                style = MaterialTheme.typography.titleMedium,
            )
        }
        Text(
            modifier = Modifier.padding(24.dp),
            text = "$player1Score - $player2Score",
            style = MaterialTheme.typography.headlineMedium,
        )
        Column(
            modifier = Modifier.weight(1f),
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            PlayerAvatar(modifier = Modifier.size(64.dp), player = player2)
            Spacer(modifier = Modifier.height(8.dp))
            Text(
                modifier = Modifier.fillMaxWidth(),
                textAlign = TextAlign.Center,
                overflow = TextOverflow.Ellipsis,
                text = player2.name,
                maxLines = 1,
                style = MaterialTheme.typography.titleMedium,
            )
        }
    }
}

@Composable
private fun RankMatchHeaderBig(
    player1: Player,
    player2: Player,
    player1Score: Int,
    player2Score: Int,
) {
    Row(
        modifier = Modifier.fillMaxWidth(),
        horizontalArrangement = Arrangement.SpaceAround,
        verticalAlignment = Alignment.CenterVertically,
    ) {
        Text(
            modifier = Modifier.padding(16.dp).weight(1f),
            textAlign = TextAlign.End,
            maxLines = 2,
            overflow = TextOverflow.Ellipsis,
            text = player1.name,
            style = MaterialTheme.typography.titleMedium,
        )
        PlayerAvatar(modifier = Modifier.size(64.dp), player = player1)
        Text(
            modifier = Modifier.padding(24.dp),
            text = "$player1Score - $player2Score",
            style = MaterialTheme.typography.headlineMedium,
        )
        PlayerAvatar(modifier = Modifier.size(64.dp), player = player2)
        Text(
            modifier = Modifier.padding(16.dp).weight(1f),
            textAlign = TextAlign.Start,
            overflow = TextOverflow.Ellipsis,
            text = player2.name,
            maxLines = 2,
            style = MaterialTheme.typography.titleMedium,
        )
    }
}
