package pl.krystiankaniowski.rank.feature.issue

import io.ktor.client.*
import io.ktor.client.request.*
import io.ktor.http.*
import org.koin.core.annotation.Single
import pl.krystiankaniowski.rank.RankConfig
import pl.krystiankaniowski.rank.core.networking.Response
import pl.krystiankaniowski.rank.core.networking.safeRequest
import pl.krystiankaniowski.rank.model.Issues

@Single
class RankIssueApi(
    private val httpClient: HttpClient,
) {

    private val endpoint = RankConfig.ENDPOINT

    suspend fun issues(): Response<Issues> = httpClient.safeRequest {
        method = HttpMethod.Get
        url("$endpoint/issues")
    }
}