package pl.krystiankaniowski.rank.model

import kotlinx.datetime.Instant
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class NewMatch(
    @SerialName("timestamp") val timestamp: Instant,
    @SerialName("winnerId") val winnerId: Long,
    @SerialName("loserId") val loserId: Long,
    @SerialName("winnerScore") val winnerScore: Long,
    @SerialName("loserScore") val loserScore: Long,
    @SerialName("history") val history: List<NewMatchSet>?,
)

@Serializable
data class NewMatchSet(
    @SerialName("winnerId") val winnerId: Long,
    @SerialName("loserId") val loserId: Long,
    @SerialName("winnerScore") val winnerScore: Long?,
    @SerialName("loserScore") val loserScore: Long?,
)
