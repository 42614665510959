package pl.krystiankaniowski.rank.core.designsystem.listener

import kotlinx.datetime.Clock

class ThrottledListener(private val throttleMillis: Long = DEFAULT_THROTTLE_MILLIS) {
    private var previousActionMillis: Long = 0

    fun trigger(action: () -> Unit) {
        val currentTimeMillis = Clock.System.now().toEpochMilliseconds()
        if (currentTimeMillis - previousActionMillis > throttleMillis) {
            previousActionMillis = currentTimeMillis
            action()
        }
    }

    companion object {
        const val DEFAULT_THROTTLE_MILLIS = 500L
    }
}
