package pl.krystiankaniowski.rank.feature.player.rival

import androidx.lifecycle.viewModelScope
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import kotlinx.datetime.LocalDateTime
import org.koin.core.annotation.Factory
import org.koin.core.annotation.InjectedParam
import pl.krystiankaniowski.rank.core.repository.MatchRepository
import pl.krystiankaniowski.rank.core.repository.PlayerRepository
import pl.krystiankaniowski.rank.core.transformIf
import pl.krystiankaniowski.rank.core.viewmodel.StateViewModel
import pl.krystiankaniowski.rank.model.Player

@Factory
class PlayerRivalsViewModel(
    @InjectedParam private val playerId: Long,
    private val playerRepository: PlayerRepository,
    private val matchRepository: MatchRepository,
) : StateViewModel<PlayerRivalsViewModel.State, PlayerRivalsViewModel.Event, PlayerRivalsViewModel.Action>() {

    sealed interface State {
        data object Loading : State
        data class Data(
            val player: Player,
            val comparisons: List<ComparisonEntry>,
            val sortBy: SortBy = SortBy.MatchCount,
        ) : State {
            data class ComparisonEntry(
                val rival: Player,
                val winCount: Int,
                val loseCount: Int,
                val winRatio: Float,
                val eloBalance: Double,
                val lastMatchDate: LocalDateTime,
            )
        }
    }

    sealed interface Event {
        data class OpenComparePlayersScreen(val playerId1: Long, val playerId2: Long) : Event
    }

    sealed interface Action {
        data class OnPlayerClick(val playerId: Long) : Action
        data class OnSortByChange(val sortBy: SortBy) : Action
    }

    enum class SortBy {
        MatchCount,
        WinRatio,
        EloBalance,
        LastMatchDate,
    }

    override fun initState(): State = State.Loading

    init {
        viewModelScope.launch {
            val player = playerRepository.getPlayer(playerId).first() ?: error("Not found")
            val matches = matchRepository.getPlayerMatches(playerId)
            val groupedMatches = matches.groupBy {
                when (playerId) {
                    it.winner.id -> it.loser
                    it.loser.id -> it.winner
                    else -> error("Player is not in match")
                }
            }
            _state.update {
                State.Data(
                    player = player,
                    comparisons = groupedMatches.map { (rival, matches) ->
                        State.Data.ComparisonEntry(
                            rival = rival,
                            winCount = matches.count { it.winner.id == playerId },
                            loseCount = matches.count { it.loser.id == playerId },
                            winRatio = matches.count { it.winner.id == playerId } / matches.size.toFloat(),
                            eloBalance = matches.sumOf { if (player.id == it.winner.id) it.winnerEloChange else it.loserEloChange },
                            lastMatchDate = matches.maxOf { it.datetime },
                        )
                    }.sortedByDescending { it.winCount + it.loseCount },
                )
            }
        }
    }

    override fun onAction(action: Action) {
        when (action) {
            is Action.OnPlayerClick -> openComparePlayersScreen(action.playerId)
            is Action.OnSortByChange -> sortBy(action.sortBy)
        }
    }

    private fun openComparePlayersScreen(playerId: Long) {
        viewModelScope.launch {
            _events.emit(
                Event.OpenComparePlayersScreen(
                    playerId1 = this@PlayerRivalsViewModel.playerId,
                    playerId2 = playerId,
                ),
            )
        }
    }

    private fun sortBy(sortBy: SortBy) {
        _state.transformIf<State.Data>(viewModelScope) {
            this.copy(
                comparisons = when (sortBy) {
                    SortBy.MatchCount -> this.comparisons.sortedByDescending { it.winCount + it.loseCount }
                    SortBy.WinRatio -> this.comparisons.sortedByDescending { it.winRatio }
                    SortBy.EloBalance -> this.comparisons.sortedByDescending { it.eloBalance }
                    SortBy.LastMatchDate -> this.comparisons.sortedByDescending { it.lastMatchDate }
                },
                sortBy = sortBy,
            )
        }
    }
}