package pl.krystiankaniowski.rank.core.model

import kotlinx.datetime.LocalDateTime
import pl.krystiankaniowski.rank.model.MatchSet
import pl.krystiankaniowski.rank.model.Player

data class MatchWithPlayers(
    val id: Long,
    val datetime: LocalDateTime,
    val winner: Player,
    val loser: Player,
    val winnerScore: Long,
    val loserScore: Long,
    val winnerEloChange: Double,
    val loserEloChange: Double,
    val history: List<MatchSet>?,
) {
    fun hasPlayer(playerId: Long): Boolean = playerId == winner.id || playerId == loser.id
}