package pl.krystiankaniowski.rank.feature.player.detail

import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.SharedFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import kotlinx.datetime.LocalDateTime
import org.koin.core.annotation.Factory
import org.koin.core.annotation.InjectedParam
import pl.krystiankaniowski.rank.core.UserError
import pl.krystiankaniowski.rank.core.UserException
import pl.krystiankaniowski.rank.core.model.MatchWithPlayers
import pl.krystiankaniowski.rank.core.repository.EloRepository
import pl.krystiankaniowski.rank.core.repository.MatchRepository
import pl.krystiankaniowski.rank.core.repository.PlayerRepository
import pl.krystiankaniowski.rank.model.Player

@Factory
class PlayerDetailViewModel(
    @InjectedParam private val id: Long,
    private val playerRepository: PlayerRepository,
    private val matchRepository: MatchRepository,
    private val eloRepository: EloRepository,
) : ViewModel() {

    private val _state: MutableStateFlow<State> = MutableStateFlow(State.Loading)
    val state: StateFlow<State> = _state

    private val _events: MutableSharedFlow<Event> = MutableSharedFlow()
    val events: SharedFlow<Event> = _events

    init {
        viewModelScope.launch {
            try {
                playerRepository.getPlayer(id).collect { player ->

                    player ?: return@collect run { _state.value = State.Error(UserError.OtherError) }

                    val matches = matchRepository.getPlayerMatches(id)

                    val winMatches = matches.count { it.winner.id == player.id }
                    val loseMatches = matches.count { it.loser.id == player.id }
                    val rank = winMatches / (winMatches + loseMatches).toFloat()

                    val favouritePlayers = matches
                        .map { if (it.winner.id == player.id) it.loser else it.winner }
                        .groupBy { it }
                        .map { (player, occurrences) -> player to occurrences.size }
                        .sortedByDescending { it.second }
                        .map { it.first }

                    _state.value = State.Loaded(
                        player = player,
                        matchesCount = winMatches + loseMatches,
                        winMatches = winMatches,
                        loseMatches = loseMatches,
                        rank = rank,
                        favouritePlayers = State.Loaded.FavouritePlayers(favouritePlayers),
                        matches = matches,
                        eloHistory = eloRepository.getEloHistory(player.id),
                    )
                }
            } catch (e: UserException) {
                _state.value = State.Error(e.userError)
            }
        }
    }

    fun onAction(action: Action) {
        when (action) {
            is Action.OnMatchClick -> onMatchClick(action.matchId)
            is Action.OnRivalClick -> onRivalClick(action.playerId)
            Action.OnShowMoreMatchesClick -> onShowMoreMatchesClick()
            Action.OnRivalsClick -> onShowMoreRivalsClick()
            Action.OnArchiveButtonClicked -> onArchiveButtonClicked()
        }
    }

    private fun onMatchClick(matchId: Long) {
        viewModelScope.launch {
            _events.emit(Event.OpenMatchScreen(matchId))
        }
    }

    private fun onRivalClick(playerId: Long) {
        viewModelScope.launch {
            _events.emit(Event.OpenCompareScreen(id, playerId))
        }
    }

    private fun onShowMoreMatchesClick() {
        viewModelScope.launch {
            _events.emit(Event.OpenPlayerMatchesScreen(id))
        }
    }

    private fun onShowMoreRivalsClick() {
        viewModelScope.launch {
            _events.emit(Event.OpenPlayerOpponentsScreen(id))
        }
    }

    private fun onArchiveButtonClicked() {
        viewModelScope.launch {
            val player = (state.value as? State.Loaded)?.player ?: return@launch
            playerRepository.setActive(player.id, !player.isActive)
        }
    }

    sealed interface State {
        data object Loading : State
        data class Error(val userError: UserError) : State
        data class Loaded(
            val player: Player,
            val matchesCount: Int,
            val winMatches: Int,
            val loseMatches: Int,
            val rank: Float,
            val favouritePlayers: FavouritePlayers,
            val matches: List<MatchWithPlayers>,
            val eloHistory: Map<LocalDateTime, Double>,
        ) : State {

            val winRatio: String
                get() = "${(winMatches.toDouble() / (winMatches + loseMatches) * 100).toInt()}%"

            val bestStreak: Int
                get() = matches.findLongestStreak(player.id)

            val lastMatches = matches.take(5)
            val isMoreMatchesButtonVisible = matches.size > 5

            val opponentCount: Int
                get() = favouritePlayers.players.size

            data class FavouritePlayers(
                val players: List<Player>,
            )
        }
    }

    sealed interface Action {
        data object OnShowMoreMatchesClick : Action
        data object OnArchiveButtonClicked : Action
        data class OnMatchClick(val matchId: Long) : Action
        data class OnRivalClick(val playerId: Long) : Action
        data object OnRivalsClick : Action
    }

    sealed interface Event {
        data class OpenCompareScreen(val player1Id: Long, val player2Id: Long) : Event
        data class OpenMatchScreen(val matchId: Long) : Event
        data class OpenPlayerMatchesScreen(val playerId: Long) : Event
        data class OpenPlayerOpponentsScreen(val playerId: Long) : Event
    }
}

fun List<MatchWithPlayers>.findLongestStreak(playerId: Long): Int {
    var currentStreak = 0
    var longestStreak = 0

    for (element in this) {
        if (element.winner.id == playerId) {
            currentStreak++
        } else {
            currentStreak = 0
        }
        if (currentStreak > longestStreak) {
            longestStreak = currentStreak
        }
    }

    return longestStreak
}