package pl.krystiankaniowski.rank.core.formatter

import kotlinx.datetime.*
import org.koin.core.annotation.Single
import pl.krystiankaniowski.rank.core.formatter.DateTimeFormatter.Mode.*

@Single
class DateTimeFormatter {

    fun format(date: LocalDateTime, mode: Mode): String = when (mode) {
        DATETIME -> "${date.date.formatDate()} ${date.time.formatTime()}"
        DATE -> date.date.formatDate()
        TIME -> date.time.formatTime()
    }

    fun format(date: LocalDate): String = date.formatDate()
    fun format(time: LocalTime): String = time.formatTime()

    enum class Mode {
        DATETIME,
        DATE,
        TIME,
    }

    private fun LocalDate.formatDate() = "$year-${monthNumber.pad()}-${dayOfMonth.pad()}"

    private fun LocalTime.formatTime() = "${hour.pad()}:${minute.pad()}:${second.pad()}"

    private fun Int.pad() = toString().padStart(2, '0')
}

fun LocalDateTime.formatRelative(): String {
    val duration = Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault()).date.minus(this.date)
    return when {
        duration.years > 1 -> "${duration.years} years ago"
        duration.years > 0 -> "${duration.years} year ago"
        duration.months > 1 -> "${duration.months} months ago"
        duration.months > 0 -> "${duration.months} month ago"
        duration.days > 1 -> "${duration.days} days ago"
        duration.days > 0 -> "yesterday"
        duration.days == 0 -> "today"
        else -> "..."
    }
}