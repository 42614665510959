@file:Suppress("MatchingDeclarationName")

package pl.krystiankaniowski.rank

import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.ui.Modifier
import androidx.navigation.NavHostController
import androidx.navigation.compose.rememberNavController
import org.koin.compose.koinInject
import org.koin.core.annotation.ComponentScan
import org.koin.core.annotation.Module
import org.koin.core.context.startKoin
import org.koin.ksp.generated.module
import pl.krystiankaniowski.rank.core.designsystem.LocalScreenSize
import pl.krystiankaniowski.rank.core.designsystem.RankScreenSize
import pl.krystiankaniowski.rank.core.designsystem.RankTheme
import pl.krystiankaniowski.rank.core.networking.NetworkingModule
import pl.krystiankaniowski.rank.feature.main.MainScreen

@Module(includes = [NetworkingModule::class])
@ComponentScan
class RankKoinModule

fun setupApp() {
    startKoin {
        modules(RankKoinModule().module)
    }
}

@Composable
fun App() {

    val navController: NavHostController = rememberNavController()

    RankTheme {
        BoxWithConstraints(modifier = Modifier.fillMaxSize()) {
            val mode = RankScreenSize.resolveScreenSize(maxWidth)
            CompositionLocalProvider(LocalScreenSize provides mode) {
                MainScreen(koinInject(), navController)
            }
        }
    }
}
