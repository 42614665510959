package pl.krystiankaniowski.rank.feature.dev.usecase

import kotlinx.coroutines.flow.first
import org.koin.core.annotation.Factory
import pl.krystiankaniowski.rank.core.repository.MatchRepository
import pl.krystiankaniowski.rank.core.repository.PlayerRepository

@Factory
class ForceReloadAppDataUseCase(
    private val playerRepository: PlayerRepository,
    private val matchRepository: MatchRepository,
) {

    suspend operator fun invoke() {
        playerRepository.getPlayers(forceSync = true).first()
        matchRepository.getMatches(forceSync = true).first()
    }
}