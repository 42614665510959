package pl.krystiankaniowski.rank.core.repository

import kotlinx.coroutines.flow.first
import kotlinx.datetime.Clock
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import org.koin.core.annotation.Single

@Single
class EloRepository(
    private val matchRepository: MatchRepository,
    private val playerRepository: PlayerRepository,
) {

    suspend fun getEloHistory(playerId: Long): Map<LocalDateTime, Double> {

        val player = playerRepository.getPlayer(playerId).first() ?: return emptyMap()
        val playerMatches = matchRepository.getPlayerMatches(playerId)

        val eloChanges = playerMatches.associate { match -> match.datetime to if (match.winner.id == playerId) match.winnerEloChange else match.loserEloChange }

        val eloHistory = buildMap {
            put(Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault()), player.elo)
            var current = player.elo
            eloChanges
                .toList()
                .sortedByDescending { it.first }
                .forEachIndexed { index, (date, elo) ->
                    if (index > 0) current -= elo
                    put(date, current)
                }
            put(LocalDateTime(2000, 1, 1, 0, 0), 1500.0)
        }.toList()
            .reversed()
            .toMap()

        return eloHistory
    }
}