package pl.krystiankaniowski.rank.feature.match.add

import androidx.compose.runtime.*
import pl.krystiankaniowski.rank.core.UserError
import pl.krystiankaniowski.rank.core.designsystem.RankScreenState
import pl.krystiankaniowski.rank.feature.error.UserErrorDialog
import pl.krystiankaniowski.rank.feature.match.add.confirm.MatchConfirmDialog
import pl.krystiankaniowski.rank.feature.match.add.confirm.MatchConfirmDialogData

@Composable
fun MatchAddScreen(
    viewModel: MatchAddViewModel,
    onBackPressed: () -> Unit,
) {

    var userError: UserError? by remember { mutableStateOf(null) }
    var confirmDialogData: MatchConfirmDialogData? by remember { mutableStateOf(null) }

    LaunchedEffect(Unit) {
        viewModel.events.collect { event ->
            when (event) {
                MatchAddViewModel.Event.Close -> onBackPressed()
                is MatchAddViewModel.Event.ErrorOccurred -> userError = event.error
                is MatchAddViewModel.Event.OpenConfirmDialog -> confirmDialogData = MatchConfirmDialogData(
                    player1 = event.player1,
                    player2 = event.player2,
                    player1Score = event.player1Score,
                    player2Score = event.player2Score,
                )
            }
        }
    }

    if (userError != null) {
        UserErrorDialog(
            error = userError!!,
            onDismiss = { userError = null },
        )
    }

    if (confirmDialogData != null) {
        MatchConfirmDialog(
            data = confirmDialogData!!,
            onConfirm = { viewModel.onAction(MatchAddViewModel.Action.OnMatchConfirmClick) },
            onDismiss = { confirmDialogData = null },
        )
    }

    when (val state = viewModel.state.collectAsState().value) {
        MatchAddViewModel.State.Loading -> RankScreenState.Loading()
        is MatchAddViewModel.State.PendingMatch -> MatchAddScreenContent(state = state, onAction = viewModel::onAction)
    }
}
