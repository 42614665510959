package pl.krystiankaniowski.rank.core.designsystem

import androidx.compose.foundation.layout.*
import androidx.compose.material3.*
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.resources.painterResource
import pl.krystiankaniowski.rank.core.UserError
import rank.client.shared.generated.resources.Res
import rank.client.shared.generated.resources.icon_empty
import rank.client.shared.generated.resources.icon_info
import rank.client.shared.generated.resources.icon_warning

object RankScreenState {

    @Composable
    fun Loading(message: String? = null) {
        CommonContent(
            header = { CircularProgressIndicator(modifier = Modifier.size(64.dp)) },
            message = message?.let { { Text(message) } },
        )
    }

    @Composable
    fun Message(message: String, subMessage: String? = null) {
        CommonContent(
            header = {
                Icon(
                    modifier = Modifier.size(64.dp),
                    painter = painterResource(Res.drawable.icon_info),
                    contentDescription = null,
                )
            },
            message = { Text(message) },
            subMessage = subMessage?.let { { Text(subMessage) } },
        )
    }

    @Composable
    fun Empty(message: String, subMessage: String? = null) {
        CommonContent(
            header = {
                Icon(
                    modifier = Modifier.size(64.dp),
                    painter = painterResource(Res.drawable.icon_empty),
                    contentDescription = null,
                )
            },
            message = { Text(message) },
            subMessage = subMessage?.let { { Text(subMessage) } },
        )
    }

    @Composable
    fun Error(userError: UserError) {
        when (userError) {
            is UserError.HttpError -> Error(message = "Error: ${userError.code}", subMessage = userError.error?.message)
            UserError.NetworkError -> Error(message = "Network error")
            UserError.OtherError -> Error(message = "Error")
            UserError.SerializationError -> Error(message = "Error", subMessage = "Serialization error")
        }
    }

    @Composable
    fun Error(message: String, subMessage: String? = null) {
        CommonContent(
            header = {
                Icon(
                    modifier = Modifier.size(64.dp),
                    painter = painterResource(Res.drawable.icon_warning),
                    contentDescription = null,
                )
            },
            message = { Text(message) },
            subMessage = subMessage?.let { { Text(subMessage) } },
        )
    }

    @Composable
    private fun CommonContent(
        header: @Composable (() -> Unit)? = null,
        message: @Composable (() -> Unit)? = null,
        subMessage: @Composable (() -> Unit)? = null,
    ) {
        Box(
            modifier = Modifier.fillMaxSize(),
            contentAlignment = Alignment.Center,
        ) {
            Column(
                verticalArrangement = Arrangement.spacedBy(16.dp),
                horizontalAlignment = Alignment.CenterHorizontally,
            ) {
                header?.invoke()
                ProvideTextStyle(MaterialTheme.typography.bodyMedium) {
                    message?.invoke()
                }
                ProvideTextStyle(MaterialTheme.typography.bodySmall) {
                    subMessage?.invoke()
                }
            }
        }
    }
}