package pl.krystiankaniowski.rank

import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.window.CanvasBasedWindow
import org.jetbrains.skiko.wasm.onWasmReady

fun main() {

    setupApp()

    onWasmReady {
        @OptIn(ExperimentalComposeUiApi::class)
        CanvasBasedWindow(
            title = "Rank",
            canvasElementId = "ComposeTarget",
            content = { App() },
        )
    }
}
