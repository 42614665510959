package pl.krystiankaniowski.rank

import androidx.compose.animation.EnterTransition
import androidx.compose.animation.ExitTransition
import androidx.compose.runtime.Composable
import androidx.navigation.NavHostController
import androidx.navigation.compose.NavHost
import androidx.navigation.compose.composable
import org.koin.compose.koinInject
import org.koin.core.parameter.parametersOf
import pl.krystiankaniowski.rank.feature.arbiter.ArbiterScreen
import pl.krystiankaniowski.rank.feature.dev.DevScreen
import pl.krystiankaniowski.rank.feature.issue.IssuesScreen
import pl.krystiankaniowski.rank.feature.main.MainDestination
import pl.krystiankaniowski.rank.feature.main.MainViewModel
import pl.krystiankaniowski.rank.feature.match.add.MatchAddScreen
import pl.krystiankaniowski.rank.feature.match.detail.MatchScreen
import pl.krystiankaniowski.rank.feature.match.list.MatchesScreen
import pl.krystiankaniowski.rank.feature.player.add.PlayerAddScreen
import pl.krystiankaniowski.rank.feature.player.compare.PlayerCompareScreen
import pl.krystiankaniowski.rank.feature.player.detail.PlayerDetailScreen
import pl.krystiankaniowski.rank.feature.player.list.PlayerListScreen
import pl.krystiankaniowski.rank.feature.player.match.PlayerMatchListScreen
import pl.krystiankaniowski.rank.feature.player.rival.PlayerRivalsScreen
import pl.krystiankaniowski.rank.feature.player.select.PlayersSelectScreen
import pl.krystiankaniowski.rank.feature.rank.RankScreen
import pl.krystiankaniowski.rank.feature.setting.SettingScreen
import pl.krystiankaniowski.rank.feature.stat.StatScreen

@Composable
fun AppGraph(
    navHostController: NavHostController,
    state: MainViewModel.State,
) {
    NavHost(
        navController = navHostController,
        startDestination = state.current.id,
        enterTransition = { EnterTransition.None },
        exitTransition = { ExitTransition.None },
    ) {
        composable(route = MainDestination.Rank.destination.route) {
            RankScreen(
                viewModel = koinInject(),
                openPlayerDetailsScreen = { id -> navHostController.navigate("player/$id") },
            )
        }
        composable(route = MainDestination.Matches.destination.route) {
            MatchesScreen(
                viewModel = koinInject(),
                openMatchDetails = { id -> navHostController.navigate("match/$id") },
            )
        }
        composable(route = "match-add/{id1}/{id2}") {
            val id1 = it.arguments?.getString("id1")?.toLongOrNull() ?: return@composable
            val id2 = it.arguments?.getString("id2")?.toLongOrNull() ?: return@composable
            MatchAddScreen(
                viewModel = koinInject(parameters = { parametersOf(listOf(id1, id2)) }),
                onBackPressed = { navHostController.popBackStack() },
            )
        }
        composable(route = "match/{id}") {
            val id = it.arguments?.getString("id")?.toLongOrNull() ?: return@composable
            MatchScreen(
                viewModel = koinInject(parameters = { parametersOf(id) }),
                onBackPressed = { navHostController.popBackStack() },
            )
        }
        composable(route = MainDestination.Players.destination.route) {
            PlayerListScreen(
                viewModel = koinInject(),
                openPlayerDetails = { id -> navHostController.navigate("player/$id") },
            )
        }
        composable(route = "player-select") {
            PlayersSelectScreen(
                viewModel = koinInject(),
                openAddMatchScreen = { players -> navHostController.navigate("match-add/${players[0]}/${players[1]}") },
                onBackPressed = { navHostController.popBackStack() },
            )
        }
        composable(route = "player/{id}") {
            val id = it.arguments?.getString("id")?.toLongOrNull() ?: return@composable
            PlayerDetailScreen(
                viewModel = koinInject(parameters = { parametersOf(id) }),
                openPlayerCompareScreen = { id1, id2 -> navHostController.navigate("player-compare/$id1/$id2") },
                openPlayerMatchesScreen = { id -> navHostController.navigate("player/$id/match") },
                openMatchScreen = { id -> navHostController.navigate("match/$id") },
                openPlayerOpponentsScreen = { id -> navHostController.navigate("player/$id/opponent") },
                onBackPressed = { navHostController.popBackStack() },
            )
        }
        composable(route = "player-compare/{id1}/{id2}") {
            val id1 = it.arguments?.getString("id1")?.toLongOrNull() ?: return@composable
            val id2 = it.arguments?.getString("id2")?.toLongOrNull() ?: return@composable
            PlayerCompareScreen(
                viewModel = koinInject(parameters = { parametersOf(id1, id2) }),
                openMatchScreen = { id -> navHostController.navigate("match/$id") },
                onBackPressed = { navHostController.popBackStack() },
            )
        }
        composable(route = "player/{id}/match") {
            val id = it.arguments?.getString("id")?.toLongOrNull() ?: return@composable
            PlayerMatchListScreen(
                viewModel = koinInject(parameters = { parametersOf(id) }),
                openMatchScreen = { id -> navHostController.navigate("match/$id") },
                onBackPressed = { navHostController.popBackStack() },
            )
        }
        composable(route = "player/{id}/opponent") {
            val id = it.arguments?.getString("id")?.toLongOrNull() ?: return@composable
            PlayerRivalsScreen(
                viewModel = koinInject(parameters = { parametersOf(id) }),
                openPlayersCompareScreen = { id1, id2 -> navHostController.navigate("player-compare/$id1/$id2") },
                onBackPressed = { navHostController.popBackStack() },
            )
        }
        composable(route = "PlayerAdd") {
            PlayerAddScreen(
                viewModel = koinInject(),
                onBackPressed = { navHostController.popBackStack() },
            )
        }
        composable(route = MainDestination.Settings.destination.route) {
            SettingScreen(
                viewModel = koinInject(),
                openArbiterScreen = { navHostController.navigate("arbiter") },
                openDevScreen = { navHostController.navigate("dev") },
                openIssuesScreen = { navHostController.navigate("issues") },
                openStatScreen = { navHostController.navigate("stat") },
            )
        }
        composable(route = "arbiter") {
            ArbiterScreen(
                viewModel = koinInject(),
                onBackPressed = { navHostController.popBackStack() },
            )
        }
        composable(route = "dev") {
            DevScreen(
                viewModel = koinInject(),
                onBackPressed = { navHostController.popBackStack() },
            )
        }
        composable(route = "issues") {
            IssuesScreen(
                viewModel = koinInject(),
                onBackPressed = { navHostController.popBackStack() },
            )
        }
        composable(route = "stat") {
            StatScreen(
                viewModel = koinInject(),
                onBackPressed = { navHostController.popBackStack() },
            )
        }
    }
}