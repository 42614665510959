package pl.krystiankaniowski.rank.core.repository

import kotlinx.coroutines.flow.*
import org.koin.core.annotation.Single
import pl.krystiankaniowski.rank.core.UserException
import pl.krystiankaniowski.rank.core.networking.RankApi
import pl.krystiankaniowski.rank.core.networking.Response
import pl.krystiankaniowski.rank.core.toUserError
import pl.krystiankaniowski.rank.model.NewPlayer
import pl.krystiankaniowski.rank.model.Player

@Single
class PlayerRepository(
    private val rankApi: RankApi,
) {

    private var players: MutableStateFlow<List<Player>> = MutableStateFlow(emptyList())

    fun getPlayers(forceSync: Boolean = false): Flow<List<Player>> = flow {
        if (forceSync || players.value.isEmpty()) {
            when (val result = rankApi.players()) {
                is Response.Error -> throw UserException(result.toUserError())
                is Response.Success -> updatePlayersFromRemote(result.body.data)
            }
        }
        players.collect { emit(it) }
    }

    fun getPlayer(id: Long): Flow<Player?> = players.map { it.firstOrNull { it.id == id } }

    suspend fun addPlayer(newPlayer: NewPlayer) {
        check(rankApi.addPlayer(newPlayer).isSuccess) { "Failed to add player" }
        when (val result = rankApi.players()) {
            is Response.Error -> throw UserException(result.toUserError())
            is Response.Success -> players.value = result.body.data
        }
    }

    private fun updatePlayersFromRemote(data: List<Player>) {
        players.value = data.sortedBy { it.name.lowercase() }
    }

    suspend fun setActive(id: Long, isActive: Boolean) {
        rankApi.setPlayerActive(id, isActive)
        players.value = getPlayers(true).first()
    }
}