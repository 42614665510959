package pl.krystiankaniowski.rank.core.formatter

import kotlin.math.pow

fun Float.cutAfter(decimals: Int): String {
    return when (decimals) {
        0 -> this.toInt()
        else -> {
            var multiplier = 1f
            repeat(decimals) { multiplier *= 10 }
            kotlin.math.round(this * multiplier) / multiplier
        }
    }.toString()
}

fun Double.cutAfter(decimals: Int): String {
    return when (decimals) {
        0 -> this.toInt()
        else -> {
            var multiplier = 1.0
            repeat(decimals) { multiplier *= 10 }
            kotlin.math.round(this * multiplier) / multiplier
        }
    }.toString()
}

fun Float.asPercentage(decimals: Int = 0): String {
    return "${((this * 100 * 10f.pow(decimals)).toInt() / 10f.pow(decimals)).cutAfter(decimals)}%"
}