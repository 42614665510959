package org.koin.ksp.generated

import org.koin.core.module.Module
import org.koin.dsl.*


public val pl_krystiankaniowski_rank_RankKoinModule : Module get() = module {
	includes(pl.krystiankaniowski.rank.core.networking.NetworkingModule().module)
	single() { _ -> pl.krystiankaniowski.rank.core.formatter.DateTimeFormatter() } 
	single() { _ -> pl.krystiankaniowski.rank.core.networking.RankApi(httpClient=get(),settings=get()) } 
	single() { _ -> pl.krystiankaniowski.rank.core.repository.EloRepository(matchRepository=get(),playerRepository=get()) } 
	single() { _ -> pl.krystiankaniowski.rank.core.repository.MatchRepository(playerRepository=get(),rankApi=get()) } 
	single() { _ -> pl.krystiankaniowski.rank.core.repository.PlayerRepository(rankApi=get()) } 
	single() { _ -> pl.krystiankaniowski.rank.core.repository.RankRepository(matchRepository=get(),playerRepository=get()) } 
	single() { _ -> pl.krystiankaniowski.rank.feature.issue.IssueRepository(api=get()) } 
	single() { _ -> pl.krystiankaniowski.rank.feature.issue.RankIssueApi(httpClient=get()) } 
	single() { _ -> pl.krystiankaniowski.rank.feature.main.MainViewModel(settings=get()) } 
	single() { _ -> pl.krystiankaniowski.rank.feature.setting.Settings() } 
	factory() { _ -> pl.krystiankaniowski.rank.feature.arbiter.ArbiterViewModel(settings=get()) } 
	factory() { _ -> pl.krystiankaniowski.rank.feature.dev.DevViewModel(forceReloadAppData=get(),forceRecalculateElo=get(),devRemoveDuplicatedMatchesUseCase=get()) } bind(pl.krystiankaniowski.rank.core.viewmodel.StateViewModel::class)
	factory() { _ -> pl.krystiankaniowski.rank.feature.dev.usecase.DevRemoveDuplicatedMatchesUseCase(rankApi=get(),forceReloadAppDataUseCase=get()) } 
	factory() { _ -> pl.krystiankaniowski.rank.feature.dev.usecase.ForceRecalculateEloUseCase(rankApi=get(),forceReloadAppDataUseCase=get()) } 
	factory() { _ -> pl.krystiankaniowski.rank.feature.dev.usecase.ForceReloadAppDataUseCase(playerRepository=get(),matchRepository=get()) } 
	factory() { _ -> pl.krystiankaniowski.rank.feature.issue.IssuesViewModel(issueRepository=get()) } 
	factory() { params -> pl.krystiankaniowski.rank.feature.match.add.MatchAddViewModel(params.get(),playerRepository=get(),matchRepository=get(),rankRepository=get()) } 
	factory() { params -> pl.krystiankaniowski.rank.feature.match.detail.MatchViewModel(params.get(),matchRepository=get(),dateTimeFormatter=get()) } 
	factory() { _ -> pl.krystiankaniowski.rank.feature.match.list.MatchesViewModel(matchRepository=get()) } 
	factory() { _ -> pl.krystiankaniowski.rank.feature.player.add.PlayerAddViewModel(playerRepository=get()) } bind(pl.krystiankaniowski.rank.core.viewmodel.StateViewModel::class)
	factory() { params -> pl.krystiankaniowski.rank.feature.player.compare.PlayerCompareViewModel(playerRepository=get(),matchRepository=get(),params.get(),params.get()) } 
	factory() { params -> pl.krystiankaniowski.rank.feature.player.detail.PlayerDetailViewModel(params.get(),playerRepository=get(),matchRepository=get(),eloRepository=get()) } 
	factory() { _ -> pl.krystiankaniowski.rank.feature.player.list.PlayerListViewModel(playerRepository=get()) } 
	factory() { params -> pl.krystiankaniowski.rank.feature.player.match.PlayerMatchListViewModel(params.get(),playerRepository=get(),matchRepository=get()) } 
	factory() { params -> pl.krystiankaniowski.rank.feature.player.rival.PlayerRivalsViewModel(params.get(),playerRepository=get(),matchRepository=get()) } bind(pl.krystiankaniowski.rank.core.viewmodel.StateViewModel::class)
	factory() { _ -> pl.krystiankaniowski.rank.feature.player.select.PlayersSelectViewModel(playerRepository=get(),matchRepository=get()) } bind(pl.krystiankaniowski.rank.core.viewmodel.StateViewModel::class)
	factory() { _ -> pl.krystiankaniowski.rank.feature.rank.RankViewModel(rankRepository=get()) } bind(pl.krystiankaniowski.rank.core.viewmodel.StateViewModel::class)
	factory() { _ -> pl.krystiankaniowski.rank.feature.setting.SettingViewModel() } 
	factory() { _ -> pl.krystiankaniowski.rank.feature.stat.StatViewModel(getNumberOfMatches=get(),getNumberOfSets=get(),getNumberOfPoints=get(),getNumberOfPlayers=get()) } 
	factory() { _ -> pl.krystiankaniowski.rank.feature.stat.usecase.StatGetNumberOfMatchesUseCase(matchRepository=get()) } 
	factory() { _ -> pl.krystiankaniowski.rank.feature.stat.usecase.StatGetNumberOfPlayersUseCase(playerRepository=get()) } 
	factory() { _ -> pl.krystiankaniowski.rank.feature.stat.usecase.StatGetNumberOfPointsUseCase(matchRepository=get()) } 
	factory() { _ -> pl.krystiankaniowski.rank.feature.stat.usecase.StatGetNumberOfSetsUseCase(matchRepository=get()) } 
}
public val pl.krystiankaniowski.rank.RankKoinModule.module : org.koin.core.module.Module get() = pl_krystiankaniowski_rank_RankKoinModule
