package pl.krystiankaniowski.rank.feature.player.list

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.foundation.lazy.grid.items
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import pl.krystiankaniowski.rank.core.designsystem.*
import pl.krystiankaniowski.rank.feature.player.ui.PlayerTile
import rank.client.shared.generated.resources.Res
import rank.client.shared.generated.resources.icon_sync

@Composable
fun PlayerListScreen(
    viewModel: PlayerListViewModel,
    openPlayerDetails: (Long) -> Unit,
) {

    LaunchedEffect(Unit) {
        viewModel.events.collect { event ->
            when (event) {
                is PlayerListViewModel.Event.OpenPlayerDetailsScreen -> openPlayerDetails(event.id)
            }
        }
    }

    val state = viewModel.state.collectAsState().value

    RankLayout.Page(
        topBar = {
            RankTopBar.Solid(
                title = "Players",
                actions = listOf(
                    RankTopBar.Action.Icon(
                        icon = Res.drawable.icon_sync,
                        onClick = { viewModel.onAction(PlayerListViewModel.Action.Refresh) },
                    ),
                ),
            )
        },
        content = {
            when (state) {
                is PlayerListViewModel.State.Loaded -> PlayerListScreenLoaded(state, viewModel::onAction)
                PlayerListViewModel.State.Loading -> RankScreenState.Loading()
                PlayerListViewModel.State.Empty -> RankScreenState.Empty(message = "No data to display")
                is PlayerListViewModel.State.Error -> RankScreenState.Error(userError = state.userError)
            }
        },
    )
}

@Composable
private fun PlayerListScreenLoaded(
    state: PlayerListViewModel.State.Loaded,
    onAction: (PlayerListViewModel.Action) -> Unit,
) {

    val screenSize = LocalScreenSize.current
    val padding = if (screenSize == RankScreenSize.Compact || screenSize == RankScreenSize.Medium) 16.dp else 24.dp
    val columnWidth = if (screenSize == RankScreenSize.Compact || screenSize == RankScreenSize.Medium) 96.dp else 128.dp

    LazyVerticalGrid(
        modifier = Modifier.fillMaxWidth(),
        contentPadding = PaddingValues(vertical = 16.dp),
        columns = GridCells.Adaptive(columnWidth),
        verticalArrangement = Arrangement.spacedBy(padding),
        horizontalArrangement = Arrangement.spacedBy(padding),
    ) {
        items(state.players) { player ->
            PlayerTile(
                player = player,
                onClick = { onAction(PlayerListViewModel.Action.OnPlayerClick(player.id)) },
            )
        }
    }
}
