package pl.krystiankaniowski.rank.feature.issue

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import org.koin.core.annotation.Single
import pl.krystiankaniowski.rank.core.UserException
import pl.krystiankaniowski.rank.core.networking.Response
import pl.krystiankaniowski.rank.core.toUserError
import pl.krystiankaniowski.rank.model.Issue

@Single
class IssueRepository(
    private val api: RankIssueApi,
) {

    private var issues: List<Issue> = emptyList()

    fun getIssues(): Flow<List<Issue>> = flow {
        if (issues.isEmpty()) {
            when (val result = api.issues()) {
                is Response.Error -> throw UserException(result.toUserError())
                is Response.Success -> issues = result.body.data
            }
        }
        emit(issues)
    }
}