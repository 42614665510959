package pl.krystiankaniowski.rank.core.designsystem

import androidx.compose.foundation.clickable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.*
import androidx.compose.material3.*
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.ui.tooling.preview.Preview

object RankListItems {

    @Composable
    fun Header(text: String) {
        ListItem(
            colors = ListItemDefaults.colors(containerColor = Color.Unspecified),
            headlineContent = {
                Text(
                    modifier = Modifier.padding(vertical = 16.dp),
                    text = text,
                    style = MaterialTheme.typography.headlineMedium,
                )
            },
        )
    }

    @Composable
    fun Item(
        label: String,
        value: String? = null,
        icon: DrawableResource? = null,
        trailingIcon: ImageVector? = null,
        onClick: (() -> Unit)? = null,
    ) {
        ListItem(
            modifier = onClick?.let { Modifier.clickable(onClick = it) } ?: Modifier,
            colors = ListItemDefaults.colors(containerColor = Color.Unspecified),
            headlineContent = { Text(label) },
            supportingContent = value?.let { { Text(text = it, color = MaterialTheme.colorScheme.primary) } },
            leadingContent = icon?.let {
                @Composable {
                    Icon(painter = painterResource(icon), contentDescription = null, tint = MaterialTheme.colorScheme.primary)
                }
            },
            trailingContent = trailingIcon?.let {
                @Composable {
                    Icon(imageVector = trailingIcon, contentDescription = null)
                }
            },
        )
    }

    @Composable
    fun RadioButton(
        label: String,
        value: String? = null,
        isSelected: Boolean,
        onClick: (() -> Unit)? = null,
    ) {
        val interactionSource = remember { MutableInteractionSource() }
        ListItem(
            modifier = onClick?.let {
                Modifier.clickable(interactionSource = interactionSource, indication = null, onClick = it)
            } ?: Modifier,
            headlineContent = {
                Row(verticalAlignment = Alignment.CenterVertically) {
                    RadioButton(
                        selected = isSelected,
                        onClick = onClick,
                        interactionSource = interactionSource,
                    )
                    Text(
                        text = label,
                        modifier = Modifier.fillMaxWidth(),
                    )
                }
            },
            supportingContent = value?.let { { Text(text = it, color = MaterialTheme.colorScheme.primary) } },
        )
    }

    @Composable
    fun Switch(
        label: String,
        value: String? = null,
        isSelected: Boolean,
        onClick: ((Boolean) -> Unit)? = null,
    ) {
        val interactionSource = remember { MutableInteractionSource() }
        ListItem(
            headlineContent = {
                Row(verticalAlignment = Alignment.CenterVertically) {
                    Switch(
                        checked = isSelected,
                        onCheckedChange = onClick,
                        interactionSource = interactionSource,
                    )
                    Spacer(Modifier.width(16.dp))
                    Text(
                        text = label,
                        modifier = Modifier.fillMaxWidth(),
                    )
                }
            },
            supportingContent = value?.let { { Text(text = it, color = MaterialTheme.colorScheme.primary) } },
        )
    }
}

@Preview
@Composable
private fun RankListItems_Preview() {
    RankPreview {
        Column {
            RankListItems.Header(text = "Header")
            RankListItems.Item(label = "label", value = "value")
            RankListItems.RadioButton(label = "label", value = "value", isSelected = true, onClick = null)
            RankListItems.RadioButton(label = "label", value = null, isSelected = false, onClick = null)
            RankListItems.Switch(label = "label", value = "value", isSelected = true, onClick = null)
            RankListItems.Switch(label = "label", value = null, isSelected = false, onClick = null)
        }
    }
}