package pl.krystiankaniowski.rank.feature.dev

import androidx.lifecycle.viewModelScope
import kotlinx.coroutines.launch
import org.koin.core.annotation.Factory
import pl.krystiankaniowski.rank.core.UserError
import pl.krystiankaniowski.rank.core.transform
import pl.krystiankaniowski.rank.core.viewmodel.StateViewModel
import pl.krystiankaniowski.rank.feature.dev.usecase.DevRemoveDuplicatedMatchesUseCase
import pl.krystiankaniowski.rank.feature.dev.usecase.ForceRecalculateEloUseCase
import pl.krystiankaniowski.rank.feature.dev.usecase.ForceReloadAppDataUseCase

@Factory
class DevViewModel(
    private val forceReloadAppData: ForceReloadAppDataUseCase,
    private val forceRecalculateElo: ForceRecalculateEloUseCase,
    private val devRemoveDuplicatedMatchesUseCase: DevRemoveDuplicatedMatchesUseCase,
) : StateViewModel<DevViewModel.State, DevViewModel.Event, DevViewModel.Action>() {

    data class State(
        val isLoading: Boolean = false,
    )

    sealed interface Event {
        data class ErrorOccurred(val error: UserError) : Event
    }

    sealed interface Action {
        data object ReloadData : Action
        data object RecalculateElo : Action
        data object RemoveDuplicatedMatches : Action
    }

    override fun initState() = State()

    override fun onAction(action: Action) {
        viewModelScope.launch {
            try {
                _state.transform { copy(isLoading = true) }
                when (action) {
                    Action.ReloadData -> forceReloadAppData()
                    Action.RecalculateElo -> forceRecalculateElo()
                    Action.RemoveDuplicatedMatches -> devRemoveDuplicatedMatchesUseCase()
                }
            } catch (e: Exception) {
                _events.emit(Event.ErrorOccurred(UserError.from(e)))
            } finally {
                _state.transform { copy(isLoading = false) }
            }
        }
    }
}