package pl.krystiankaniowski.rank.core.designsystem

import androidx.compose.runtime.compositionLocalOf
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp

enum class RankScreenSize {
    Compact,
    Medium,
    Expanded,
    Large,
    ExtraLarge,
    ;

    companion object {

        fun RankScreenSize.margin() = when (this) {
            Compact -> 16.dp
            Medium -> 24.dp
            Expanded -> 24.dp
            Large -> 24.dp
            ExtraLarge -> 24.dp
        }

        fun resolveScreenSize(width: Dp) = when {
            width < 600.dp -> Compact
            width < 840.dp -> Medium
            width < 1280.dp -> Expanded
            width < 1920.dp -> Large
            else -> ExtraLarge
        }
    }
}

val LocalScreenSize = compositionLocalOf { RankScreenSize.Compact }
