package pl.krystiankaniowski.rank.feature.dev

import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.Text
import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.compose.ui.window.Dialog
import androidx.compose.ui.window.DialogProperties
import pl.krystiankaniowski.rank.core.UserError
import pl.krystiankaniowski.rank.core.designsystem.RankLayout
import pl.krystiankaniowski.rank.core.designsystem.RankListItems
import pl.krystiankaniowski.rank.core.designsystem.RankTopBar
import pl.krystiankaniowski.rank.feature.error.UserErrorDialog

@Composable
fun DevScreen(
    viewModel: DevViewModel,
    onBackPressed: () -> Unit,
) {

    val state by viewModel.state.collectAsState()

    var userError: UserError? by remember { mutableStateOf(null) }

    LaunchedEffect(Unit) {
        viewModel.events.collect {
            when (it) {
                is DevViewModel.Event.ErrorOccurred -> userError = it.error
            }
        }
    }

    if (userError != null) {
        UserErrorDialog(
            error = userError!!,
            onDismiss = { userError = null },
        )
    }

    if (state.isLoading) {
        Dialog(
            properties = DialogProperties(dismissOnClickOutside = false, dismissOnBackPress = false),
            onDismissRequest = { },
        ) {
            Row {
                CircularProgressIndicator()
                Spacer(modifier = Modifier.padding(16.dp))
                Text("Loading...")
            }
        }
    }

    RankLayout.Page(
        topBar = { RankTopBar.Solid(title = "Developer options", onBackPressed = onBackPressed) },
    ) {
        RankLayout.ContentScrollable {
            RankLayout.ContentCard {
                RankListItems.Header("Data")
                RankListItems.Item(
                    onClick = { viewModel.onAction(DevViewModel.Action.ReloadData) },
                    label = "Reload data",
                    value = "Force synchronize data with backend",
                )
                RankListItems.Item(
                    onClick = { viewModel.onAction(DevViewModel.Action.RecalculateElo) },
                    label = "Recalculate elo",
                    value = "Force elo recalculation for all players",
                )
                RankListItems.Item(
                    onClick = { viewModel.onAction(DevViewModel.Action.RemoveDuplicatedMatches) },
                    label = "Remove duplicated matches",
                    value = "Find and remove all duplicated matches",
                )
            }
        }
    }
}
