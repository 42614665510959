package pl.krystiankaniowski.rank.feature.player.elo

import androidx.compose.foundation.Canvas
import androidx.compose.foundation.layout.*
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import kotlinx.datetime.*
import kotlin.time.Duration.Companion.days

@Composable
fun EloCard(
    eloHistory: Map<LocalDateTime, Double>,
) {

    val values = eloHistory.toLast90DaysList()

    Column(modifier = Modifier.padding(16.dp)) {
        BoxWithConstraints(
            modifier = Modifier
                .fillMaxWidth()
                .height(200.dp),
        ) {
            val maxWidth = maxWidth.px
            val maxHeight = maxHeight.px

            val color = MaterialTheme.colorScheme.primary
            val _1500Color = MaterialTheme.colorScheme.secondary

            val step = maxWidth / 90f

            Canvas(modifier = Modifier.size(this@BoxWithConstraints.maxWidth, this@BoxWithConstraints.maxHeight)) {
                drawLine(
                    color = _1500Color,
                    start = Offset(0f, maxHeight / 2),
                    end = Offset(maxWidth, maxHeight / 2),
                    strokeWidth = 0.5f,
                    alpha = 0.5f,
                )

                var lastY = 0f

                values.forEachIndexed { index, value ->
                    val x = step * index
                    val y = (maxHeight - ((value - 1000) / 1000f) * maxHeight).toFloat()
                    if (index > 0) {
                        drawLine(
                            color = color,
                            start = Offset(x - step, y),
                            end = Offset(x, y),
                            strokeWidth = 4f,
                        )
                        drawLine(
                            color = color,
                            start = Offset(x - step, y),
                            end = Offset(x - step, lastY),
                            strokeWidth = 4f,
                        )
                    }
                    lastY = y
                }
            }
        }
        Row(modifier = Modifier.fillMaxWidth()) {
            Text(modifier = Modifier.weight(1f), textAlign = TextAlign.Center, text = "90 days", style = MaterialTheme.typography.bodyMedium)
            Text(modifier = Modifier.weight(1f), textAlign = TextAlign.Center, text = "60 days", style = MaterialTheme.typography.bodyMedium)
            Text(modifier = Modifier.weight(1f), textAlign = TextAlign.Center, text = "30 days", style = MaterialTheme.typography.bodyMedium)
            Text(modifier = Modifier.weight(1f), textAlign = TextAlign.Center, text = "now", style = MaterialTheme.typography.bodyMedium)
        }
    }
}

inline val Dp.px: Float
    @Composable get() = with(LocalDensity.current) { this@px.toPx() }

fun Map<LocalDateTime, Double>.toLast90DaysList(): List<Double> {
    val singleDates: Map<LocalDate, Double> = this
        .toList()
        .groupBy { it.first.date }
        .map { it.key to (it.value.maxBy { it.first }).second }
        .sortedBy { it.first }
        .toMap()
    val startDate = Clock.System.now() - 91.days
    val startDay = startDate.toLocalDateTime(TimeZone.currentSystemDefault()).date
    var lastValue: Double = singleDates.toList().filter { it.first <= startDay }.maxBy { it.first }.second

    val data = List(91) { index ->
        val currentDay = (startDate + index.days).toLocalDateTime(TimeZone.currentSystemDefault()).date
        val currentValue = singleDates[currentDay] ?: lastValue
        lastValue = currentValue
        currentValue
    }

    return data
}