package pl.krystiankaniowski.rank.core.designsystem

import androidx.compose.foundation.shape.CornerSize
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.ui.unit.dp

object RankListUtils {

    @Composable
    fun getListShape(list: List<*>, index: Int) = when {
        list.size == 1 -> MaterialTheme.shapes.medium
        index == 0 -> MaterialTheme.shapes.medium.copy(bottomEnd = CornerSize(0.dp), bottomStart = CornerSize(0.dp))
        index == list.size - 1 -> MaterialTheme.shapes.medium.copy(topEnd = CornerSize(0.dp), topStart = CornerSize(0.dp))
        else -> MaterialTheme.shapes.medium.copy(bottomEnd = CornerSize(0.dp), bottomStart = CornerSize(0.dp), topEnd = CornerSize(0.dp), topStart = CornerSize(0.dp))
    }
}
