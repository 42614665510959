package pl.krystiankaniowski.rank.feature.setting

import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.SharedFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import org.koin.core.annotation.Factory
import pl.krystiankaniowski.rank.RankConfig
import rank.client.shared.generated.resources.Res
import rank.client.shared.generated.resources.icon_arbiter
import rank.client.shared.generated.resources.icon_dev

@Factory
class SettingViewModel : ViewModel() {

    data class State(
        val settingsItems: List<SettingEntry>,
    )

    sealed interface Event {
        data object OpenArbiterScreen : Event
        data object OpenDevScreen : Event
        data object OpenIssuesScreen : Event
        data object OpenStatScreen : Event
        data class OpenUrl(val url: String) : Event
    }

    private val settingsItems: List<SettingEntry> = listOf(
        SettingEntry.Item(
            icon = Res.drawable.icon_arbiter,
            label = "Arbiter",
            action = { viewModelScope.launch { _events.emit(Event.OpenArbiterScreen) } },
        ),
        SettingEntry.Group(
            title = "About",
            items = listOfNotNull(
                SettingEntry.Item(
                    label = "Project page",
                    description = "https://github.com/Kanialdo/rank",
                    action = { viewModelScope.launch { _events.emit(Event.OpenUrl("https://github.com/Kanialdo/rank")) } },
                ),
                platformSettingsProvider?.provideStoreItem { url -> viewModelScope.launch { _events.emit(Event.OpenUrl(url)) } },
                SettingEntry.Item(
                    label = "Stats",
                    description = "Check system stats",
                    action = { viewModelScope.launch { _events.emit(Event.OpenStatScreen) } },
                ),
                SettingEntry.Item(
                    label = "Issues",
                    description = "Check plans for future",
                    action = { viewModelScope.launch { _events.emit(Event.OpenIssuesScreen) } },
                ),
                SettingEntry.Item(
                    label = "Build",
                    description = "${RankConfig.VSC_BRANCH} ${RankConfig.VSC_COMMIT}",
                ),
            ),
        ),
        SettingEntry.Item(
            icon = Res.drawable.icon_dev,
            label = "Dev options",
            action = { viewModelScope.launch { _events.emit(Event.OpenDevScreen) } },
        ),
    )

    private val _state: MutableStateFlow<State> = MutableStateFlow(State(settingsItems))
    val state: StateFlow<State> = _state

    private val _events: MutableSharedFlow<Event> = MutableSharedFlow()
    val events: SharedFlow<Event> = _events

    init {
        _state.value = State(
            settingsItems = settingsItems,
        )
    }
}