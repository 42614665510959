package pl.krystiankaniowski.rank.core.designsystem

import androidx.compose.foundation.layout.*
import androidx.compose.material3.*
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Shape
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.ui.tooling.preview.Preview
import pl.krystiankaniowski.rank.core.designsystem.listener.ThrottledListener

object RankButton {

    @Composable
    fun Primary(
        modifier: Modifier = Modifier,
        text: String,
        enabled: Boolean = true,
        onClick: () -> Unit,
    ) {
        Standard(
            modifier = modifier,
            enabled = enabled,
            colors = ButtonDefaults.buttonColors(),
            onClick = onClick,
        ) {
            ButtonText(text)
        }
    }

    @Composable
    fun Secondary(
        modifier: Modifier = Modifier,
        text: String,
        enabled: Boolean = true,
        onClick: () -> Unit,
    ) {
        Standard(
            modifier = modifier,
            enabled = enabled,
            colors = ButtonDefaults.filledTonalButtonColors(),
            onClick = onClick,
        ) {
            ButtonText(text)
        }
    }

    @Composable
    fun TextButton(
        modifier: Modifier = Modifier,
        text: String,
        enabled: Boolean = true,
        onClick: () -> Unit,
    ) {
        TextButton(
            modifier = modifier,
            enabled = enabled,
            shape = MaterialTheme.shapes.small,
            contentPadding = PaddingValues(horizontal = 16.dp, vertical = 8.dp),
            onClick = onClick,
        ) {
            ButtonText(text)
        }
    }

    @Composable
    private fun Standard(
        modifier: Modifier = Modifier,
        enabled: Boolean = true,
        contentPadding: PaddingValues = PaddingValues(horizontal = 16.dp, vertical = 8.dp),
        shape: Shape = MaterialTheme.shapes.small,
        colors: ButtonColors,
        onClick: () -> Unit,
        content: @Composable () -> Unit,
    ) {
        val throttledListener = remember { ThrottledListener() }
        Button(
            modifier = modifier
                .defaultMinSize(
                    minWidth = ButtonMinWidth,
                    minHeight = ButtonMinHeight,
                ),
            enabled = enabled,
            colors = colors,
            contentPadding = contentPadding,
            shape = shape,
            onClick = { throttledListener.trigger(onClick) },
        ) {
            content()
        }
    }

    @Composable
    private fun ButtonText(
        text: String,
    ) {
        Text(text = text)
    }

    private val ButtonMinWidth = 56.dp
    private val ButtonMinHeight = 40.dp
}

@Preview
@Composable
private fun RankButton_Preview() {
    RankPreview {
        Column(modifier = Modifier.padding(16.dp)) {
            Row(horizontalArrangement = Arrangement.spacedBy(16.dp)) {
                RankButton.Primary(
                    text = "Primary",
                    onClick = {},
                )
                RankButton.Secondary(
                    text = "Secondary",
                    onClick = {},
                )
                RankButton.TextButton(
                    text = "Text button",
                    onClick = {},
                )
            }
            Spacer(modifier = Modifier.height(16.dp))
            Row(horizontalArrangement = Arrangement.spacedBy(16.dp)) {
                RankButton.Primary(
                    text = "Primary",
                    enabled = false,
                    onClick = {},
                )
                RankButton.Secondary(
                    text = "Secondary",
                    enabled = false,
                    onClick = {},
                )
                RankButton.TextButton(
                    text = "Text button",
                    enabled = false,
                    onClick = {},
                )
            }
        }
    }
}
