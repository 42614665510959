package pl.krystiankaniowski.rank.feature.player.match

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.itemsIndexed
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.unit.dp
import pl.krystiankaniowski.rank.core.designsystem.*
import pl.krystiankaniowski.rank.feature.match.ui.RankMatch

@Composable
fun PlayerMatchListScreen(
    viewModel: PlayerMatchListViewModel,
    openMatchScreen: (Long) -> Unit,
    onBackPressed: () -> Unit,
) {

    RankLayout.Page(
        topBar = {
            when (val state = viewModel.state.collectAsState().value) {
                is PlayerMatchListViewModel.State.Loaded -> {
                    RankTopBar.Solid(title = "${state.name}'s matches", onBackPressed = onBackPressed)
                }
                else -> {
                    RankTopBar.Solid(title = "Player matches", onBackPressed = onBackPressed)
                }
            }
        },
    ) {
        when (val state = viewModel.state.collectAsState().value) {
            is PlayerMatchListViewModel.State.Loaded -> PlayerMatchListScreenLoaded(
                state = state,
                openMatchScreen = openMatchScreen,
            )
            PlayerMatchListViewModel.State.Loading -> RankScreenState.Loading()
            is PlayerMatchListViewModel.State.Error -> RankScreenState.Error(userError = state.userError)
        }
    }
}

@Composable
private fun PlayerMatchListScreenLoaded(
    state: PlayerMatchListViewModel.State.Loaded,
    openMatchScreen: (Long) -> Unit,
) {
    LazyColumn(
        contentPadding = PaddingValues(vertical = 16.dp),
    ) {
        state.matches.forEach { matchesAtDate ->
            item(key = matchesAtDate.key.toString()) {
                RankListItems.Header(matchesAtDate.key.toString())
            }
            itemsIndexed(matchesAtDate.value) { index, match ->
                if (index > 0) RankDivider()
                RankMatch(
                    modifier = Modifier
                        .clip(RankListUtils.getListShape(matchesAtDate.value, index))
                        .background(MaterialTheme.colorScheme.surfaceContainer),
                    match = match,
                    onMatchClick = { openMatchScreen(match.id) },
                )
            }
        }
    }
}