package pl.krystiankaniowski.rank.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Player(
    @SerialName("id") val id: Long,
    @SerialName("name") val name: String,
    @SerialName("avatarUrl") val avatarUrl: String?,
    @SerialName("elo") val elo: Double,
    @SerialName("isActive") val isActive: Boolean,
)