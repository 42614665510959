package pl.krystiankaniowski.rank.model

import kotlinx.datetime.Instant
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Match(
    @SerialName("id") val id: Long,
    @SerialName("timestamp") val timestamp: Instant,
    @SerialName("winnerId") val winnerId: Long,
    @SerialName("loserId") val loserId: Long,
    @SerialName("winnerScore") val winnerScore: Long,
    @SerialName("loserScore") val loserScore: Long,
    @SerialName("winnerEloChange") val winnerEloChange: Double,
    @SerialName("loserEloChange") val loserEloChange: Double,
    @SerialName("history") val history: List<MatchSet>?,
)