package pl.krystiankaniowski.rank.feature.player.select

import androidx.lifecycle.viewModelScope
import kotlinx.coroutines.flow.first
import org.koin.core.annotation.Factory
import pl.krystiankaniowski.rank.core.repository.MatchRepository
import pl.krystiankaniowski.rank.core.repository.PlayerRepository
import pl.krystiankaniowski.rank.core.runIf
import pl.krystiankaniowski.rank.core.transform
import pl.krystiankaniowski.rank.core.transformIf
import pl.krystiankaniowski.rank.core.viewmodel.StateViewModel
import pl.krystiankaniowski.rank.model.Player

@Factory
class PlayersSelectViewModel(
    private val playerRepository: PlayerRepository,
    private val matchRepository: MatchRepository,
) : StateViewModel<PlayersSelectViewModel.State, PlayersSelectViewModel.Event, PlayersSelectViewModel.Action>() {

    sealed interface State {
        data object Loading : State
        data class SelectPlayers(
            val recentPlayers: List<Player>,
            val allPlayers: List<Player>,
            val selectedPlayers: Set<Player>,
            val isNextButtonEnabled: Boolean,
        ) : State
    }

    sealed interface Event {
        data class OnPlayersConfirmed(val selectedPlayers: List<Long>) : Event
    }

    sealed interface Action {
        data class OnPlayerClick(val player: Player) : Action
        data object ConfirmPlayers : Action
    }

    override fun initState() = State.Loading

    init {
        _state.transform(viewModelScope) {
            val players = playerRepository.getPlayers().first().filter { it.isActive }
            val recentPlayers: List<Player> = matchRepository.getMatches().first()
                .asSequence()
                .sortedByDescending { it.datetime }
                .take(16)
                .flatMap { match -> listOf(match.winner, match.loser) }
                .toSet()
                .toList()
                .filter { it.isActive }
                .take(6)

            State.SelectPlayers(
                allPlayers = players,
                recentPlayers = recentPlayers,
                selectedPlayers = emptySet(),
                isNextButtonEnabled = false,
            )
        }
    }

    override fun onAction(action: Action) {
        when (action) {
            is Action.OnPlayerClick -> onPlayerClick(player = action.player)
            Action.ConfirmPlayers -> confirmPlayers()
        }
    }

    private fun onPlayerClick(player: Player) {
        _state.transformIf<State.SelectPlayers> {
            val selectedPlayers = when {
                selectedPlayers.contains(player) -> this.selectedPlayers - player
                selectedPlayers.size == 2 -> this.selectedPlayers
                else -> this.selectedPlayers + player
            }
            copy(
                selectedPlayers = selectedPlayers,
                isNextButtonEnabled = selectedPlayers.size == PLAYERS_TO_SELECT,
            )
        }
    }

    private fun confirmPlayers() {
        _state.runIf<State.SelectPlayers>(viewModelScope) {
            if (isNextButtonEnabled) {
                _events.emit(Event.OnPlayersConfirmed(this.selectedPlayers.map { it.id }))
            }
        }
    }

    companion object {
        const val PLAYERS_TO_SELECT = 2
    }
}