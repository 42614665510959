package pl.krystiankaniowski.rank.feature.stat.usecase

import kotlinx.coroutines.flow.first
import org.koin.core.annotation.Factory
import pl.krystiankaniowski.rank.core.repository.MatchRepository

@Factory
class StatGetNumberOfMatchesUseCase(
    private val matchRepository: MatchRepository,
) {

    suspend operator fun invoke(): Int = matchRepository.getMatches().first().size
}