package pl.krystiankaniowski.rank.feature.match.list

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.itemsIndexed
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.unit.dp
import pl.krystiankaniowski.rank.core.designsystem.*
import pl.krystiankaniowski.rank.feature.match.ui.RankMatch
import rank.client.shared.generated.resources.Res
import rank.client.shared.generated.resources.icon_sync

@Composable
fun MatchesScreen(
    viewModel: MatchesViewModel,
    openMatchDetails: (Long) -> Unit,
) {

    LaunchedEffect(Unit) {
        viewModel.events.collect { event ->
            when (event) {
                is MatchesViewModel.Event.OpenMatchDetailsScreen -> openMatchDetails(event.id)
            }
        }
    }

    RankLayout.Page(
        topBar = {
            RankTopBar.Solid(
                title = "Matches",
                actions = listOf(
                    RankTopBar.Action.Icon(
                        icon = Res.drawable.icon_sync,
                        onClick = { viewModel.onAction(MatchesViewModel.Action.Refresh) },
                    ),
                ),
            )
        },
        content = {
            when (val state = viewModel.state.collectAsState().value) {
                is MatchesViewModel.State.Loaded -> MatchesScreenLoaded(state, viewModel::onAction)
                MatchesViewModel.State.Loading -> RankScreenState.Loading()
                MatchesViewModel.State.Empty -> RankScreenState.Empty(message = "No data to display")
                is MatchesViewModel.State.Error -> RankScreenState.Error(userError = state.userError)
            }
        },
    )
}

@Composable
private fun MatchesScreenLoaded(
    state: MatchesViewModel.State.Loaded,
    onAction: (MatchesViewModel.Action) -> Unit,
) {
    LazyColumn(
        contentPadding = PaddingValues(vertical = 16.dp),
    ) {
        state.matches.forEach { matchesAtDate ->
            item(key = matchesAtDate.key.toString()) {
                RankListItems.Header(matchesAtDate.key.toString())
            }
            itemsIndexed(matchesAtDate.value) { index, match ->
                if (index > 0) RankDivider()
                RankMatch(
                    modifier = Modifier
                        .clip(RankListUtils.getListShape(matchesAtDate.value, index))
                        .background(MaterialTheme.colorScheme.surfaceContainer),
                    match = match,
                    onMatchClick = { onAction(MatchesViewModel.Action.OnMatchClick(match.id)) },
                )
            }
        }
    }
}
