package pl.krystiankaniowski.rank.feature.player.ui

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.ElevatedCard
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.BlendMode
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.unit.dp
import io.kamel.image.KamelImage
import io.kamel.image.asyncPainterResource
import pl.krystiankaniowski.rank.model.Player

@Composable
fun PlayerAvatar(
    modifier: Modifier = Modifier,
    player: Player,
) {
    PlayerAvatar(
        modifier = modifier,
        avatarUrl = player.avatarUrl,
        isActive = player.isActive,
    )
}

@Composable
fun PlayerAvatar(
    modifier: Modifier = Modifier,
    avatarUrl: String?,
    isActive: Boolean,
) {
    Box(
        modifier = modifier
            .clip(MaterialTheme.shapes.medium)
            .border(
                width = 1.dp,
                color = if (isActive) MaterialTheme.colorScheme.primary else MaterialTheme.colorScheme.surface,
                shape = MaterialTheme.shapes.medium,
            )
            .background(if (isActive) MaterialTheme.colorScheme.primaryContainer else MaterialTheme.colorScheme.surfaceContainer),
    ) {
        ElevatedCard {
            val url = avatarUrl ?: "https://api.rank.krystiankaniowski.pl/avatar/pixel-art/blueprint.jpeg"
            KamelImage(
                modifier = Modifier.fillMaxSize(),
                resource = asyncPainterResource(url),
                onLoading = { CircularProgressIndicator() },
                onFailure = { },
                contentDescription = "Avatar",
                colorFilter = ColorFilter.tint(
                    color = when {
                        isActive -> MaterialTheme.colorScheme.primary
                        else -> MaterialTheme.colorScheme.surface
                    },
                    blendMode = BlendMode.Color,
                ).takeIf { !isActive || avatarUrl == null },
            )
        }
    }
}

@Composable
fun PlayerAvatarSquare(
    modifier: Modifier = Modifier,
    player: Player,
) {
    PlayerAvatarSquare(
        modifier = modifier,
        avatarUrl = player.avatarUrl,
        isActive = player.isActive,
    )
}

@Composable
fun PlayerAvatarSquare(
    modifier: Modifier = Modifier,
    avatarUrl: String?,
    isActive: Boolean,
) {
    Box(
        modifier = modifier.background(if (isActive) MaterialTheme.colorScheme.primaryContainer else MaterialTheme.colorScheme.surfaceContainer),
    ) {
        val url = avatarUrl ?: "https://api.rank.krystiankaniowski.pl/avatar/pixel-art/blueprint.jpeg"
        KamelImage(
            modifier = Modifier.fillMaxSize(),
            resource = asyncPainterResource(url),
            onLoading = { CircularProgressIndicator() },
            onFailure = { },
            contentDescription = "Avatar",
            colorFilter = ColorFilter.tint(
                color = when {
                    isActive -> MaterialTheme.colorScheme.primary
                    else -> MaterialTheme.colorScheme.surface
                },
                blendMode = BlendMode.Color,
            ).takeIf { !isActive || avatarUrl == null },
        )
    }
}