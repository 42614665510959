package pl.krystiankaniowski.rank.feature.match.ui

import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import pl.krystiankaniowski.rank.core.designsystem.RankDivider
import pl.krystiankaniowski.rank.core.designsystem.RankLayout
import pl.krystiankaniowski.rank.core.model.MatchWithPlayers

@Composable
fun RankRecentMatchesCard(matches: List<MatchWithPlayers>) {
    RankLayout.SupportPaneCard {
        Text(
            modifier = Modifier.padding(16.dp).fillMaxWidth(),
            text = "Recent matches",
            style = MaterialTheme.typography.headlineSmall,
            maxLines = 1,
            textAlign = TextAlign.Center,
        )
        RankDivider()
        if (matches.isEmpty()) {
            Text(
                modifier = Modifier.padding(16.dp).fillMaxWidth(),
                text = "No matches to display",
                style = MaterialTheme.typography.bodyMedium,
                maxLines = 1,
                textAlign = TextAlign.Center,
            )
        } else {
            matches.forEachIndexed { index, match ->
                if (index > 0) {
                    RankDivider()
                }
                RankMatch(
                    match = match,
                    onMatchClick = {},
                )
            }
        }
    }
}