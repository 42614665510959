package pl.krystiankaniowski.rank.feature.stat

import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import org.koin.core.annotation.Factory
import pl.krystiankaniowski.rank.core.UserError
import pl.krystiankaniowski.rank.core.UserException
import pl.krystiankaniowski.rank.core.update
import pl.krystiankaniowski.rank.feature.stat.usecase.StatGetNumberOfMatchesUseCase
import pl.krystiankaniowski.rank.feature.stat.usecase.StatGetNumberOfPlayersUseCase
import pl.krystiankaniowski.rank.feature.stat.usecase.StatGetNumberOfPointsUseCase
import pl.krystiankaniowski.rank.feature.stat.usecase.StatGetNumberOfSetsUseCase

@Factory
class StatViewModel(
    private val getNumberOfMatches: StatGetNumberOfMatchesUseCase,
    private val getNumberOfSets: StatGetNumberOfSetsUseCase,
    private val getNumberOfPoints: StatGetNumberOfPointsUseCase,
    private val getNumberOfPlayers: StatGetNumberOfPlayersUseCase,
) : ViewModel() {

    private val _state: MutableStateFlow<State> = MutableStateFlow(State.Loading)
    val state: StateFlow<State> = _state

    init {
        viewModelScope.launch {
            try {
                _state.update(
                    State.Loaded(
                        numberOfMatches = getNumberOfMatches(),
                        numberOfSets = getNumberOfSets(),
                        numberOfPoints = getNumberOfPoints(),
                        numberOfPlayers = getNumberOfPlayers(),
                    ),
                )
            } catch (e: UserException) {
                _state.update(State.Error(e.userError))
            }
        }
    }

    sealed interface State {
        data object Loading : State
        data class Error(val userError: UserError) : State
        data class Loaded(
            val numberOfMatches: Int,
            val numberOfSets: Int,
            val numberOfPoints: Int,
            val numberOfPlayers: Int,
        ) : State
    }
}