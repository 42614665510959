package org.koin.ksp.generated

import org.koin.core.module.Module
import org.koin.dsl.*


public val pl_krystiankaniowski_rank_core_networking_NetworkingModule : Module get() = module {
	val moduleInstance = pl.krystiankaniowski.rank.core.networking.NetworkingModule()
	single() { _ -> moduleInstance.httpClient() } bind(io.ktor.client.HttpClient::class)
}
public val pl.krystiankaniowski.rank.core.networking.NetworkingModule.module : org.koin.core.module.Module get() = pl_krystiankaniowski_rank_core_networking_NetworkingModule
