package pl.krystiankaniowski.rank.core.designsystem

import androidx.compose.foundation.isSystemInDarkTheme
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.darkColorScheme
import androidx.compose.material3.lightColorScheme
import androidx.compose.runtime.Composable
import androidx.compose.ui.graphics.Color

@Composable
fun RankTheme(
    useDarkTheme: Boolean = isSystemInDarkTheme(),
    content: @Composable () -> Unit,
) {

    val colorScheme = when (useDarkTheme) {
        true -> darkScheme
        false -> lightScheme
    }

    MaterialTheme(
        colorScheme = colorScheme,
        shapes = MaterialTheme.shapes,
        typography = MaterialTheme.typography,
        content = content,
    )
}

// ------ GENERATED ------

private val primaryLightMediumContrast = Color(0xFF234373)
private val onPrimaryLightMediumContrast = Color(0xFFFFFFFF)
private val primaryContainerLightMediumContrast = Color(0xFF5775A8)
private val onPrimaryContainerLightMediumContrast = Color(0xFFFFFFFF)
private val secondaryLightMediumContrast = Color(0xFF00495A)
private val onSecondaryLightMediumContrast = Color(0xFFFFFFFF)
private val secondaryContainerLightMediumContrast = Color(0xFF2D7E95)
private val onSecondaryContainerLightMediumContrast = Color(0xFFFFFFFF)
private val tertiaryLightMediumContrast = Color(0xFF004D3B)
private val onTertiaryLightMediumContrast = Color(0xFFFFFFFF)
private val tertiaryContainerLightMediumContrast = Color(0xFF33826A)
private val onTertiaryContainerLightMediumContrast = Color(0xFFFFFFFF)
private val errorLightMediumContrast = Color(0xFF8C0009)
private val onErrorLightMediumContrast = Color(0xFFFFFFFF)
private val errorContainerLightMediumContrast = Color(0xFFDA342E)
private val onErrorContainerLightMediumContrast = Color(0xFFFFFFFF)
private val backgroundLightMediumContrast = Color(0xFFF9F9FF)
private val onBackgroundLightMediumContrast = Color(0xFF191C20)
private val surfaceLightMediumContrast = Color(0xFFF5FAFB)
private val onSurfaceLightMediumContrast = Color(0xFF171D1E)
private val surfaceVariantLightMediumContrast = Color(0xFFE0E2EC)
private val onSurfaceVariantLightMediumContrast = Color(0xFF40434A)
private val outlineLightMediumContrast = Color(0xFF5C5F67)
private val outlineVariantLightMediumContrast = Color(0xFF787A83)
private val scrimLightMediumContrast = Color(0xFF000000)
private val inverseSurfaceLightMediumContrast = Color(0xFF2B3133)
private val inverseOnSurfaceLightMediumContrast = Color(0xFFECF2F3)
private val inversePrimaryLightMediumContrast = Color(0xFFAAC7FF)
private val surfaceDimLightMediumContrast = Color(0xFFD5DBDC)
private val surfaceBrightLightMediumContrast = Color(0xFFF5FAFB)
private val surfaceContainerLowestLightMediumContrast = Color(0xFFFFFFFF)
private val surfaceContainerLowLightMediumContrast = Color(0xFFEFF5F6)
private val surfaceContainerLightMediumContrast = Color(0xFFE9EFF0)
private val surfaceContainerHighLightMediumContrast = Color(0xFFE3E9EA)
private val surfaceContainerHighestLightMediumContrast = Color(0xFFDEE3E5)

private val primaryDark = Color(0xFFAAC7FF)
private val onPrimaryDark = Color(0xFF0A305F)
private val primaryContainerDark = Color(0xFF274777)
private val onPrimaryContainerDark = Color(0xFFD6E3FF)
private val secondaryDark = Color(0xFF87D1EA)
private val onSecondaryDark = Color(0xFF003642)
private val secondaryContainerDark = Color(0xFF004E5F)
private val onSecondaryContainerDark = Color(0xFFB3EBFF)
private val tertiaryDark = Color(0xFF88D6BA)
private val onTertiaryDark = Color(0xFF00382A)
private val tertiaryContainerDark = Color(0xFF00513E)
private val onTertiaryContainerDark = Color(0xFFA4F2D5)
private val errorDark = Color(0xFFFFB4AB)
private val onErrorDark = Color(0xFF690005)
private val errorContainerDark = Color(0xFF93000A)
private val onErrorContainerDark = Color(0xFFFFDAD6)
private val backgroundDark = Color(0xFF111318)
private val onBackgroundDark = Color(0xFFE2E2E9)
private val surfaceDark = Color(0xFF0E1415)
private val onSurfaceDark = Color(0xFFDEE3E5)
private val surfaceVariantDark = Color(0xFF44474E)
private val onSurfaceVariantDark = Color(0xFFC4C6D0)
private val outlineDark = Color(0xFF8E9099)
private val outlineVariantDark = Color(0xFF44474E)
private val scrimDark = Color(0xFF000000)
private val inverseSurfaceDark = Color(0xFFDEE3E5)
private val inverseOnSurfaceDark = Color(0xFF2B3133)
private val inversePrimaryDark = Color(0xFF415F91)
private val surfaceDimDark = Color(0xFF0E1415)
private val surfaceBrightDark = Color(0xFF343A3B)
private val surfaceContainerLowestDark = Color(0xFF090F10)
private val surfaceContainerLowDark = Color(0xFF171D1E)
private val surfaceContainerDark = Color(0xFF1B2122)
private val surfaceContainerHighDark = Color(0xFF252B2C)
private val surfaceContainerHighestDark = Color(0xFF303637)

private val darkScheme = darkColorScheme(
    primary = primaryDark,
    onPrimary = onPrimaryDark,
    primaryContainer = primaryContainerDark,
    onPrimaryContainer = onPrimaryContainerDark,
    secondary = secondaryDark,
    onSecondary = onSecondaryDark,
    secondaryContainer = secondaryContainerDark,
    onSecondaryContainer = onSecondaryContainerDark,
    tertiary = tertiaryDark,
    onTertiary = onTertiaryDark,
    tertiaryContainer = tertiaryContainerDark,
    onTertiaryContainer = onTertiaryContainerDark,
    error = errorDark,
    onError = onErrorDark,
    errorContainer = errorContainerDark,
    onErrorContainer = onErrorContainerDark,
    background = backgroundDark,
    onBackground = onBackgroundDark,
    surface = surfaceDark,
    onSurface = onSurfaceDark,
    surfaceVariant = surfaceVariantDark,
    onSurfaceVariant = onSurfaceVariantDark,
    outline = outlineDark,
    outlineVariant = outlineVariantDark,
    scrim = scrimDark,
    inverseSurface = inverseSurfaceDark,
    inverseOnSurface = inverseOnSurfaceDark,
    inversePrimary = inversePrimaryDark,
    surfaceDim = surfaceDimDark,
    surfaceBright = surfaceBrightDark,
    surfaceContainerLowest = surfaceContainerLowestDark,
    surfaceContainerLow = surfaceContainerLowDark,
    surfaceContainer = surfaceContainerDark,
    surfaceContainerHigh = surfaceContainerHighDark,
    surfaceContainerHighest = surfaceContainerHighestDark,
)

private val lightScheme = lightColorScheme(
    primary = primaryLightMediumContrast,
    onPrimary = onPrimaryLightMediumContrast,
    primaryContainer = primaryContainerLightMediumContrast,
    onPrimaryContainer = onPrimaryContainerLightMediumContrast,
    secondary = secondaryLightMediumContrast,
    onSecondary = onSecondaryLightMediumContrast,
    secondaryContainer = secondaryContainerLightMediumContrast,
    onSecondaryContainer = onSecondaryContainerLightMediumContrast,
    tertiary = tertiaryLightMediumContrast,
    onTertiary = onTertiaryLightMediumContrast,
    tertiaryContainer = tertiaryContainerLightMediumContrast,
    onTertiaryContainer = onTertiaryContainerLightMediumContrast,
    error = errorLightMediumContrast,
    onError = onErrorLightMediumContrast,
    errorContainer = errorContainerLightMediumContrast,
    onErrorContainer = onErrorContainerLightMediumContrast,
    background = backgroundLightMediumContrast,
    onBackground = onBackgroundLightMediumContrast,
    surface = surfaceLightMediumContrast,
    onSurface = onSurfaceLightMediumContrast,
    surfaceVariant = surfaceVariantLightMediumContrast,
    onSurfaceVariant = onSurfaceVariantLightMediumContrast,
    outline = outlineLightMediumContrast,
    outlineVariant = outlineVariantLightMediumContrast,
    scrim = scrimLightMediumContrast,
    inverseSurface = inverseSurfaceLightMediumContrast,
    inverseOnSurface = inverseOnSurfaceLightMediumContrast,
    inversePrimary = inversePrimaryLightMediumContrast,
    surfaceDim = surfaceDimLightMediumContrast,
    surfaceBright = surfaceBrightLightMediumContrast,
    surfaceContainerLowest = surfaceContainerLowestLightMediumContrast,
    surfaceContainerLow = surfaceContainerLowLightMediumContrast,
    surfaceContainer = surfaceContainerLightMediumContrast,
    surfaceContainerHigh = surfaceContainerHighLightMediumContrast,
    surfaceContainerHighest = surfaceContainerHighestLightMediumContrast,
)
