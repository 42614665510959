package pl.krystiankaniowski.rank.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Issue(
    @SerialName("id") val id: Long,
    @SerialName("title") val title: String,
    @SerialName("description") val description: String?,
    @SerialName("tags") val tags: List<Tag>,
) {
    @Serializable
    data class Tag(
        @SerialName("id") val id: Long,
        @SerialName("name") val name: String,
        @SerialName("color") val color: Long,
    )
}
