package pl.krystiankaniowski.rank.feature.main

import androidx.compose.foundation.layout.*
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.*
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.resources.painterResource

@Composable
fun SideMenu(
    extendedFloatingActionButton: (@Composable () -> Unit)?,
    menu: List<MainDestination>,
    selected: MainDestination?,
    onSelect: (MainDestination) -> Unit,
) {
    PermanentDrawerSheet(
        modifier = Modifier.width(240.dp),
        drawerContainerColor = MaterialTheme.colorScheme.surface,
    ) {
        extendedFloatingActionButton?.let { extendedFloatingActionButton ->
            Box(
                modifier = Modifier.fillMaxWidth().padding(16.dp),
                content = { extendedFloatingActionButton() },
            )
        }
        Spacer(Modifier.height(16.dp))
        menu.forEach {
            NavigationDrawerItem(
                modifier = Modifier.padding(horizontal = 16.dp),
                colors = NavigationDrawerItemDefaults.colors(
                    unselectedContainerColor = MaterialTheme.colorScheme.surface,
                ),
                icon = { Icon(painter = painterResource(it.icon), contentDescription = it.title) },
                label = { Text(it.title) },
                selected = it == selected,
                onClick = { onSelect(it) },
            )
        }
    }
}

@Composable
fun RailMenu(
    floatingActionButton: (@Composable () -> Unit)?,
    menu: List<MainDestination>,
    selected: MainDestination?,
    onSelect: (MainDestination) -> Unit,
) {
    NavigationRail(
        modifier = Modifier
            .fillMaxHeight()
            .verticalScroll(rememberScrollState()),
        header = {
            floatingActionButton?.let { floatingActionButton ->
                Box(modifier = Modifier.padding(vertical = 16.dp)) {
                    floatingActionButton()
                }
            }
        },
        containerColor = MaterialTheme.colorScheme.surface,
    ) {
        menu.forEach {
            NavigationRailItem(
                modifier = Modifier.padding(horizontal = 8.dp),
                selected = it == selected,
                icon = { Icon(painter = painterResource(it.icon), contentDescription = it.title) },
                onClick = { onSelect(it) },
                label = { Text(it.title) },
            )
        }
    }
}

@Composable
fun BottomMenu(
    menu: List<MainDestination>,
    selected: MainDestination?,
    onSelect: (MainDestination) -> Unit,
) {
    NavigationBar {
        menu.forEach {
            NavigationBarItem(
                selected = it == selected,
                icon = { Icon(painter = painterResource(it.icon), contentDescription = it.title) },
                onClick = { onSelect(it) },
                label = { Text(it.title) },
                alwaysShowLabel = false,
            )
        }
    }
}
