package pl.krystiankaniowski.rank.feature.match.list

import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.SharedFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import kotlinx.datetime.LocalDate
import org.koin.core.annotation.Factory
import pl.krystiankaniowski.rank.core.UserError
import pl.krystiankaniowski.rank.core.catchUserException
import pl.krystiankaniowski.rank.core.model.MatchWithPlayers
import pl.krystiankaniowski.rank.core.repository.MatchRepository
import pl.krystiankaniowski.rank.core.update

@Factory
class MatchesViewModel(
    private val matchRepository: MatchRepository,
) : ViewModel() {

    private val _state: MutableStateFlow<State> = MutableStateFlow(State.Loading)
    val state: StateFlow<State> = _state

    private val _events: MutableSharedFlow<Event> = MutableSharedFlow()
    val events: SharedFlow<Event> = _events

    private var currentJob: Job? = null

    init {
        loadData(forceSync = false)
    }

    private fun loadData(forceSync: Boolean) {
        currentJob?.cancel()
        currentJob = viewModelScope.launch {
            _state.update(State.Loading)
            matchRepository.getMatchesGroupedByDate(forceSync)
                .catchUserException { _state.update(State.Error(it.userError)) }
                .collect { data ->
                    _state.value = when {
                        data.isEmpty() -> State.Empty
                        else -> State.Loaded(matches = data)
                    }
                }
        }
    }

    fun onAction(action: Action) {
        when (action) {
            Action.Refresh -> loadData(forceSync = true)
            is Action.OnMatchClick -> onMatchClick(action.id)
        }
    }

    private fun onMatchClick(id: Long) {
        viewModelScope.launch {
            _events.emit(Event.OpenMatchDetailsScreen(id))
        }
    }

    sealed interface State {
        data object Loading : State
        data object Empty : State
        data class Error(val userError: UserError) : State
        data class Loaded(
            val matches: Map<LocalDate, List<MatchWithPlayers>>,
        ) : State
    }

    sealed interface Event {
        data class OpenMatchDetailsScreen(val id: Long) : Event
    }

    sealed interface Action {
        data object Refresh : Action
        data class OnMatchClick(val id: Long) : Action
    }
}