package pl.krystiankaniowski.rank.feature.setting

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.platform.LocalUriHandler
import pl.krystiankaniowski.rank.core.designsystem.RankLayout
import pl.krystiankaniowski.rank.core.designsystem.RankListItems
import pl.krystiankaniowski.rank.core.designsystem.RankTopBar

@Composable
fun SettingScreen(
    viewModel: SettingViewModel,
    openArbiterScreen: () -> Unit,
    openDevScreen: () -> Unit,
    openIssuesScreen: () -> Unit,
    openStatScreen: () -> Unit,
) {

    val localUriHandler = LocalUriHandler.current
    val state = viewModel.state.collectAsState().value

    LaunchedEffect(Unit) {
        viewModel.events.collect { event ->
            when (event) {
                is SettingViewModel.Event.OpenUrl -> localUriHandler.openUri(event.url)
                SettingViewModel.Event.OpenArbiterScreen -> openArbiterScreen()
                SettingViewModel.Event.OpenDevScreen -> openDevScreen()
                SettingViewModel.Event.OpenIssuesScreen -> openIssuesScreen()
                SettingViewModel.Event.OpenStatScreen -> openStatScreen()
            }
        }
    }

    RankLayout.Page(
        topBar = { RankTopBar.Solid("Settings") },
    ) {
        RankLayout.ContentScrollable {
            state.settingsItems.forEach { entry ->
                when (entry) {
                    is SettingEntry.Group -> RankLayout.ContentCard {
                        RankListItems.Header(text = entry.title)
                        entry.items.forEach { item ->
                            RankListItems.Item(
                                icon = item.icon,
                                label = item.label,
                                value = item.description,
                                onClick = item.action,
                            )
                        }
                    }

                    is SettingEntry.Item -> RankLayout.ContentCard {
                        RankListItems.Item(
                            icon = entry.icon,
                            label = entry.label,
                            onClick = entry.action,
                        )
                    }
                }
            }
        }
    }
}
