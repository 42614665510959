package pl.krystiankaniowski.rank.feature.player.add

import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Text
import androidx.compose.material3.TextField
import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import pl.krystiankaniowski.rank.core.UserError
import pl.krystiankaniowski.rank.core.designsystem.RankButton
import pl.krystiankaniowski.rank.core.designsystem.RankLayout
import pl.krystiankaniowski.rank.core.designsystem.RankTopBar
import pl.krystiankaniowski.rank.feature.error.UserErrorDialog

@Composable
fun PlayerAddScreen(
    viewModel: PlayerAddViewModel,
    onBackPressed: () -> Unit,
) {

    val state by viewModel.state.collectAsState()

    var userError: UserError? by remember { mutableStateOf(null) }

    LaunchedEffect(Unit) {
        viewModel.events.collect {
            when (it) {
                PlayerAddViewModel.Event.CloseView -> onBackPressed()
                is PlayerAddViewModel.Event.ErrorOccurred -> userError = it.userError
            }
        }
    }

    if (userError != null) {
        UserErrorDialog(
            error = userError!!,
            onDismiss = { userError = null },
        )
    }

    RankLayout.Page(
        topBar = { RankTopBar.Solid(title = "Add player", onBackPressed = onBackPressed) },
    ) {
        PlayerAddScreenContent(
            state = state,
            onAction = viewModel::onAction,
        )
    }
}

@Composable
private fun PlayerAddScreenContent(
    state: PlayerAddViewModel.State,
    onAction: (PlayerAddViewModel.Action) -> Unit,
) {
    RankLayout.ContentScrollable {
        RankLayout.ContentCard(modifier = Modifier.padding(16.dp)) {
            TextField(
                value = state.name ?: "",
                onValueChange = { onAction(PlayerAddViewModel.Action.OnNameChange(it)) },
                label = { Text("Name") },
            )
            Spacer(modifier = Modifier.height(16.dp))
            RankButton.Primary(
                enabled = state.isAddButtonEnable,
                text = "Add",
                onClick = { onAction(PlayerAddViewModel.Action.OnAddButtonClick) },
            )
        }
    }
}